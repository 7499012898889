import React, { useState } from "react";
import "../styling.css";
import { Button, CircularProgress } from "@mui/material";
import { GroupEditorModal } from "./GroupEditorModal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "../ApiProvider";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

export const GroupTableComponent = ({ groupConfigs, level }) => {
  const { deactivateGroup } = useApi();
  const queryClient = useQueryClient();
  const [currentGroup, setCurrentGroup] = useState(null);

  const deactivateGroupMutation = useMutation({
    mutationFn: (groupId) => deactivateGroup(groupId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["groups", level] });
    },
  });

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 300,
    },
    {
      field: "memberIds",
      headerName: "Size",
      valueGetter: (value) => value.length,
    },
    {
      field: "inserted",
      headerName: "Created",
      type: "date",
      valueGetter: (value) => new Date(Date.parse(value)),
    },
    {
      type: "actions",
      headerName: "Manage",
      getActions: ({ row }) => [
        <GridActionsCellItem
          label="Edit"
          showInMenu
          onClick={() => {
            setCurrentGroup(row.id);
          }}
        />,
        <GridActionsCellItem
          label="Deactivate"
          showInMenu
          onClick={() => {
            deactivateGroupMutation.mutate(row.id);
          }}
        />,
      ],
    },
  ];

  return (
    <div>
      {currentGroup && (
        <GroupEditorModal
          open={currentGroup !== null}
          handleClose={() => {
            setCurrentGroup(null);
          }}
          groupId={currentGroup}
          level={level}
        />
      )}
      <DataGrid
        rows={groupConfigs}
        columns={columns}
        disableRowSelectionOnClick
      />
    </div>
  );
};
