import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useAuth } from "./AuthProvider";
import { Link } from "react-router-dom";
import { Avatar, IconButton } from "@mui/material";

export const UserMenu = () => {
  const { user, email, logout, userHasPermission } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    if (string === null || string === undefined || string.length === 0) {
      return "#1ab2a5";
    }

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    let initials;
    if (name === null || name === undefined || name === "") {
      initials = null;
    } else if (name.includes(" ") && name.split(" ").length > 1) {
      initials = `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;
    } else {
      initials = name[0].toUpperCase();
    }
    return {
      sx: {
        width: "30px",
        height: "30px",
        bgcolor: stringToColor(name),
      },
      children: initials,
    };
  }

  return (
    <>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        color="inherit"
      >
        <Avatar {...stringAvatar(user.name)} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Link to="/profile" style={{ all: "unset" }}>
          <MenuItem>
            <div>
              <p style={{ margin: "0" }}>{user.name}</p>
              <p style={{ margin: "0" }}>{email}</p>
            </div>
          </MenuItem>
        </Link>
        {(userHasPermission("manage_account") ||
          userHasPermission("manage_groupings") ||
          userHasPermission("manage_users")) && (
          <Link to="/account" style={{ all: "unset" }}>
            <MenuItem onClick={handleClose}>Manage Account</MenuItem>
          </Link>
        )}
        {userHasPermission("internal_admin") && (
          <Link to="/admin" style={{ all: "unset" }}>
            <MenuItem onClick={handleClose}>Internal Admin</MenuItem>
          </Link>
        )}
        {userHasPermission("manage_account") && (
          <Link to="/jobStatus" style={{ all: "unset" }}>
            <MenuItem onClick={handleClose}>View Jobs</MenuItem>
          </Link>
        )}
        <MenuItem
          onClick={() => {
            logout();
            handleClose();
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};
