import React from "react";
import {useApi} from "../ApiProvider";
import {useQuery} from "@tanstack/react-query";
import {GraphComponent} from "./GraphComponent";
import {ReportTable} from "./ReportTable";
import {CircularProgress, Grid} from "@mui/material";

export const ReportsContainer = ({
                                     userIds,
                                     groupIds,
                                     timeWindow,
                                     showSummaryTable,
                                 }) => {
    const {fetchInsightsDataForUsers, fetchInsightsDataForGroups} = useApi();
    const timeWindowValue = timeWindow.apiValue;

    const userInsightsQuery = useQuery({
        queryKey: ["userInsights", userIds, timeWindowValue],
        queryFn: ({queryKey}) =>
            fetchInsightsDataForUsers(queryKey[1], queryKey[2]),
    });
    const groupInsightsQuery = useQuery({
        queryKey: ["groupInsights", groupIds, timeWindowValue],
        queryFn: ({queryKey}) =>
            fetchInsightsDataForGroups(queryKey[1], queryKey[2]),
    });

    if (userInsightsQuery.isPending || groupInsightsQuery.isPending) {
        return <CircularProgress/>;
    }

    const userData = userInsightsQuery.data;
    const groupData = groupInsightsQuery.data;

    if (userInsightsQuery.isError || groupInsightsQuery.isError) {
        return <></>;
    }

    var userSummaries = userData["userSummaries"];
    let userTableControls = userSummaries.map((summary) => {
        return {
            ...summary,
            isEnabledForGraphs: true,
            isMarkedForRemove: false,
        };
    });

    let groupSummaries = groupData["userSummaries"];
    let groupTableControls = groupSummaries.map((summary) => {
        return {
            ...summary,
            isEnabledForGraphs: true,
            isMarkedForRemove: false,
        };
    });

    const weeklySummaries = userData.weeklySummaries;
    const groupWeeklySummaries = groupData.weeklySummaries;

    const userEmails = [
        ...new Set(
            userTableControls
                .filter((tableItem) => {
                    return tableItem.isEnabledForGraphs === true;
                })
                .map((summary) => {
                    return {
                        id: summary.userId,
                        name: summary.name,
                        stroke: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
                    };
                })
        ),
    ];

    const groupNames = [
        ...new Set(
            groupTableControls
                .filter((tableItem) => {
                    return tableItem.isEnabledForGraphs === true;
                })
                .map((summary) => {
                    return {
                        id: summary.groupId,
                        name: summary.name,
                        stroke: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
                    };
                })
        ),
    ];

    const mergedSummaries = new Map();

    weeklySummaries.forEach((obj1) => {
        const startOfWeek = obj1.startOfWeek;
        const existingObj = mergedSummaries.get(obj1.startOfWeek);
        if (existingObj) {
            mergedSummaries.set(obj1.startOfWeek, {...existingObj, user: obj1});
        } else {
            mergedSummaries.set(startOfWeek, {startOfWeek: startOfWeek, user: obj1});
        }
    });

    groupWeeklySummaries.forEach((obj1) => {
        const existingObj = mergedSummaries.get(obj1.startOfWeek);
        if (existingObj) {
            mergedSummaries.set(obj1.startOfWeek, {...existingObj, group: obj1});
        } else {
            mergedSummaries.set(obj1.startOfWeek, {group: obj1});
        }
    });


    const finalMerged = Array.from(mergedSummaries.values());

    const graphs = [
        {
            attribute: "wticketsAssigned",
            title: "Tickets Assigned by Week",
        },
        {
            attribute: "rticketsAssigned",
            title: "Tickets Assigned Aggregated",
        },
        {
            attribute: "wpullsMerged",
            title: "PRs Merged by Week",
        },
        {
            attribute: "rpullsMerged",
            title: "PRs Merged Aggregated",
        },
        {
            attribute: "wonCallFlatHours",
            title: "On Call Hours by Week"
        },
        {
            attribute: "ronCallFlatHours",
            title: "On Call Hours Aggregate"
        },
        {
            attribute: "wpullsOpened",
            title:"PRs Opened by Week"
        },
        {
            attribute: "rpullsOpened",
            title:"PRs Opened Aggregate"
        },
        {
            attribute: "wcommits",
            title:"Commits by Week"
        },
        {
            attribute: "rcommits",
            title:"Commits Aggregate"
        },
        {
            attribute: "wpagesAssigned",
            title:"Incidents Assigned by Week"
        },
        {
            attribute: "rpagesAssigned",
            title:"Incidents Assigned Aggregate"
        },
        {
            attribute: "wpagesResolved",
            title:"Incidents Resolve by Week"
        },
        {
            attribute: "rpagesResolved",
            title:"Incidents Resolve Aggregate"
        },
        {
            attribute: "wadds",
            title:"Adds by Week"
        },
        {
            attribute: "radds",
            title:"Adds Aggregate"
        },
        {
            attribute: "wdeletes",
            title:"Deletes by Week"
        },
        {
            attribute: "rdeletes",
            title:"Deletes Aggregate"
        },
        {
            attribute: "wcomments",
            title:"Comments by Week"
        },
        {
            attribute: "rcomments",
            title:"Comments Aggregate"
        }
    ]

    return (
        <Grid container spacing={1}>
            {showSummaryTable && (
                <Grid item xs={12}>
                    <ReportTable
                        userSummaries={userTableControls}
                        groupSummaries={groupTableControls}
                    />
                </Grid>
            )}
            {graphs.map((item, index) => {
                return <GraphComponent
                    title={item.title}
                    graphData={finalMerged}
                    users={userEmails}
                    groups={groupNames}
                    attribute={item.attribute}
                />
            })}
        </Grid>
    );
};
