import React, { useState } from "react";
import "../styling.css";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useApi } from "../ApiProvider";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import LoadingButton from "@mui/lab/LoadingButton";
function IntegrationCreateModal({ isOpen, closeModal, handleSyncWithGithub, handleSyncWithJira }) {
  const [integrationName, setIntegrationName] = useState("");
  const [token, setToken] = useState("");
  const [domainUrl, setDomainUrl] = useState("");
  const [altLoginEmail, setAltLoginEmail] = useState("");

  const { createIntegration } = useApi();
  const queryClient = useQueryClient();

  const createIntegrationMutation = useMutation({
    mutationFn: () => createIntegration(integrationName, token, domainUrl, altLoginEmail),
    onSuccess: () => {
      toast.success("Integration Created", { toastId: "toastId" });
      queryClient.invalidateQueries({ queryKey: ["integrations"] });
      closeModal();
    },
    onError: () => {
      toast.error(
        "Unable to create integration, please check your keys and try again",
        { toastId: "toastId" }
      );
    },
  });

  return (
    <Dialog open={isOpen} onClose={closeModal} fullWidth>
      <DialogTitle>Add an Integration</DialogTitle>
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          paddingTop: "1rem",
        }}
      >
        <FormControl fullWidth>
          <InputLabel>Integration Type</InputLabel>
          <Select
            margin="dense"
            label="Integration Type"
            value={integrationName}
            onChange={(e) => setIntegrationName(e.target.value)}
          >
            <MenuItem value="GITHUB_APP">GitHub App</MenuItem>
            <MenuItem value="GITHUB_TOKEN">GitHub Token</MenuItem>
            <MenuItem value="JIRA_TOKEN">Jira Token</MenuItem>
            {/*<MenuItem value="JIRA_APP">Jira App</MenuItem>*/}
            <MenuItem value="PAGERDUTY">PagerDuty</MenuItem>
          </Select>
        </FormControl>
        {integrationName === "GITHUB_APP" && (
          <Button
            color="secondary"
            variant="contained"
            onClick={handleSyncWithGithub}
          >
            Link with GitHub
          </Button>
        )}
        {integrationName === "JIRA_APP" && (
            <Button
                color="secondary"
                variant="contained"
                onClick={handleSyncWithJira}
            >
              Link with Jira
            </Button>
        )}
        {integrationName === "PAGERDUTY" && (
          <>
            <TextField
              autoFocus
              required
              margin="dense"
              label="API Token"
              fullWidth
              value={token}
              onChange={(e) => setToken(e.target.value)}
              helperText="https://YOUR_ORGANIZATION.pagerduty.com/api_keys (Read Only access required)"
            />
            <LoadingButton
              color="secondary"
              variant="contained"
              disabled={token.length === 0}
              onClick={() => createIntegrationMutation.mutate()}
              loading={createIntegrationMutation.isPending}
            >
              Add PagerDuty
            </LoadingButton>
          </>
        )}
        {integrationName === "GITHUB_TOKEN" && (
          <>
            <TextField
              autoFocus
              required
              margin="dense"
              label="API Token"
              fullWidth
              value={token}
              onChange={(e) => setToken(e.target.value)}
              helperText="https://github.com/settings/tokens Add Repo, Email, and Project scopes, read only"
            />
            <LoadingButton
              color="secondary"
              variant="contained"
              disabled={token.length === 0}
              onClick={() => createIntegrationMutation.mutate()}
              loading={createIntegrationMutation.isPending}
            >
              Add GitHub Token
            </LoadingButton>
          </>
        )}
        {integrationName === "JIRA_TOKEN" && (
            <>
              <TextField
                  autoFocus
                  required
                  margin="dense"
                  label="domain"
                  fullWidth
                  value={domainUrl}
                  onChange={(e) => setDomainUrl(e.target.value)}
                  helperText="https://mydomain.atlassian.net - enter the full url ending in .net"
              />
              <TextField
                autoFocus
                required
                margin="dense"
                label="Jira Email Address"
                fullWidth
                value={altLoginEmail}
                onChange={(e) => setAltLoginEmail(e.target.value)}
                helperText="Email address associated with this auth token"
            />
              <TextField
                  autoFocus
                  required
                  margin="dense"
                  label="API Token"
                  fullWidth
                  value={token}
                  onChange={(e) => setToken(e.target.value)}
                  helperText="https://support.atlassian.com/atlassian-account/docs/manage-api-tokens-for-your-atlassian-account/"
              />
              <LoadingButton
                  color="secondary"
                  variant="contained"
                  disabled={token.length === 0}
                  onClick={() => createIntegrationMutation.mutate()}
                  loading={createIntegrationMutation.isPending}
              >
                Add Jira Token
              </LoadingButton>
            </>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default IntegrationCreateModal;
