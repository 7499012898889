import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ReportsController } from "./dashboard/ReportsController";
import { AccountController } from "./account/AccountController";
import { RepoController } from "./repo/RepoController";
import { IntegrationController } from "./integration/IntegrationController";
import { AuthProvider, useAuth } from "./AuthProvider";
import PrivateRoute from "./PrivateRoute";
import LandingPageLoggedOut from "./LandingPageLoggedOut";
import AuthCallback from "./AuthCallback";
import ProfileControllerWrapper from "./profile/ProfileControllerWrapper";
import { ApiProvider } from "./ApiProvider";
import GitHubAuthCallbackComponent from "./GitHubAuthCallbackComponent";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ThemeProvider } from "@mui/material/styles";
import { useTheme } from "./theme";
import { CssBaseline, useMediaQuery } from "@mui/material";
import { JobStatusController } from "./JobStatusController";
import { UserInvite } from "./UserInvite";
import { toast, ToastContainer } from "react-toastify";
import ErrorBoundary from "./ErrorBoundary";
import { LoginComponent } from "./LoginComponent";
import ContactUsComponent from "./ContactUsComponent";
import { InternalAdminContainer } from "./internal/InternalAdminContainer";
import { InternalApiProvider } from "./api/InternalApiProvider";
import AboutUsComponent from "./AboutUsComponent";
import InvestorComponent from "./InvestorComponent";
import DevEfficiencyInfoComponent from "./DevEfficiencyInfoComponent";
import JiraAuthCallbackComponent from "./JiraAuthCallbackComponent";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2, // Number of retries
      retryDelay: (attemptIndex) => {
        const delay = Math.min(1000 * Math.pow(2, attemptIndex), 30000);
        console.log(`Attempt ${attemptIndex}, Delay: ${delay}`);
        return delay;
      }, // Other retry-related options
    },
  },
});

const RoutesBase = () => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const { userHasPermission } = useAuth();

  return (
    <ThemeProvider theme={useTheme(prefersDarkMode)}>
      <CssBaseline />
      <Routes>
        <Route
          path={"/git/callback"}
          element={<GitHubAuthCallbackComponent />}
        />
        <Route
          path={"/jira/callback"}
          element={<JiraAuthCallbackComponent />}
        />
        <Route path="/auth/callback" element={<AuthCallback />} />
        <Route path="/" element={<LandingPageLoggedOut />} />
        <Route path="/login" element={<LoginComponent />} />
        <Route path="/contact" element={<ContactUsComponent />} />
        <Route path="/aboutUs" element={<AboutUsComponent />} />
        <Route path="/investors" element={<InvestorComponent />} />
        <Route path="/devEfficiency" element={<DevEfficiencyInfoComponent />} />
        <Route path="/unauthorized" element={<h2>Unauthorized</h2>} />
        <Route
          path={"/"}
          element={
            <PrivateRoute>
              <App />
            </PrivateRoute>
          }
        >
          <Route path={"/home"} element={<ReportsController />} />
          <Route path={"/graphs"} element={<ReportsController />} />
          {userHasPermission("manage_account") && (
            <Route path={"/account"} element={<AccountController />} />
          )}
          <Route path={"/integrations"} element={<IntegrationController />} />
          <Route path={"/repos"} element={<RepoController />} />
          <Route path={"/profile"} element={<ProfileControllerWrapper />} />
          <Route path="/users/:userId" element={<ProfileControllerWrapper />} />
          {userHasPermission("manage_account") && (
            <Route path={"/jobStatus"} element={<JobStatusController />} />
          )}
          {userHasPermission("internal_admin") && (
            <>
              <Route path="/admin" element={<InternalAdminContainer />} />
              <Route
                path="/admin/accounts/:id"
                element={<AccountController />}
              />
            </>
          )}
        </Route>
        <Route path="/invite" element={<UserInvite />} />
      </Routes>
    </ThemeProvider>
  );
};

const ReactRoot = () => {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ApiProvider>
            <InternalApiProvider>
              <RoutesBase />
            </InternalApiProvider>
          </ApiProvider>
        </AuthProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

const root = createRoot(document.getElementById("App"));
root.render(
  <div>
    <ErrorBoundary>
      <ReactRoot />
      <ToastContainer
        position="top-center" // Set position to top-center
        autoClose={2000} // Optional: Close the toast automatically after 2 seconds
        hideProgressBar={true} // Optional: Show or hide the progress bar
        newestOnTop={false} // Optional: Display newest toast on top
        closeOnClick={true} // Optional: Close the toast when clicked
        rtl={false} // Optional: Right-to-left support
        limit={1}
      />
    </ErrorBoundary>
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
