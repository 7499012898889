import {
  AppBar,
  Box,
  Button, Grid, Paper,
  Toolbar,
  Typography,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";
import {GraphComponent} from "./dashboard/GraphComponent";
import {loggedOutData} from "./loggedOutData";
import React, {useEffect, useRef, useState} from "react";
import {ReportTable} from "./dashboard/ReportTable";
import {RepoTable} from "./repo/RepoTable";

const LandingPageLoggedOut = () => {
  const theme = useTheme();
  const repoTableData = loggedOutData.repoTableData;
  const graphUserNames = loggedOutData.graphUserNames;
  const graphGroupNames = loggedOutData.graphGroupNames;
  const graphUserControls = loggedOutData.userTableControls;
  const graphGroupControls = loggedOutData.graphTableControls;
  const graphDataFinalMerged = loggedOutData.graphDataFinalMerged;
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
  const [progress, setProgress] = useState(100);
  const imagesBouncedRef = useRef(false); // Ref to track if images have already bounced

  const subPages = [
    {
      title: 'About us',
      link: '/aboutUs'
    },
    {
      title: 'The Science of Dev Efficiency',
      link: '/devEfficiency'
    },
    {
      title: 'Investors',
      link: '/investors'
    },
  ]
  const MarketingOptions = [
    {
      title: 'Build Culture',
      desc: 'Many factors cause teams to slow down and speed up. ' +
          'Whether its organizational pivots, product directional shifts, or team process improvements ' +
          '- get the data you need to see peaks and valleys across multiple points of measurement. ',
      gTitle: 'Weekly Commits',
      gAttribute: 'wcommits',
    },
    {
      title: 'Decrease burnout ',
      desc: 'From long on-call sessions, to working on the weekends. ' +
          'We have to work together to bring these into an acceptable tolerance.  ',
      gTitle: 'Hours On Call',
      gAttribute: 'ronCallFlatHours',
    },
    {
      title: 'Minimize bias',
      desc: 'Recognize the devs who are progressing the code base. We need people who deliver high quality code and lots of it. ' +
          'Less talking; More doing, we got this!',
      gTitle: 'Total Additions',
      gAttribute: 'radds',
    },
    {
      title: 'Focus on what Matters',
      desc: 'Spend time moving forward, not looking behind. Whether its monitoring for Incident related Pages, ' +
          'production bug fixes, or both at the same time. Positions developers to work on fun, new stuff - not just tech debt and incidents',
      gTitle: 'Pages for Incidents',
      gAttribute: 'rpagesAssigned',
    },
  ];

  const animateMainImages = () => {
    if (!imagesBouncedRef.current) {
      const mainImages = document.querySelectorAll('.brand_image');
      mainImages.forEach((image) => {
        image.style.animation = 'bounce 1s';
      });
      imagesBouncedRef.current = true;
    }
  };

  const timerRef = useRef(null);

  useEffect(() => {
    startAutoSwitch();
    animateMainImages();
    return () => clearInterval(timerRef.current);
  }, []);

  const startAutoSwitch = () => {
    timerRef.current = setInterval(() => {
      setSelectedOptionIndex(prevIndex => {
        if (prevIndex === null) return 0;
        return (prevIndex + 1) % MarketingOptions.length;
      });
      setProgress(100); // Reset progress

    }, 10000); // Adjust the interval as needed
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgress(prevProgress => {
        const newProgress = prevProgress - (100 / 180); // Adjust for interval
        if (newProgress <= 0) {
          clearInterval(intervalId);
          return 0;
        }
        return newProgress;
      });
    }, 50); // Update progress every second
    return () => clearInterval(intervalId);

  }, [selectedOptionIndex]);

  const handleOptionClick = (index) => {
    clearInterval(timerRef.current); // Stop the timer
    setSelectedOptionIndex(index);
    startAutoSwitch(); // Start the timer again
  };

  const handleFooterLinkClick = (index) => {
    window.location.href = subPages[index].link;
  }

  const handleEmailBasedLogin = () => {
    window.location.href = "/login";
  };

  const handleContactUs = () => {
    window.location.href = "/contact";
  };

  const handleHomeClick = () => {
    window.location.href = "/";
  };

  const styles = {
    primarySectionBox: {
      width: "100%",
      height: "5em",
      display: "flex", // Use flexbox layout
      alignItems: "center", // Vertically center items
      justifyContent: "center", // Horizontally center items
      textAlign: "justify" /* Evenly distribute text across lines */,
    },
    secondarySectionBox: {
      width: "100%",
      height: "10em",
      display: "flex", // Use flexbox layout
      alignItems: "center", // Vertically center items
      justifyContent: "center", // Horizontally center items
      /* Evenly distribute text across lines */
      textAlign: "center",
      '@media screen and (max-width: 768px)': {
        height: "5em",
      }
    },

    secondaryLargeBox: {
      paddingTop: '2em',
      // backgroundColor: "#f5f5f5",
      // color: theme.palette.text.primary,
      /* Evenly distribute text across lines */
      textAlign: "center",
    },
    alternateLargeBox: {
      paddingTop: '2em',
      /* Evenly distribute text across lines */
      textAlign: "center",
      // backgroundColor: theme.palette.primary.light
    },
    primaryBackground: {
      fontWeight: "bold",
      textAlign: "center",
    },
  };

  const buildLogoStr = (logo) => {
    return theme.palette.mode === 'light' ? '/light/' + logo : '/dark/' + logo;
  }

  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{cursor: 'pointer'}} onClick={handleHomeClick}>
            DevLanding
          </Typography>

          <Button style={{"margin-right": "1em", cursor: 'pointer'}} color="secondary" variant="contained" onClick={handleContactUs}>
            Contact Us
          </Button>

          <Button variant="contained"
                  color="secondary" style={{cursor: 'pointer'}} onClick={handleEmailBasedLogin}>
            Log in
          </Button>

        </Toolbar>
      </AppBar>
      <Box sx={styles.secondarySectionBox}
           elevation={3}
           style={{
             padding: '20px',
             height: '100%',
           }}>
        <img className="brand_image" src={buildLogoStr('gitHub_logo.png')}/>
        +
        <img className="brand_image" src="/outlook-calendar.svg"/>
        +
        <img className="brand_image" src="/jira-logo-gradient-blue-attribution_rgb.svg"/>
        +
        <img className="medium_brand_image" src="/pagerduty-logo.svg"/>
      </Box>
      <Box sx={styles.primarySectionBox}>
        <Typography sx={styles.primaryBackground} variant="h6">
          <div>
            Dev Efficiency - All in One Place
          </div>
        </Typography>
      </Box>
      <Box sx={styles.secondaryBox}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} style={{display: 'flex', flexDirection: 'column'}}>
            <Paper sx={styles.secondaryBox} style={{
              padding: '16px',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100%',
            }}>
              <Box
                  style={{
                    padding: '20px',
                    cursor: 'pointer',
                    'text-align': 'left',
                    marginBottom: '10px',
                  }}
              >
                {MarketingOptions.map((option, index) => (
                    <div onClick={() => handleOptionClick(index)}>
                      <Typography variant={index === selectedOptionIndex ? 'h4' : 'h6'} gutterBottom
                                  style={{
                                    fontSize: index === selectedOptionIndex ? '2.5rem' : '1rem',
                                  }}>
                        {option.title}
                      </Typography>
                      {index === selectedOptionIndex && (
                          <Typography variant="body1" gutterBottom style={{color: theme.palette.text.secondary}}>
                            {option.desc}
                          </Typography>
                      )}
                    </div>
                ))}
              </Box>
            </Paper>
          </Grid>
          <GraphComponent
              title={MarketingOptions[selectedOptionIndex].gTitle}
              users={graphUserNames}
              groups={graphGroupNames}
              graphData={graphDataFinalMerged}
              attribute={MarketingOptions[selectedOptionIndex].gAttribute}
              key={MarketingOptions[selectedOptionIndex].gTitle}
          />
        </Grid>
      </Box>
      <Box sx={styles.secondaryLargeBox}>
        <Grid container spacing={0} style={{'text-align': 'center'}}>
          <Grid item xs={12}>
            <Box style={{'padding': '2em'}}>
              <Typography style={{width: '100%'}} variant="h4">
                Compare across Teams, Orgs, and Levels
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box >
              <Typography variant="h6" style={{color:theme.palette.text.secondary}}>
                Define group level metrics to bench mark where things are, and set targets of where things should be.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box style={{'padding': '2em'}}>
              <ReportTable
                  userSummaries={graphUserControls}
                  groupSummaries={graphGroupControls}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={styles.alternateLargeBox}>
        <Grid container spacing={0} style={{'text-align': 'center'}}>
          <Grid item xs={12}>
            <Box style={{'padding': '2em'}}>
              <Typography style={{width: '100%'}} variant="h4">
                Over 10 million data points indexed, and counting
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Typography variant="h6" style={{color: theme.palette.text.secondary}}>
                We have the controls you need to manage your account at scale. From repos management to configuring
                release evaluations, we have you covered.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box style={{'padding': '2em'}}>
              <RepoTable repos={repoTableData} isDemoMode={true}/>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={styles.secondaryBox}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} style={{display: 'flex', flexDirection: 'column'}}>
            <Paper sx={styles.secondaryBox} style={{
              padding: '16px',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100%',
            }}>
              <Box
                  style={{
                    padding: '20px',
                    cursor: 'pointer',
                    'text-align': 'left',
                    marginBottom: '10px',
                  }}
              >
                {subPages.map((option, index) => (
                    <div onClick={() => handleFooterLinkClick(index)}>
                      <Typography variant='h6' gutterBottom style={{}}>
                        {option.title}
                      </Typography>
                    </div>
                ))}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default LandingPageLoggedOut;
