import React from "react";

import { ReactComponent as Github } from "./logos/github.svg";
import { ReactComponent as Pagerduty } from "./logos/pagerduty.svg";
import { SvgIcon, Tooltip } from "@mui/material";

export const IntegrationIcon = ({ integrationName, additionalText }) => {
  let normalizedName = "";
  let integrationIconToRender;
  let color = "text";
  switch (integrationName.toLowerCase()) {
    case "github":
      normalizedName = "GitHub";
      integrationIconToRender = <Github />;
      break;
    case "pagerduty":
      normalizedName = "PagerDuty";
      integrationIconToRender = <Pagerduty />;
      color = "success";
      break;
    default:
      integrationIconToRender = null;
  }
  return (
    <Tooltip
      title={normalizedName + (additionalText ? `: ${additionalText}` : "")}
    >
      <SvgIcon color={color}>{integrationIconToRender}</SvgIcon>
    </Tooltip>
  );
};
