import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { AliasTable } from "./AliasTable";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "../ApiProvider";
import LoadingButton from "@mui/lab/LoadingButton";

export const AliasEditorModal = ({ user, open, handleClose }) => {
  const { userId, userEmail } = user;
  const [showEditor, setShowEditor] = useState(false);
  const [aliasValue, setAliasValue] = useState("");
  const [aliasType, setAliasType] = useState("EMAIL");
  const [integration, setIntegration] = useState(null);

  const { createUserAlias, fetchIntegrations } = useApi();

  const fetchIntegrationsQuery = useQuery({
    queryKey: ["integrations"],
    queryFn: fetchIntegrations,
  });

  const queryClient = useQueryClient();
  const createAliasMutation = useMutation({
    mutationFn: () => createUserAlias(userId, aliasType, aliasValue),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["aliases", "account"] });
      queryClient.invalidateQueries({ queryKey: ["aliases", "user", userId] });
      setAliasValue("");
      setShowEditor(false);
    },
  });

  const aliasTypes = [
    { label: "Email", value: "EMAIL" },
    { label: "Github Login", value: "LOGIN_NAME" },
  ];

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Aliases for {userEmail}</DialogTitle>
      <DialogContent>
        <AliasTable userId={userId} />
        {showEditor ? (
          <Grid container spacing={2} alignItems="center" marginTop={"1rem"}>
            <Grid item>
              <TextField
                select={true}
                value={aliasType}
                onChange={(e) => setAliasType(e.target.value)}
                label="Type"
              >
                {aliasTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item>
              <TextField
                label="Alias value"
                value={aliasValue}
                onChange={(e) => setAliasValue(e.target.value)}
              />
            </Grid>
            <Grid item>
              <Autocomplete
                options={
                  fetchIntegrationsQuery.isPending
                    ? []
                    : fetchIntegrationsQuery.data
                }
                getOptionLabel={(option) => option.description}
                onChange={(_e, newValue) => setIntegration(newValue)}
                value={integration}
                size="small"
                sx={{ width: "400px" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Associated Integration"
                  />
                )}
              />
            </Grid>
            <Grid item>
              {!createAliasMutation.isPending ? (
                <LoadingButton
                  onClick={() => createAliasMutation.mutate()}
                  disabled={aliasType === "" || integration === null}
                  loading={createAliasMutation.isPending}
                >
                  Save
                </LoadingButton>
              ) : (
                <CircularProgress />
              )}
            </Grid>
          </Grid>
        ) : (
          <Button
            variant="contained"
            onClick={() => setShowEditor(true)}
            style={{ marginTop: "1rem" }}
            color="secondary"
          >
            Add alias
          </Button>
        )}
      </DialogContent>
    </Dialog>
  );
};
