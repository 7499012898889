import React, { useEffect, useState } from "react";
import { IntegrationTable } from "./IntegrationTable";
import { useApi } from "../ApiProvider";
import { useQuery } from "@tanstack/react-query";
import { Button, CircularProgress } from "@mui/material";
import IntegrationCreateModal from "./IntegrationCreateModal";

export const IntegrationController = () => {
  const { fetchIntegrations } = useApi();
  const [showModal, setShowModal] = useState(false);

  const { data, error, isLoading } = useQuery({
    queryKey: ["integrations"],
    queryFn: fetchIntegrations,
  });

  const handleSyncWithGithub = async () => {
    try {
      const redirectUri = process.env.REACT_APP_GITHUB_REDIRECT_URI; // Replace with your actual callback URL
      const clientId = process.env.REACT_APP_GITHUB_CLIENT_ID; // dev
      const authUrl = `https://github.com/login/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=user:email`;

      window.location.href = authUrl; // URL to initiate OAuth flow
      // Redirect the user to GitHub's OAuth authorization URL
    } catch (error) {
      console.error("Error initiating OAuth flow:", error);
    }
  };

  const handleSyncWithJira = async () => {
    try {
      const redirectUri = process.env.REACT_APP_JIRA_REDIRECT_URI // Replace with your actual callback URL
      const clientId = process.env.REACT_APP_JIRA_CLIENT_ID; // dev
      // const authUrl = `https://auth.atlassian.com/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=read%3Ajira-work%20read%3Ajira-user&response_type=code&prompt=consent`;

      // const authUrl = `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=A9XvzXI6a8eEgoYyQGH4tY8pfVBBznyO&scope=read%3Aconfluence-space.summary%20read%3Aconfluence-props%20read%3Aconfluence-content.summary%20read%3Aconfluence-user&redirect_uri=https%3A%2F%2Flocalhost%3A3000%2Fjira%2Fcallback&response_type=code&prompt=consent`

      const authUrl = `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=A9XvzXI6a8eEgoYyQGH4tY8pfVBBznyO&scope=read%3Ajira-work%20read%3Ajira-user&redirect_uri=https%3A%2F%2Flocalhost%3A3000%2Fjira%2Fcallback&response_type=code&prompt=consent`
      window.location.href = authUrl; // URL to initiate OAuth flow
      // Redirect the user to GitHub's OAuth authorization URL
    } catch (error) {
      console.error("Error initiating OAuth flow:", error);
    }
  }

  return (
    <div>
      <div className="header-with-actions">
        <h2>Integrations</h2>
        <Button
          className="saveButton"
          variant="contained"
          color="secondary"
          onClick={() => setShowModal(true)}
        >
          Add
        </Button>
      </div>
      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        <div>Error fetching Integration information</div>
      ) : (
        <div>
          <IntegrationTable curIntegrations={data} />
        </div>
      )}

      {showModal && (
        <IntegrationCreateModal
          isOpen={showModal}
          closeModal={() => setShowModal(false)}
          closeModelSaveless={() => setShowModal(false)}
          handleSyncWithGithub={handleSyncWithGithub}
          handleSyncWithJira={handleSyncWithJira}
        />
      )}
    </div>
  );
};
