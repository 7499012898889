import { Autocomplete, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { AliasEditorModal } from "../aliases/AliasEditorModal";
import { InviteUserModal } from "./InviteUserModal";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "../ApiProvider";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import { useAuth } from "../AuthProvider";
import { useNavigate } from "react-router-dom";

export const ScopeComponent = ({ overrideAccountId }) => {
  const navigate = useNavigate();
  const { userHasPermission, startImpersonation } = useAuth();

  const [currentUser, setCurrentUser] = useState(null);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [editingRow, setEditingRow] = useState(null);
  const [updatedRoles, setUpdatedRoles] = useState([]);

  const { resetPassword, fetchAccountScopes, updateAccountScopes } = useApi();

  const queryClient = useQueryClient();
  const scopesQuery = useQuery({
    queryKey: ["scopes", overrideAccountId],
    queryFn: () => fetchAccountScopes(overrideAccountId),
  });

  const resetPasswordMutation = useMutation({
    mutationFn: (userId) => resetPassword(userId),
  });

  const updateScopesMutation = useMutation({
    mutationFn: (userId) => updateAccountScopes(userId, updatedRoles),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["scopes"] });
      toast.success("Roles Updated!", {
        position: toast.POSITION.TOP_CENTER,
      });
      setUpdatedRoles([]);
    },
  });

  const toggleEditing = (row) => {
    if (editingRow === row.userId) {
      updateScopesMutation.mutate(row.userId);
      setEditingRow(null);
    } else {
      const scopes = transformScopes(row);
      setUpdatedRoles(scopes);
      setEditingRow(row.userId);
    }
  };

  const transformScopes = (row) => {
    return Object.entries(row)
      .filter((pair) => pair[1] === true)
      .map((pair) => pair[0]);
  };

  const impersonatingAvailable =
    overrideAccountId && userHasPermission("internal_admin");

  const columns = [
    {
      field: "userEmail",
      headerName: "Email Address",
      minWidth: 200,
    },
    {
      field: "roles",
      headerName: "Roles",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 300,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {editingRow === row.userId ? (
              <Autocomplete
                disableClearable
                multiple
                options={[
                  "accessDevlanding",
                  "manageUsers",
                  "manageAccount",
                  "manageGroupings",
                  "manageBilling",
                ]}
                onChange={(e, newValue) => {
                  setUpdatedRoles(newValue);
                }}
                value={updatedRoles}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} variant="standard" color="info" />
                )}
              />
            ) : (
              <p>{transformScopes(row).join(", ")}</p>
            )}
            <Button color="secondary" onClick={() => toggleEditing(row)}>
              {editingRow === row.userId ? "Save" : "Edit"}
            </Button>
          </div>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      sortable: false,
      getActions: ({ row }) => {
        var baseActions = [
          <GridActionsCellItem
            showInMenu
            onClick={() => setCurrentUser(row)}
            label="Manage Aliases"
          />,
          <GridActionsCellItem
            showInMenu
            onClick={() => resetPasswordMutation.mutate(row.userId)}
            label="Reset Password"
          />,
        ];
        if (impersonatingAvailable) {
          baseActions = baseActions.concat(
            <GridActionsCellItem
              showInMenu
              onClick={() => startImpersonation(row.userId)}
              label="Impersonate"
            />
          );
        }
        return baseActions;
      },
    },
  ];

  return (
    <div className="container">
      <div className="table-container">
        <div className="header-with-actions">
          <h2>Users</h2>
          <Button
            className="saveButton"
            variant="contained"
            color="secondary"
            onClick={() => setShowInviteModal(true)}
          >
            Add
          </Button>
        </div>
        <DataGrid
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pagination
          pageSizeOptions={[5, 10, 25, 50]}
          columns={columns}
          rows={scopesQuery.data || []}
          loading={scopesQuery.isLoading}
          disableRowSelectionOnClick
          getRowId={(row) => row.userId}
          getRowHeight={() => "auto"}
          onRowDoubleClick={(row) => {
            navigate(`/users/${row.id}`);
          }}
        />
        {currentUser && (
          <AliasEditorModal
            user={currentUser}
            open={currentUser !== null}
            handleClose={() => {
              setCurrentUser(null);
            }}
          />
        )}
        <InviteUserModal
          open={showInviteModal}
          handleClose={() => setShowInviteModal(false)}
        />
      </div>
    </div>
  );
};
