import {
    AppBar,
    Box,
    Button, Link,
    Toolbar,
    Typography,
} from "@mui/material";
import React from "react";
import {useTheme} from "@mui/material/styles";

const AboutUsComponent = () => {
    const theme = useTheme();

    const handleEmailBasedLogin = () => {
        window.location.href = "/login";
    };

    const handleContactUs = () => {
        window.location.href = "/contact";
    }
    const handleHomeClick = () => {
        window.location.href = "/";
    };

    const styles = {
        primarySectionBox: {
            width: "100%",
            display: "flex", // Use flexbox layout
            justifyContent: "center", // Horizontally center items
            textAlign: "justify" /* Evenly distribute text across lines */,
            height: '100vh', // Set the height to fill the whole viewport
            padding: '3em',
        },
        secondarySectionBox: {
            width: "100%",
            height: "10em",
            display: "flex", // Use flexbox layout
            flexDirection: "column"
        },
        primaryBackground: {
            fontWeight: "bold",
            textAlign: "center",
        },
        sectionBox: {
            paddingTop: '2em'
        },
    };

    return (
        <>
            <Box sx={{display: 'flex', flexDirection: 'column', height: '100vh'}}>
                <AppBar position="sticky">
                    <Toolbar>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{cursor: 'pointer'}} onClick={handleHomeClick}>
                            DevLanding
                        </Typography>

                        <Button style={{"margin-right": "1em", cursor: 'pointer'}} color="secondary" variant="contained" onClick={handleContactUs}>
                            Contact Us
                        </Button>

                        <Button variant="contained"
                                color="secondary" style={{cursor: 'pointer'}} onClick={handleEmailBasedLogin}>
                            Log in
                        </Button>

                    </Toolbar>
                </AppBar>
                <Box sx={styles.primarySectionBox}>
                    <Box sx={styles.secondarySectionBox}>
                        <Typography variant="h2">
                            About Us
                        </Typography>
                        <Typography sx={styles.sectionBox} gutterBottom>
                            Founded by Engineering Leaders at Facebook and Google, we are on a mission to bring data to
                            the engineering efficiency conversation. DORA metrics matter,
                            but we felt the human component was missing. We wanted to take a different approach that
                            focused on how people get their job done. Between getting Paged, Contributing on weekends, and
                            having too many Meetings - the day in developers life matters.
                        </Typography>
                        <Typography sx={styles.sectionBox} gutterBottom>
                            Focus is the key to delivery. At DevLanding we aim to give developers space to focus, and use
                            data to show how focus leads to a healthy culture.

                            Together, with over 20 years of technical leadership experience, we decided to create
                            DevLanding - a centralized view of an organizations development culture.

                            We have conviction an org with data will out perform an org without it every day of the
                            week.
                        </Typography>
                        <Typography sx={styles.sectionBox} gutterBottom>
                            Data tells a story. What does your organizations data say about your engineering culture?
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default AboutUsComponent;
