import React, { useState } from "react";
import { RepoTable } from "./RepoTable";
import { toast } from "react-toastify";
import { useApi } from "../ApiProvider";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { CircularProgress, Fab } from "@mui/material";

export const RepoController = () => {
  const [reposToSave, setReposToSave] = useState([]);
  const [updatedRepos, setUpdatedRepos] = useState(null);
  const [updatingRepos, setUpdatingRepos] = useState(false);

  const { fetchRepos, updateRepo } = useApi();

  const queryClient = useQueryClient();
  const { isLoading, data } = useQuery({
    queryKey: ["repos"],
    queryFn: fetchRepos,
  });

  const { repos } = data || { repos: [] };

  const handleRepoChange = (repoId, field, value) => {
    let updatedItem = {};
    const reposSource = updatedRepos || repos;
    let updatedSource = reposSource.map((item) => {
      if (item.id === repoId) {
        const temp = { ...item, [field]: value };
        updatedItem = temp;
        return temp;
      } else {
        return item;
      }
    });

    let hasPrevUpdates = false;
    let updatedToSave = reposToSave.map((item) => {
      if (item.id === repoId) {
        hasPrevUpdates = true;
        return { ...item, [field]: value };
      } else {
        return item;
      }
    });

    if (!hasPrevUpdates) {
      updatedToSave.push(updatedItem);
    }
    setUpdatedRepos(updatedSource);
    setReposToSave(updatedToSave);
  };

  const handleRepoBulkSave = () => {
    setUpdatingRepos(true);
    const promises = reposToSave.map((item) => {
      return updateRepo(item);
    });

    Promise.all(promises).then((responses) => {
      setUpdatingRepos(false);
      queryClient.invalidateQueries({ queryKey: ["repos"] });
      setUpdatedRepos(null);
      toast.success("Repos updated");
    });
  };

  if (isLoading === true) {
    return <CircularProgress />;
  }

  return (
    <div className="body-component-container">
      <h1>Repos</h1>
      <RepoTable
        repos={updatedRepos || repos}
        doUpdate={handleRepoChange}
        doSave={handleRepoBulkSave}
      />
      {updatedRepos !== null && (
        <>
          <Fab
            onClick={() => handleRepoBulkSave()}
            variant="extended"
            color="secondary"
            style={{ right: "1rem", position: "fixed", bottom: "1rem" }}
          >
            {updatingRepos ? <CircularProgress /> : "Save"}
          </Fab>
        </>
      )}
    </div>
  );
};
