import React, { createContext, useContext } from "react";
import { toast } from "react-toastify";
import { useAuth } from "../AuthProvider";
import { ApiError } from "./ApiError";

export const InternalApiContext = createContext(null);

export const InternalApiProvider = ({ children }) => {
  const { handleUnauthorized, authToken, accountId, userId } = useAuth();
  const apiUrl = process.env.REACT_APP_INTERNAL_API_URL;

  const fetchAllAccounts = async () => {
    return await doGet("/accounts");
  };

  const createAccount = async (accountName, ownerEmail, ownerName) => {
    return await doPost(
      "/accounts",
      {
        accountName: accountName,
        email: ownerEmail,
        ownerName: ownerName,
      },
      "Account Created"
    );
  };

  const doGet = async (urlWithParams) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Access-Control-Request-Method": "GET",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    const response = fetch(apiUrl + urlWithParams, requestOptions)
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            handleUnauthorized(); // Use handleLogout from AuthContext
          } else {
            throw new ApiError("something bad happened");
          }
        }
        return response.json();
      })
      .catch((error) => {
        toast.error("Something went wrong. Please try again later.", {
          //https://stackoverflow.com/questions/62578112/react-toastify-showing-multiple-toast
          toastId: "toastId",
        });
      });

    return response;
  };

  const doPost = async (url, body, successToastMessage = "") => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(body),
    };

    const response = fetch(apiUrl + url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            handleUnauthorized(); // Use handleLogout from AuthContext
          } else if (response.status === 400) {
            throw new ApiError("Invalid input provided");
          } else {
            throw new Error("Unknown error occurred");
          }
        }
        if (successToastMessage !== "" && response.ok) {
          toast.success(successToastMessage);
        }
        return response.json();
      })
      .catch((error) => {
        if (error instanceof ApiError) {
          // propagate any errors like this upwards.
          throw error;
        }
        toast.error("Something went wrong. Please try again later.", {
          //https://stackoverflow.com/questions/62578112/react-toastify-showing-multiple-toast
          toastId: "toastId",
        });
      });

    return response;
  };

  return (
    <InternalApiContext.Provider
      value={{
        fetchAllAccounts,
        createAccount,
      }}
    >
      {children}
    </InternalApiContext.Provider>
  );
};

export const useInternalApi = () => useContext(InternalApiContext);
