import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { useApi } from "../ApiProvider";
import { UserSelector } from "../dashboard/UserSelector";

export const GroupEditorModal = ({ groupId, open, handleClose, level }) => {
  const { fetchGroup, fetchGroupMembers, updateGroup } = useApi();

  const queryClient = useQueryClient();
  const groupDetailsQuery = useQuery({
    queryKey: ["groupDetails", groupId],
    queryFn: () => fetchGroup(groupId),
    onSuccess: (data) => {
      setName(data.name);
    },
  });
  const groupMembersQuery = useQuery({
    queryKey: ["groupMembers", groupId],
    queryFn: () => fetchGroupMembers(groupId),
  });

  const [name, setName] = useState(null);
  const [additions, setAdditions] = useState([]);
  const [updatedMembers, setUpdatedMembers] = useState(null);

  const closeModal = () => {
    handleClose();
  };

  const updateGroupMutation = useMutation({
    mutationFn: (membersPayload) =>
      updateGroup(groupId, name ?? groupDetailsQuery.data.name, membersPayload),
    onSuccess: (data) => {
      queryClient.setQueryData(["groupDetails", groupId], data);
      queryClient.invalidateQueries({ queryKey: ["groups", level] });
      closeModal();
    },
  });

  const members =
    groupMembersQuery.data?.map((u) => ({ ...u, remove: false })) ?? [];

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Edit Group</DialogTitle>
      <DialogContent>
        {groupDetailsQuery.isPending && <CircularProgress />}
        {groupDetailsQuery.isSuccess && (
          <>
            <TextField
              value={name ?? groupDetailsQuery.data.name}
              onChange={(e) => setName(e.target.value)}
              label="Edit Name"
              margin="dense"
            />
            {groupMembersQuery.isPending ? (
              <CircularProgress />
            ) : (
              <>
                <h6>Members</h6>
                {
                  <MembersList
                    members={updatedMembers ?? members}
                    removeMember={(m) => {
                      const valToMutate = updatedMembers ?? members;
                      setUpdatedMembers(
                        valToMutate.map((v, i) => {
                          if (v === m) {
                            return { ...v, remove: !v.remove };
                          } else {
                            return v;
                          }
                        })
                      );
                    }}
                    buttonText={"Remove"}
                  />
                }
                <div>
                  <h6>Add</h6>
                  <UserSelector
                    handleUserSelection={(newValues) => {
                      setAdditions(newValues.map((u) => u.email));
                    }}
                  />
                </div>
              </>
            )}
          </>
        )}
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          {updateGroupMutation.isPending ? (
            <CircularProgress />
          ) : (
            <Button
              variant="contained"
              onClick={() => {
                var membersList = updatedMembers ?? members;
                membersList = membersList
                  .filter((v) => !v.remove)
                  .map((m) => m.email)
                  .concat(additions);
                updateGroupMutation.mutate(membersList);
              }}
            >
              Update
            </Button>
          )}
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

const MembersList = ({ members, removeMember }) => {
  return (
    <List>
      {members.map((m) => (
        <ListItem
          key={m.id}
          secondaryAction={
            <Button
              onClick={() => {
                removeMember(m);
              }}
            >
              {m.remove ? "Restore" : "Remove"}
            </Button>
          }
        >
          {m.remove ? (
            <ListItemText>
              <s>{m.email}</s>
            </ListItemText>
          ) : (
            <ListItemText>{m.email}</ListItemText>
          )}
        </ListItem>
      ))}
    </List>
  );
};
