import {
    AppBar,
    Box,
    Button, Link,
    TextField,
    Toolbar,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";

const ContactUsComponent = () => {
    const theme = useTheme();

    const handleLogin = () => {
        const redirectUri = encodeURIComponent(
            process.env.REACT_APP_GOOGLE_REDIRECT_URI
        ); // Replace with your actual callback URL
        const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID; // dev
        const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=email%20profile`;

        window.location.href = authUrl; // URL to initiate OAuth flow
    };

    const handleEmailBasedLogin = () => {
        window.location.href = "/login";
    };

    const handleContactUs = () => {
        window.location.href = "/contact";
    }
    const handleHomeClick = () => {
        window.location.href = "/";
    };

    const styles = {
        primarySectionBox: {
            width: "100%",
            height: "5em",
            display: "flex", // Use flexbox layout
            alignItems: "center", // Vertically center items
            justifyContent: "center", // Horizontally center items
            textAlign: "justify" /* Evenly distribute text across lines */,
        },
        secondarySectionBox: {
            width: "100%",
            height: "10em",
            display: "flex", // Use flexbox layout
            alignItems: "center", // Vertically center items
            justifyContent: "center", // Horizontally center items
            /* Evenly distribute text across lines */
            textAlign: "center",
        },
        primaryBackground: {
            fontWeight: "bold",
            textAlign: "center",
        },
    };

    return (
        <>
            <AppBar position="sticky" color="inherit">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{cursor: 'pointer'}} onClick={handleHomeClick}>
                        DevLanding
                    </Typography>
                    <Button style={{"margin-right": "1em", cursor: 'pointer'}} color="secondary" variant="contained" onClick={handleContactUs}>
                        Contact Us
                    </Button>
                    <Button variant="contained"
                            color="secondary" style={{cursor: 'pointer'}} onClick={handleEmailBasedLogin}>
                        Log in
                    </Button>
                </Toolbar>
            </AppBar>
            <Box>
                <Box sx={styles.primarySectionBox} component="div">
                    <Typography sx={styles.primaryBackground} variant="h7">
                        Explore Measure Monitor Act. All in one place
                    </Typography>
                </Box>
                <Box sx={styles.secondarySectionBox} component="div">
                    <img className="brand_image" src="/gitHub_logo.png"/>
                    +
                    <img className="brand_image" src="/outlook-calendar.svg"/>
                    +
                    <img className="brand_image" src="/jira-logo-gradient-blue-attribution_rgb.svg"/>
                    +
                    <img className="medium_brand_image" src="/pagerduty-logo.svg"/>
                </Box>
                <Box sx={{my: 4, textAlign: 'center'}}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Contact Us
                    </Typography>
                    <Typography variant="body1">
                        If you have any questions or inquiries, please contact our sales team:
                        sales@devlanding.com
                    </Typography>
                </Box>
            </Box>
        </>
    );
};

export default ContactUsComponent;
