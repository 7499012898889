import React, {useEffect} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {toast} from "react-toastify";

const ErrorBoundary = ({ children }) => {
    useEffect(() => {
        const handleError = (event) => {
            console.warn('Unhandled error:', event.error);
            // Optionally, display a toast message or perform other actions
            // Example using react-toastify
            toast.error('An unexpected error occurred');
        };

        window.addEventListener('error', handleError);

        return () => {
            window.removeEventListener('error', handleError);
        };
    }, []);

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
};

export default ErrorBoundary;