import React, { useState } from "react";
import { useInternalApi } from "../api/InternalApiProvider";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

export const InternalAdminAccounts = () => {
  const navigate = useNavigate();
  const [showCreationModal, setShowCreationModal] = useState(false);
  const [accountEmail, setAccountEmail] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountOwnerName, setAccountOwnerName] = useState("");
  const { fetchAllAccounts, createAccount } = useInternalApi();

  const fetchAllAccountsQuery = useQuery({
    queryFn: fetchAllAccounts,
    queryKey: ["accounts"],
  });

  const queryClient = useQueryClient();

  const createAccountMutation = useMutation({
    mutationFn: () =>
      createAccount(accountName, accountEmail, accountOwnerName),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["accounts"] });
      setShowCreationModal(false);
    },
  });

  const columns = [
    { field: "id", headerName: "Account ID", minWidth: 50, type: "number" },
    { field: "name", headerName: "Name", flex: 1, minWidth: 200 },
    { field: "contactEmail", headerName: "Contact", minWidth: 200, flex: 1 },
  ];

  return (
    <>
      <div className="header-with-actions">
        <h3>Accounts</h3>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setShowCreationModal(true)}
        >
          Create Account
        </Button>
      </div>
      <DataGrid
        columns={columns}
        loading={fetchAllAccountsQuery.isPending}
        rows={fetchAllAccountsQuery.data?.accounts || []}
        disableRowSelectionOnClick
        onRowClick={(row) => {
          navigate(`/admin/accounts/${row.id}`);
        }}
      />
      {showCreationModal && (
        <Dialog
          onClose={() => setShowCreationModal(false)}
          open={showCreationModal}
        >
          <DialogTitle>Create Account</DialogTitle>
          <DialogContent>
            <TextField
              variant="outlined"
              label="Account Name"
              fullWidth
              margin="dense"
              value={accountName}
              onChange={(e) => setAccountName(e.target.value)}
              placeholder="RocketShips"
            />
            <TextField
              variant="outlined"
              label="Admin email"
              fullWidth
              margin="dense"
              type="email"
              value={accountEmail}
              onChange={(e) => setAccountEmail(e.target.value)}
              placeholder="dennis@rocketships.com"
            />
            <TextField
              variant="outlined"
              label="Admin name"
              fullWidth
              margin="dense"
              value={accountOwnerName}
              onChange={(e) => setAccountOwnerName(e.target.value)}
              placeholder="Dennis Nedry"
            />
            <DialogActions>
              <Button
                disabled={
                  accountName.length === 0 ||
                  /^[\w+-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(accountEmail) ===
                    false
                }
                onClick={() => createAccountMutation.mutate()}
              >
                Create
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
