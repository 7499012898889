import React from "react";
import "./email.css";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { MonthTick } from "./MonthTick";
import { Card, Grid, Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// Define a custom tooltip component
const CustomTooltip = ({theme, active, payload, label, users, groups }) => {
  const primaryColor = theme.palette.secondary.main;
  const secondaryColor = theme.palette.text.secondary;
  const tooltip = theme.palette.primary.toolTip;

  if (active && payload && payload.length) {
    return (
      <Paper style={{ padding: "8px", backgroundColor: tooltip}}>
        <h4 style={{color: primaryColor}}>{label}</h4>
        {payload.map((entry, index) => {
          var user;

          for (const element of users) {
            if ("u_" + element.id === entry.name) {
              user = element;
              break;
            }
          }

          for (const element of groups) {
            if ("g_" + element.id === entry.name) {
              user = element;
              break;
            }
          }

          return (
            <p style={{color: secondaryColor}} key={`item-${index}`}>
              <span >{user.name}:</span> {entry.value}
            </p>
          );
        })}
      </Paper>
    );
  }

  return null;
};

export const GraphComponent = ({ graphData, users, groups, title, attribute }) => {

  const theme = useTheme();
  const primaryColor = theme.palette.text.primary;
  const secondaryColor = theme.palette.text.secondary;

  const titleColor = theme.palette.text.primary;

  return (
    <Grid item xs={12} md={6} sm={6}>
      <Card>
        <Typography
          color={titleColor}
          variant="h6"
          component="div"
          sx={{ flexGrow: 1, margin: "1em" }}
        >
          {title}
        </Typography>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            data={graphData}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            syncId="chart"
          >
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis
              dataKey="startOfWeek"
              height={60}
              tick={<MonthTick />}
              interval={0}
            />
            <YAxis />
            {users?.map((user) => (
                <Line animationDuration={3000} isAnimationActive={true}  key={"u_" + user.id} dataKey={`user.${attribute}[${user.id}]`} name={"u_" + user.id} stroke={primaryColor} />
            ))}
            {groups?.map((group) => (
                <Line animationDuration={3000} isAnimationActive={true} key={"g_" + group.id} dataKey={`group.${attribute}[${group.id}]`} name={"g_" + group.id} stroke={secondaryColor} />
            ))}
            <Tooltip
              content={<CustomTooltip theme={theme} users={users} groups={groups} />}
            />
            ;
          </LineChart>
        </ResponsiveContainer>
      </Card>
    </Grid>
  );
};
