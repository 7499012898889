import React, { useEffect, useRef, useState } from "react";
import "../styling.css";
import { UserSelector } from "../dashboard/UserSelector";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "../ApiProvider";

function GroupCreateModal({ isOpen, closeModal, level }) {
  const [emailList, setEmailList] = useState("");
  const [emailsToSave, setEmailsToSave] = useState([]);
  const [name, setName] = useState("");
  const { createGroup } = useApi();

  const textareaRef = useRef(null);

  const queryClient = useQueryClient();
  const createGroupMutation = useMutation({
    mutationFn: (emails) => {
      createGroup(level, name, emails);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["groups", level] });
      handleCloseModalSaveless();
    },
  });

  const handleCloseModal = () => {
    const emails = emailsToSave
      .concat(emailList.split(",").map((v) => v.trim()))
      .sort()
      .filter((value, index, array) => array.indexOf(value) === index)
      .filter((value) => /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value));
    createGroupMutation.mutate(emails);
  };

  const handleCloseModalSaveless = () => {
    setEmailList("");
    setName("");
    closeModal();
  };

  const handlePaste = (event) => {
    const pastedText = event.clipboardData.getData("text");
    // Append the pasted text to the existing text content
    setEmailList((prevEmails) => prevEmails + pastedText);
    // Prevent the default paste behavior (replacing the content)
    event.preventDefault();
  };

  useEffect(() => {
    // Scroll the textarea to the bottom when content changes
    if (textareaRef.current) {
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
    }
  }, [emailList]);

  return (
    <div>
      <Dialog open={isOpen} onClose={handleCloseModalSaveless}>
        <DialogTitle>Create Group</DialogTitle>
        <DialogContent>
          <input
            id="group-name-create"
            type="text"
            placeholder="Group Name"
            onChange={(e) => setName(e.target.value)}
          />
          <UserSelector
            handleUserSelection={(newValues) => {
              setEmailsToSave(newValues.map((u) => u.email));
            }}
          />
          <div className="email-textarea-container">
            {" "}
            {/* Container div */}
            <textarea
              ref={textareaRef}
              id="email-textarea"
              className="email-textarea"
              value={emailList}
              onChange={(e) => setEmailList(e.target.value)}
              onPaste={handlePaste}
              placeholder="Paste CSV email addresses here"
            />
          </div>
          <DialogActions>
            {createGroupMutation.isPending ? (
              <CircularProgress />
            ) : (
              <Button onClick={handleCloseModal} variant="contained">
                Create Group
              </Button>
            )}
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default GroupCreateModal;
