import React, { useState } from "react";
import { useApi } from "../ApiProvider";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { IntegrationIcon } from "./IntegrationIcon";

export const IntegrationTable = ({ curIntegrations, level }) => {
  const { deactivateIntegration } = useApi();
  const queryClient = useQueryClient();

  const deactivateIntegrationMutation = useMutation({
    mutationFn: (integrationId) => deactivateIntegration(integrationId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["integrations", level] });
    },
  });

  const createStatusStr = (isEnabled) => {
    if (isEnabled === true) {
      return "Active";
    } else {
      return "Disabled";
    }
  };

  const columns = [
    {
      field: "enabled",
      headerName: "Active",
      type: "boolean",
    },
    {
      field: "provider",
      headerName: "Type",
      align: "center",
      width: 30,
      renderCell: ({ row }) => {
        return <IntegrationIcon integrationName={row.provider} />;
      },
      cellClassName: "integration-column",
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 300,
      flex: 1,
    },
    {
      field: "created",
      headerName: "Created",
      type: "date",
      valueGetter: (value) => new Date(Date.parse(value)),
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      getActions: ({ row }) => [
        <GridActionsCellItem
          label="Deactivate"
          showInMenu
          onClick={() => deactivateIntegrationMutation.mutate(row.id)}
        />,
      ],
    },
  ];

  return (
    <DataGrid
      rows={curIntegrations}
      columns={columns}
      disableRowSelectionOnClick
    />
  );
};
