import React, {useEffect} from 'react';
import {useApi} from "./ApiProvider";

const JiraAuthCallbackComponent = () => {
    const { createGithubUserLink } = useApi();

    useEffect(() => {
        const fetchAccessToken = async () => {
            try {
                const code = new URLSearchParams(window.location.search).get('code');
                createGithubUserLink(code)
                    .then((response) => {
                        console.log("success from the server, getting an access token!")
                        window.location.href = '/account';
                    });
            } catch (error) {
                console.error('Error fetching access token:', error);
            }
        };

        fetchAccessToken();
    }, []);

    return (
        <div>
            <p>Fetching access token...</p>
        </div>
    );
};

export default JiraAuthCallbackComponent;