import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider"; // Replace with your actual AuthProvider
import { useMutation } from "@tanstack/react-query";
import { CircularProgress } from "@mui/material";

const AuthCallback = () => {
  const { login } = useAuth();
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("code");

  const loginMutation = useMutation({
    mutationFn: () => login(token),
    mutationKey: ["login"],
    onError: () => {
      navigate("/login");
    },
  });

  useEffect(() => {
    console.log("handling auth callback");
    loginMutation.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <CircularProgress />;
};

export default AuthCallback;
