import React, { createContext, useContext } from "react";
import { toast } from "react-toastify";
import { useAuth } from "./AuthProvider";
import { ApiError } from "./api/ApiError";

export const ApiContext = createContext(null);

export const ApiProvider = ({ children }) => {
  const {
    handleUnauthorized,
    authToken,
    accountId,
    userId,
    isImpersonating,
    impersonationToken,
  } = useAuth();
  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchAccounts = async () => {
    const encodedValue = encodeURIComponent(userId);
    const serverObj = await doGet(
      `/accounts/fetchForUser?userId=${encodedValue}`
    );
    return serverObj.userAccounts;
  };
  const fetchIntegrations = async () => {
    const encodedValue = encodeURIComponent(accountId);
    const serverObj = await doGet(
      `/integrations/fetchForAccount?accountId=${encodedValue}`
    );
    return serverObj.integrations;
  };

  const createIntegration = async (provider, token, domainUrl, altEmailAddress) => {
    const body = {
      provider: provider,
      apiToken: token,
      accountId: accountId,
      apiUrl: domainUrl,
      altEmailAddress: altEmailAddress
    };

    return await doPost(`/integrations/create`, body);
  };

  const deactivateIntegration = async (integrationId) => {
    const body = {
      integrationId: integrationId,
    };

    return await doPost(`/integrations/disable`, body);
  };

  const fetchRepos = async () => {
    const accountIdEncoded = encodeURIComponent(accountId);
    const serverObj = await doGet(
      `/repos/fetchRepos?accountId=${accountIdEncoded}`
    );
    return serverObj;
  };

  const updateRepo = async (repo) => {
    const body = {
      id: repo.id,
      indexing: repo.indexing,
      archived: repo.archived,
    };
    const serverObj = await doPost(`/repos/update`, body);
    return serverObj;
  };

  const fetchInsightsDataForUsers = async (userIds, timeWindow) => {
    if (userIds.length === 0) {
      return {
        userSummaries: [],
        weeklySummaries: [],
      };
    }
    const body = {
      userIds: userIds,
      accountId: accountId,
      numMonths: timeWindow,
      requestType: 'USERS'
    };

    const serverObj = await doPost(`/graph/graphData`, body);
    return serverObj;
  };

  const fetchInsightsDataForGroups = async (groupIds, timeWindow) => {
    if (groupIds.length === 0) {
      return {
        userSummaries: [],
        weeklySummaries: [],
      };
    }
    const body = {
      groupIds: groupIds,
      numMonths: timeWindow,
      requestType: 'GROUPS'
    };

    const serverObj = await doPost(`/graph/graphData`, body);
    return serverObj;
  };

  const fetchUserForAccount = async (userEmail) => {
    const encodedValue = encodeURIComponent(accountId);
    const encodedEmail = encodeURIComponent(userEmail);
    const serverObj = await doGet(
      `/accounts/fetchUserByEmail?accountId=${encodedValue}&email=${encodedEmail}`
    );
    return serverObj;
  };

  const fetchAccountScopes = async (overrideAccountId) => {
    let url = "/accounts/accountUserScopes";
    if (overrideAccountId) {
      url = url.concat("?overrideAccountId=", overrideAccountId);
    }
    const serverObj = await doGet(url);
    return serverObj.userScopes;
  };

  const updateAccountScopes = async (userId, userScopesArr) => {
    const body = {
      accountId: accountId,
      userId: userId,
      userScopes: userScopesArr,
    };
    const serverObj = await doPost(`/accounts/accountUserScopes`, body);
    return serverObj.userScopes;
  };

  const fetchGroups = async (type) => {
    const eType = encodeURIComponent(type);
    const eAccountId = encodeURIComponent(accountId);
    const eUserId = encodeURIComponent(userId);
    const serverObj = await doGet(
      `/groups/fetchForUser?accountId=${eAccountId}&userId=${eUserId}&type=${eType}`
    );
    return serverObj.groups;
  };

  const fetchAccountUsers = async () => {
    const eAccountId = encodeURIComponent(accountId);
    const serverObj = await doGet(`/accounts/users?accountId=${eAccountId}`);
    return serverObj.users;
  };

  const fetchUserGroups = async () => {
    const serverObj = await doGet(`/groups/fetchForUser`);
    return serverObj.groups;
  };

  const getUser = async (userId) => {
    return await doGet(`/users/${userId}`);
  };

  const updateUser = async (userId, newUserName) => {
    return await doPost(
      `/users/${userId}`,
      {
        name: newUserName,
      },
      "Name updated"
    );
  };

  const createGroup = async (type, name, emails) => {
    const body = {
      userId: userId,
      accountId: accountId,
      members: emails,
      type: type,
      name: name,
    };
    const serverObj = await doPost(
      `/groups/create`,
      body,
      "Successfully created group"
    );
    return serverObj.userScopes;
  };

  const fetchGroup = async (groupId) => {
    const serverObj = await doGet(`/groups/${groupId}`);
    return serverObj;
  };

  const fetchGroupMembers = async (groupId) => {
    const serverObj = await doGet(`/groups/${groupId}/members`);
    return serverObj;
  };

  const updateGroup = async (groupId, name, emails) => {
    const body = {
      userId: userId,
      accountId: accountId,
      members: emails,
      name: name,
    };
    const serverObj = await doPut(
      `/groups/${groupId}`,
      body,
      `Successfully updated group ${name}`
    );
    return serverObj;
  };

  const deactivateGroup = async (groupId) => {
    const serverObj = await doPost(
      `/groups/${groupId}/deactivate`,
      {},
      `Successfully deactivated group`
    );
    return serverObj;
  };

  const fetchRepoIndexStatus = async (repoId) => {
    const encodedValue = encodeURIComponent(repoId);
    const serverObj = await doGet(
      `/repoIndex/fetchDailyIndexStatus?repoId=${encodedValue}`
    );
    return serverObj;
  };

  const createGithubUserLink = async (gitProvidedCode) => {
    const encodedValue = encodeURIComponent(gitProvidedCode);

    const serverObj = await doGet(
      `/auth/linkGithubUser?code=${encodedValue}&accountId=${accountId}`
    );
    return serverObj;
  };

  const createJiraUserLink = async (jiraProvidedCode) => {
    const encodedValue = encodeURIComponent(jiraProvidedCode);

    const serverObj = await doGet(
        `/auth/linkJiraUser?code=${encodedValue}&accountId=${accountId}`
    );
    return serverObj;
  }

  const getAccountAliases = async () => {
    const serverObj = await doGet(`/aliases/fetchForAccount`);
    return serverObj;
  };

  const getUserAliases = async (userId) => {
    const serverObj = await doGet(`/aliases/fetchForUser?userId=${userId}`);
    return serverObj;
  };

  const createUserAlias = async (userId, aliasType, aliasValue) => {
    const request = {
      userId: userId,
      aliasValue: aliasValue,
      aliasType: aliasType,
    };
    const serverObj = await doPost(
      `/aliases/create`,
      request,
      "Successfully added alias"
    );
    return serverObj;
  };

  const unassignAlias = async (aliasId) => {
    const serverObj = await doPost(
      `/aliases/${aliasId}/unassign`,
      {},
      "Successfully unassigned alias"
    );
    return serverObj;
  };

  const assignAlias = async (aliasId, userId) => {
    const serverObj = await doPost(
      `/aliases/${aliasId}/assign`,
      {
        userId: userId,
      },
      "Successfully mapped alias"
    );
    return serverObj;
  };

  const getJobProgress = async (includeCompleted) => {
    return await doGet(`/jobs/progress?includeCompleted=${includeCompleted}`);
  };

  const inviteUser = async (emailAddress, userName, accessDevlanding) => {
    return await doPost(
      `/accounts/addUser`,
      {
        emailAddress: emailAddress,
        accessDevlanding: accessDevlanding,
        name: userName,
      },
      "User created successfully"
    );
  };

  const uploadUserCsv = (file, accessDevlanding) => {
    const formData = new FormData();
    formData.append("file", file);

    const requestOptions = {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${tokenToUse()}`,
      },
    };
    const response = fetch(
      apiUrl + `/files/users?accessDevlanding=${accessDevlanding}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            handleUnauthorized(); // Use handleLogout from AuthContext
          } else if (response.status === 400) {
            throw new ApiError("Invalid input provided");
          } else {
            throw new Error("Unknown error occurred");
          }
        }
        if (response.ok) {
          toast.success("Uploaded users successfully");
        }
        return response.json();
      })
      .catch((error) => {
        if (error instanceof ApiError) {
          // propagate any errors like this upwards.
          throw error;
        }
        toast.error("Something went wrong. Please try again later.", {
          //https://stackoverflow.com/questions/62578112/react-toastify-showing-multiple-toast
          toastId: "toastId",
        });
      });

    return response;
  };

  const resetPassword = async (userId) => {
    return await doPost(
      `/accounts/resetUser?userId=${userId}`,
      {},
      "User sent password reset successfully"
    );
  };

  const getToken = async (token) => {
    const response = await fetch(
      process.env.REACT_APP_LOGIN + `/invite?token=${token}`
    );
    if (!response.ok) {
      throw new ApiError("Not ok");
    }
    return await response;
  };

  const createLogin = async (token, password) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        authToken: token,
        password: password,
      }),
    };

    const response = await fetch(
      process.env.REACT_APP_LOGIN + `/create`,
      requestOptions
    );
    if (!response.ok) {
      toast.error("Something went wrong. Please try again later.");
      throw new ApiError("something bad happened");
    }

    toast.success("Password set!");
    return await response;
  };

  const requestPwReset = async (username) => {
    if (username.length === 0 || username.length > 50) {
      toast.error("Invalid input provided.");
      throw new ApiError("invalid input");
    }
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
      }),
    };
    const response = await fetch(
      process.env.REACT_APP_LOGIN + `/reset`,
      requestOptions
    );
    if (!response.ok) {
      toast.error("Something went wrong. Please try again later.");
      throw new ApiError("something bad happened");
    }

    toast.success("Password reset requested");
    return await response;
  };

  const fetchAccountSummary = async (overrideAccountId) => {
    let url = `/accounts/summary`;
    if (overrideAccountId) {
      url = url.concat("?overrideAccountId=", overrideAccountId);
    }
    return await doGet(url);
  };

  const updateAccountMetadata = async (name, ownerEmail) => {
    return await doPost(
      `/accounts/metadata`,
      {
        ownerEmail: ownerEmail,
        name: name,
      },
      "Successfully updated account"
    );
  };

  const batchUpdateAliases = async (type, userId, aliases) => {
    const aliasIds = aliases.map((el) => el.id);
    const apiType = type === "Add" ? "ASSIGN" : "UNASSIGN";
    return await doPost(
      `/aliases/update`,
      {
        userId: userId,
        updateType: apiType,
        aliasesToUpdate: aliasIds,
      },
      "Successfully updated aliases"
    );
  };

  const tokenToUse = () => {
    if (isImpersonating()) {
      console.log("impersonation");
      return impersonationToken;
    } else {
      return authToken;
    }
  };

  const doGet = async (urlWithParams) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Access-Control-Request-Method": "GET",
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenToUse()}`,
      },
    };

    const response = fetch(apiUrl + urlWithParams, requestOptions)
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            handleUnauthorized(); // Use handleLogout from AuthContext
          } else {
            throw new ApiError("something bad happened");
          }
        }
        return response.json();
      })
      .catch((error) => {
        toast.error("Something went wrong. Please try again later.", {
          //https://stackoverflow.com/questions/62578112/react-toastify-showing-multiple-toast
          toastId: "toastId",
        });
      });

    return response;
  };

  const doPost = async (url, body, successToastMessage = "") => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenToUse()}`,
      },
      body: JSON.stringify(body),
    };

    const response = fetch(apiUrl + url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            handleUnauthorized(); // Use handleLogout from AuthContext
          } else if (response.status === 400) {
            throw new ApiError("Invalid input provided");
          } else {
            throw new Error("Unknown error occurred");
          }
        }
        if (successToastMessage !== "" && response.ok) {
          toast.success(successToastMessage);
        }
        return response.json();
      })
      .catch((error) => {
        if (error instanceof ApiError) {
          // propagate any errors like this upwards.
          throw error;
        }
        toast.error("Something went wrong. Please try again later.", {
          //https://stackoverflow.com/questions/62578112/react-toastify-showing-multiple-toast
          toastId: "toastId",
        });
      });

    return response;
  };

  const doPut = async (url, body, successToastMessage = "") => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenToUse()}`,
      },
      body: JSON.stringify(body),
    };

    const response = await fetch(apiUrl + url, requestOptions);
    if (!response.ok) {
      if (response.status === 401) {
        handleUnauthorized(); // Use handleLogout from AuthContext
      } else {
        toast.error("Something went wrong. Please try again later.");
        throw new ApiError("something bad happened");
      }
    }
    if (successToastMessage !== "" && response.ok) {
      toast.success(successToastMessage);
    }
    return await response.json();
  };

  return (
    <ApiContext.Provider
      value={{
        fetchAccounts,
        fetchIntegrations,
        createIntegration,
        fetchRepos,
        updateRepo,
        fetchInsightsDataForUsers,
        fetchInsightsDataForGroups,
        fetchUserForAccount,
        fetchAccountScopes,
        updateAccountScopes,
        fetchGroups,
        fetchAccountUsers,
        fetchUserGroups,
        createGroup,
        createGithubUserLink,
        fetchGroup,
        fetchGroupMembers,
        updateGroup,
        deactivateGroup,
        getAccountAliases,
        getUserAliases,
        createUserAlias,
        assignAlias,
        unassignAlias,
        getJobProgress,
        inviteUser,
        resetPassword,
        requestPwReset,
        getToken,
        createLogin,
        fetchAccountSummary,
        updateAccountMetadata,
        batchUpdateAliases,
        deactivateIntegration,
        getUser,
        updateUser,
        uploadUserCsv,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export const useApi = () => useContext(ApiContext);
