import { Button, TextField } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { useApi } from "../ApiProvider";
import { useAuth } from "../AuthProvider";

export const AccountComponent = ({ account }) => {
  const { userHasPermission } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [updatedEmail, setUpdatedEmail] = useState(account.contactEmail);
  const [updatedName, setUpdatedName] = useState(account.name);

  const { updateAccountMetadata } = useApi();
  const queryClient = useQueryClient();

  const updateAccountMutation = useMutation({
    mutationFn: () => updateAccountMetadata(updatedName, updatedEmail),
    onSuccess: (response) => {
      setIsEditing(false);
      queryClient.setQueryData(["accountDetails"], response);
    },
  });

  return (
    <>
      <div className="header-with-actions">
        <h2>Your Account</h2>
        {userHasPermission("manage_account") &&
          (isEditing ? (
            <Button
              onClick={() => {
                updateAccountMutation.mutate();
              }}
              variant="contained"
              color="secondary"
              disabled={updateAccountMutation.isPending}
            >
              Save
            </Button>
          ) : (
            <Button
              onClick={() => setIsEditing(true)}
              color="secondary"
              variant="contained"
            >
              Edit
            </Button>
          ))}
      </div>
      {isEditing ? (
        <>
          <TextField
            value={updatedName}
            onChange={(e) => setUpdatedName(e.target.value)}
            label="Account Name"
          />
          <TextField
            value={updatedEmail}
            onChange={(e) => setUpdatedEmail(e.target.value)}
            label="Contact Email"
          />
        </>
      ) : (
        <>
          <h3 style={{ margin: 0 }}>{account.name}</h3>
          <div>
            Account ID: <b>{account.id}</b>, Status: Active, Contact:
            <b>{account.ownerEmail}</b>
          </div>
          {userHasPermission("manage_billing") && (
            <div>Billing Cadence: Monthly, Billing Status: PAID</div>
          )}
        </>
      )}
    </>
  );
};
