import React from "react";
import { InternalAdminAccounts } from "./InternalAdminAccounts";

export const InternalAdminContainer = () => {
  return (
    <>
      <h2>Internal Admin</h2>
      <InternalAdminAccounts />
    </>
  );
};
