import {createTheme} from "@mui/material";

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    text: {
      primary: "#1A73B2",
      secondary: "#979797",
      content: "#606060"
    },
    primary: {
      light: "#fff",
      main: "#1A73B2",
      contrastText: "#fff",
      background: '#1A73B2',
    },
    secondary: {
      main: "#f59f6b",
      light: "#fff",
      contrastText: "#606060",
    },
    background: {
      default: '#ffffff',
    },
    link: {
      main: '#f59f6b', // Default link color
    },
  },
  typography: {
    fontFamily: "Roboto, Arial, sans-serif",
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        extended: {
          borderRadius: "8px",
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-colCell, & .MuiCheckbox-root': {
            color: 'text.secondary', // Inherit text color from parent
          },
          '& a': {
            color: 'inherit', // Inherit link color from parent
          },
          '& .MuiCheckbox-colorSecondary': {
            color: 'inherit', // Inherit color for both checked and unchecked states
          },
          '& .MuiIconButton-root': {
            color: 'inherit', // Inherit text color for icon buttons
          },
          '& .MuiCheckbox-root': {
            color: 'inherit', // Inherit text color for checkboxes
          },
          '& .MuiDataGrid-cell': {
            color: "#606060" // Reference secondary color
          },
        },
        cell: {
          display: 'flex',
          alignItems: 'center', // Vertically center the content within cells
        },
        columnHeader: {
          '& .MuiDataGrid-colCell': {
            backgroundColor: '#cdc3ee', // Primary theme color for headers
          },
        },
        columnTypeString: { // Override styles for string columns
          '& a': {
            color: 'inherit', // Inherit link color from parent
          },
          '& .MuiCheckbox-colorSecondary': {
            color: 'inherit', // Inherit color for both checked and unchecked states
          },
        },
      },
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      light: "#b3e5fc", // this is used as a secondary background for overlays
      main: "#121212",   // this is used as the background of the element.
      contrastText: "#121212", // text on the item
    },
    background: {
      paper: '#232a41',
      body: '#121212'
    },
    // text: {
    //   primary: "#cdc3ee",
    //   secondary: "#fff",
    //   content: "#fff"
    // },
    secondary: {
      light: "#979797", // secondary background for overlays
      main: "#b3e5fc",  // background on the element
      contrastText: "#121212", // text on the element
    },
    nav: {
      main: '#121212',
      contrastText: '#b3e5fc'
    },
    link: {
      main: '#cdc3ee', // Default link color
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        extended: {
          borderRadius: "8px",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: 'inherit', // Inherit color for both checked and unchecked states
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-main': {
            border: 'none', // Remove the border
          },

          '& .MuiDataGrid-colCell, & .MuiDataGrid-cell, & .MuiCheckbox-root': {
            color: 'inherit', // Inherit text color from parent
          },
          '& .MuiCheckbox-colorSecondary': {
            color: 'inherit', // Inherit color for both checked and unchecked states
          },
          '& .MuiIconButton-root': {
            color: 'inherit', // Inherit text color for icon buttons
          },
          '& .MuiCheckbox-root': {
            color: 'inherit', // Inherit text color for checkboxes
          },
          '& .MuiDataGrid-cell': {
          color: "#fff" // Reference secondary color
          },
        },
        cell: {
          display: 'flex',
          alignItems: 'center', // Vertically center the content within cells
        },
        columnHeader: {
          '& .MuiDataGrid-colCell': {
            backgroundColor: '#cdc3ee', // Primary theme color for headers
          },
        },
        columnTypeString: { // Override styles for string columns
          '& .MuiDataGrid-colCell' : {
            color: 'inherit', // Inherit text color from parent
          },
          '& .MuiCheckbox-colorSecondary': {
            color: 'inherit', // Inherit color for both checked and unchecked states
          },
        },
      },
    }
  },
});

export const useTheme = (prefersDarkMode) => {
  if (prefersDarkMode == true) {
    return darkTheme;
  } else {
    return lightTheme;
  }
};
