import React, { useState } from "react";
import { Container, TextField, Button, Box, Card, Grid } from "@mui/material";
import { useAuth } from "./AuthProvider";
import { useApi } from "./ApiProvider";
import { useMutation } from "@tanstack/react-query";
import LoadingButton from "@mui/lab/LoadingButton";

export const LoginComponent = () => {
  const [email, setEmail] = useState("");
  const [pw, setPw] = useState("");

  const [forgotPw, setForgotPw] = useState(false);
  const { emailBasedLogin } = useAuth();
  const { requestPwReset } = useApi();

  const emailBasedLoginMutation = useMutation({
    mutationFn: () => emailBasedLogin(email, pw),
  });

  const passwordResetMutation = useMutation({
    mutationFn: () => requestPwReset(email),
    onSuccess: () => {
      setForgotPw(false);
    },
  });

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "20px",
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailBasedLoginMutation.mutate();
  };

  return (
    <Grid
      style={{ height: "100vh" }}
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={10} sm={6} md={4} lg={3}>
        <Card variant="outlined">
          <Box alignItems="center" justifyContent="center" display="flex">
            <img
              src="/devlanding-logo-white.jpg"
              alt="Logo"
              style={{
                paddingTop: "2em",
                height: "20em",
                objectFit: "contain",
              }}
            />
          </Box>
          <Container style={containerStyle}>
            <form onSubmit={handleSubmit}>
              {forgotPw && (
                <>
                  <h5>Forgot your password?</h5>
                  <p>
                    Enter your username and we'll email you a reset link if you
                    have an account.
                  </p>
                </>
              )}
              <TextField
                id="username"
                onChange={(e) => setEmail(e.target.value)}
                label="Username"
                variant="outlined"
                fullWidth
                margin="normal"
                required
              />
              {!forgotPw && (
                <>
                  <TextField
                    id="password"
                    onChange={(e) => setPw(e.target.value)}
                    label="Password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    required
                  />
                  <Button onClick={() => setForgotPw(true)}>
                    Forgot your password?
                  </Button>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    size="large"
                    loading={emailBasedLoginMutation.isPending}
                  >
                    Login
                  </LoadingButton>
                </>
              )}
              {forgotPw && (
                <>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    onClick={() => passwordResetMutation.mutate()}
                    loading={passwordResetMutation.isPending}
                  >
                    Continue
                  </LoadingButton>
                  <Button onClick={() => setForgotPw(false)}>Back</Button>
                </>
              )}
            </form>
          </Container>
        </Card>
      </Grid>
    </Grid>
  );
};
