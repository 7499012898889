import {
    AppBar,
    Box,
    Button, Link,
    Toolbar,
    Typography,
} from "@mui/material";
import React from "react";
import {useTheme} from "@mui/material/styles";

const InvestorComponent = () => {
    const theme = useTheme();

    const handleEmailBasedLogin = () => {
        window.location.href = "/login";
    };

    const handleContactUs = () => {
        window.location.href = "/contact";
    }
    const handleHomeClick = () => {
        window.location.href = "/";
    };

    const styles = {
        primarySectionBox: {
            width: "100%",
            display: "flex", // Use flexbox layout
            justifyContent: "center", // Horizontally center items
            textAlign: "justify" /* Evenly distribute text across lines */,
            height: '100vh', // Set the height to fill the whole viewport
            padding: '3em',
        },
        secondarySectionBox: {
            width: "100%",
            height: "10em",
            display: "flex", // Use flexbox layout
            flexDirection: "column"
        },
        primaryBackground: {
            fontWeight: "bold",
            textAlign: "center",
        },
        sectionBox: {
            paddingTop: '2em'
        },
    };

    return (
        <>
            <Box sx={{display: 'flex', flexDirection: 'column', height: '100vh'}}>
                <AppBar position="sticky">
                    <Toolbar>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{cursor: 'pointer'}} onClick={handleHomeClick}>
                            DevLanding
                        </Typography>

                        <Button style={{"margin-right": "1em", cursor: 'pointer'}} color="secondary" variant="contained" onClick={handleContactUs}>
                            Contact Us
                        </Button>

                        <Button variant="contained"
                                color="secondary" style={{cursor: 'pointer'}} onClick={handleEmailBasedLogin}>
                            Log in
                        </Button>

                    </Toolbar>
                </AppBar>
                <Box sx={styles.primarySectionBox}>
                    <Box sx={styles.secondarySectionBox}>
                        <Typography variant="h2">
                            Investors
                        </Typography>
                        <Typography sx={styles.sectionBox} gutterBottom>
                            As a founder funded venture, we are proud of who we are, our mission, and who we aim to be.
                            We are currently a profitable, self sustained business - bringing real value to our partners.
                        </Typography>
                        <Typography sx={styles.sectionBox} gutterBottom>
                            We are not seeking a capital investment at this time.
                            Our company is growing sustainably. Investor relations are focused on client expansion and the network possibilities we gain in new partnerships.
                        </Typography>
                        <Typography sx={styles.sectionBox} gutterBottom>
                            Send us a note: sales@devlanding.com if you are interested in joining our advisory board, or partnering with us in any other way.
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default InvestorComponent;
