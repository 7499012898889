import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useApi } from "./ApiProvider";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

export const UserInvite = () => {
  let [queryParams, setQueryParams] = useSearchParams();
  const navigate = useNavigate();
  const token = queryParams.get("token");

  const [userPassword, setUserPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { getToken, createLogin } = useApi();
  const getTokenQuery = useQuery({
    queryKey: ["token", token],
    queryFn: () => getToken(token),
  });
  const createLoginMutation = useMutation({
    mutationFn: () => createLogin(token, userPassword),
    onSuccess: () => {
      navigate("/login");
    },
  });

  return (
    <Grid
      style={{ height: "100vh" }}
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={10} sm={6} md={4} lg={3}>
        <Card variant="outlined">
          {getTokenQuery.isLoading ? (
            <CircularProgress />
          ) : getTokenQuery.isError ? (
            <p>Invalid token</p>
          ) : (
            <>
              <CardHeader title="Set new password" />
              <CardContent
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <TextField
                  label="New Password"
                  value={userPassword}
                  onChange={(e) => setUserPassword(e.target.value)}
                  type="password"
                />
                <TextField
                  label="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  type="password"
                />
                <LoadingButton
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    createLoginMutation.mutate();
                  }}
                  disabled={
                    userPassword !== confirmPassword || userPassword === ""
                  }
                  loading={createLoginMutation.isPending}
                >
                  Save
                </LoadingButton>
              </CardContent>
            </>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};
