import React, { useState, useEffect } from "react";
import "../styling.css";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import "react-toastify/dist/ReactToastify.css";
import { useApi } from "../ApiProvider";
import { useQuery } from "@tanstack/react-query";
import { UserSelector } from "./UserSelector";
import {Chip, CircularProgress, Grid, InputAdornment, Paper} from "@mui/material";
import { ReportsContainer } from "./ReportsContainer";
import {useTheme} from "@mui/material/styles";

export const ReportsController = () => {
  const { fetchUserGroups } = useApi();

  const theme = useTheme();
  const primaryColor = theme.palette.secondary.main;
  const secondaryColor = theme.palette.secondary.contrastText;
  const bodyBackground = theme.palette.background.body;

  const [userIds, setUserIds] = useState(() => {
    // Retrieve array from localStorage or use default value
    const userIds = localStorage.getItem("userIds");
    return userIds ? JSON.parse(userIds) : [];
  });
  const [groupIds, setGroupIds] = useState(() => {
    // Retrieve array from localStorage or use default value
    const groupIds = localStorage.getItem("groupIds");
    return groupIds ? JSON.parse(groupIds) : [];
  });
  const [displayedGroups, setDisplayedGroups] = useState(() => {
    // Retrieve array from localStorage or use default value
    const displayedGroups = localStorage.getItem("displayedGroups");
    return displayedGroups ? JSON.parse(displayedGroups) : [];
  });
  const [timeWindow, setTimeWindow] = useState({
    apiValue: 6,
    value: "6mo",
    label: "Past 6 months",
  });

  useEffect(() => {
    localStorage.setItem("userIds", JSON.stringify(userIds));
  }, [userIds]);
  useEffect(() => {
    localStorage.setItem("groupIds", JSON.stringify(groupIds));
  }, [groupIds]);
  useEffect(() => {
    localStorage.setItem("displayedGroups", JSON.stringify(displayedGroups));
  }, [displayedGroups]);

  const userGroupsQuery = useQuery({
    queryKey: ["userGroups"],
    queryFn: fetchUserGroups,
  });

  const handleSelectUsers = (newValue) => {
    setUserIds(newValue.map((u) => u.id).sort());
  };

  const handleSelectGroups = (e, newValues) => {
    const uniqueItems = [];
    const seenIds = new Set();

    newValues.forEach((item) => {
      if (!seenIds.has(item.id)) {
        uniqueItems.push(item);
        seenIds.add(item.id);
      }
    });

    setDisplayedGroups(uniqueItems);
    localStorage.setItem("displayedGroups", JSON.stringify(uniqueItems));
    setGroupIds(uniqueItems.map((g) => g.id).sort());
  };

  const handleRenderTags = (value, getTagProps) =>
      value.map((option) => (
          <Chip
              key={option.id}
              label={`${option.name}`}
              color="secondary" // Set chip color to primary (can be customized)
              {...getTagProps({ index: value.indexOf(option) })}
          />
      ));

  return (
    <div className="body-component-container">
      {!userGroupsQuery.isFetched ? (
        <CircularProgress />
      ) : userGroupsQuery.isError ? (
        <></>
      ) : (
        <>
          <Paper
            className="spaced-container"
            style={{
              width: "100%",
              justifyContent: "space-between",
              position: "sticky",
              top: 0,
              zIndex: 100,
              padding: "8px 0",
              background: bodyBackground
            }}
            elevation={0}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} md={3}>
                <UserSelector handleUserSelection={handleSelectUsers} />
              </Grid>
              <Grid item xs={12} md={3}>
                <Autocomplete
                  multiple
                  options={userGroupsQuery.data}
                  getOptionLabel={(option) => option.name}
                  onChange={handleSelectGroups}
                  value={displayedGroups}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Groups"
                      color="info"
                    />
                  )}
                  renderTags={handleRenderTags} // Customize chip appearance

                />
              </Grid>
              <Grid item xs={0} md={3} xl={4} />
              <Grid item xs={12} md={3} xl={2}>
                <Autocomplete
                  options={[
                    // {
                    //   value: "7d",
                    //   label: "Last Week",
                    // },
                    // {
                    //   value: "14d",
                    //   label: "Last 2 weeks",
                    // },
                    {
                      apiValue: 1,
                      value: "1mo",
                      label: "Past month",
                    },
                    {
                      apiValue: 2,
                      value: "2mo",
                      label: "Past 2 months",
                    },
                    {
                      apiValue: 3,
                      value: "3mo",
                      label: "Past 3 months",
                    },
                    {
                      apiValue: 6,
                      value: "6mo",
                      label: "Past 6 months",
                    },
                    {
                      apiValue: 12,
                      value: "1y",
                      label: "Past year",
                    },
                  ]}
                  value={timeWindow}
                  disableClearable
                  onChange={(_, value) => setTimeWindow(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      color="info"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <p
                              style={{
                                borderRadius: "4px",
                                background: primaryColor,
                                padding: "2px",
                                color: secondaryColor,
                              }}
                            >
                              {timeWindow.value}
                            </p>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Paper>
          {(userIds.length !== 0 || groupIds.length !== 0) && (
            <ReportsContainer
              userIds={userIds}
              groupIds={groupIds}
              timeWindow={timeWindow}
              showSummaryTable
            />
          )}
        </>
      )}
    </div>
  );
};
