import React from "react";
import { AccountComponent } from "./AccountComponent";
import { ScopeComponent } from "./ScopeComponent";

import "react-toastify/dist/ReactToastify.css";
import { IntegrationController } from "../integration/IntegrationController";
import { GroupsController } from "../profile/GroupsController";
import { useApi } from "../ApiProvider";
import { AliasTable } from "../aliases/AliasTable";
import { useQuery } from "@tanstack/react-query";
import { Alert, Button, CircularProgress } from "@mui/material";
import { useAuth } from "../AuthProvider";
import { Link, useParams } from "react-router-dom";

export const AccountController = () => {
  const params = useParams();

  const { userHasPermission } = useAuth();
  const { fetchAccountSummary } = useApi();

  const { data, error, isLoading } = useQuery({
    queryKey: ["accountDetails", params.id],
    queryFn: () => fetchAccountSummary(params.id),
  });

  return (
    <div className="body-component-container">
      {userHasPermission("internal_admin") && params.id && (
        <Alert
          severity="warning"
          action={
            <Link to="/admin" style={{ all: "unset" }}>
              <Button color="secondary" size="small">
                Go back to Admin
              </Button>
            </Link>
          }
        >
          Viewing account details for {params.id}
        </Alert>
      )}
      <div className="table-container left">
        <div>
          {isLoading ? (
            <CircularProgress />
          ) : error ? (
            <p>Error fetching account information</p>
          ) : (
            <>
              <AccountComponent account={data} />
            </>
          )}
        </div>
        {(userHasPermission("manage_users") ||
          userHasPermission("internal_admin")) && (
          <div>
            <ScopeComponent overrideAccountId={params.id} />
          </div>
        )}
        {userHasPermission("manage_account") && !params.id && (
          <IntegrationController />
        )}
        {userHasPermission("manage_groupings") && !params.id && (
          <div>
            <GroupsController level="ACCOUNT" />
          </div>
        )}
        {userHasPermission("manage_users") && !params.id && (
          <div>
            <h2>Unassociated Aliases</h2>
            <AliasTable />
          </div>
        )}
      </div>
    </div>
  );
};
