export const loggedOutData = {
    repoTableData: [
        {
            "id": 1,
            "nodeId": "demo data - 1!",
            "integrationId": 1,
            "installationId": 1,
            "accountId": 1,
            "fullUrl": "https://github.com/devlanding-dev/devlanding-api",
            "ownerName": "devlanding-dev",
            "name": "devlanding-api",
            "indexing": true,
            "archived": false,
        },
        {
            "id": 2,
            "nodeId": "demo data - 2!",
            "integrationId": 1,
            "installationId": 1,
            "accountId": 1,
            "fullUrl": "https://github.com/devlanding-dev/devlanding-ui",
            "ownerName": "devlanding-dev",
            "name": "devlanding-ui",
            "indexing": true,
            "archived": false,
        },
        {
            "id": 3,
            "nodeId": "R_kgDOKauQrg",
            "integrationId": 7,
            "installationId": null,
            "accountId": 1,
            "fullUrl": "https://github.com/devlanding-dev/devlanding-api-legacy",
            "ownerName": "devlanding-dev-legacy",
            "name": "devlanding-api",
            "indexing": false,
            "archived": true
        },
        {
            "id": 4,
            "nodeId": "R_kgDOKrQM2w",
            "integrationId": 7,
            "installationId": null,
            "accountId": 1,
            "fullUrl": "https://github.com/devlanding-dev/devlanding-ui-deprecated",
            "ownerName": "devlanding-dev",
            "name": "devlanding-ui-deprecated",
            "indexing": false,
            "archived": true,
        }
    ],
    graphTableControls: [
        {
            "userId": null,
            "groupId": 1,
            "email": null,
            "name": "All Devs",
            "numComments": 88,
            "numCommits": 122.5,
            "numAdds": 40072,
            "numDeletes": 32629,
            "numRepos": null,
            "numPrsOpened": 50.5,
            "numPrsMerged": 49,
            "numPrsHoursToMerge": 278.73,
            "avgPrHoursToMerge": 5.69,
            "numOncallHoursFlat": 532,
            "numPagesAssigned": 0,
            "numPagesResolved": 0,
            "durationPagesToResolve": 0,
            "ticketsAssigned": 0,
            "ticketsClosed": 0,
            "bugsAssigned": 0,
            "bugsClosed": 0,
            "ticketsTransferred": 0,
            "durationTicketAssigned": 0,
            "durationBugAssigned": 0,
            "isEnabledForGraphs": true,
            "isMarkedForRemove": false
        }
    ],
    userTableControls: [{
            "userId": 1,
            "groupId": null,
            "email": null,
            "name": "Dennis Neddry",
            "numComments": 125,
            "numCommits": 217,
            "numAdds": 74744,
            "numDeletes": 62808,
            "numRepos": null,
            "numPrsOpened": 90,
            "numPrsMerged": 87,
            "numPrsHoursToMerge": 289,
            "avgPrHoursToMerge": 3.32,
            "numOncallHoursFlat": 872.19,
            "numPagesAssigned": 1,
            "numPagesResolved": 1,
            "durationPagesToResolve": 59.78,
            "ticketsAssigned": 1,
            "ticketsClosed": 0,
            "bugsAssigned": 2,
            "bugsClosed": 2,
            "ticketsTransferred": 1,
            "durationTicketAssigned": 0,
            "durationBugAssigned": 0.13,
            "isEnabledForGraphs": true,
            "isMarkedForRemove": false
        },
        {
            "userId": 2,
            "groupId": null,
            "email": null,
            "name": "Stanley Jobson",
            "numComments": 52,
            "numCommits": 36,
            "numAdds": 6105,
            "numDeletes": 2651,
            "numRepos": null,
            "numPrsOpened": 19,
            "numPrsMerged": 19,
            "numPrsHoursToMerge": 277,
            "avgPrHoursToMerge": 14.58,
            "numOncallHoursFlat": 192.03,
            "numPagesAssigned": 0,
            "numPagesResolved": 0,
            "durationPagesToResolve": 0,
            "ticketsAssigned": 0,
            "ticketsClosed": 0,
            "bugsAssigned": 0,
            "bugsClosed": 0,
            "ticketsTransferred": 0,
            "durationTicketAssigned": 0,
            "durationBugAssigned": 0,
            "isEnabledForGraphs": true,
            "isMarkedForRemove": false
        }
    ],
    graphUserNames: [
        {
            "id": 1,
            "name": "Dennis Neddry",
            "stroke": "#f2c74b"
        },
        {
            "id": 2,
            "name": "Stanley Jobson",
            "stroke": "#2b5490"
        }
    ],
    graphGroupNames: [
        {
            "id": 2,
            "name": "Senior Devs  - Avg",
            "stroke": "#66035f"
        }
    ],
    graphDataFinalMerged: [
        {
            "startOfWeek": "2023-11-05",
            "user": {
                "startOfWeek": "2023-11-05",
                "wonCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "wpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "wpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "wticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "wdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wcommits": {
                    "1": 5,
                    "2": 0
                },
                "wadds": {
                    "1": 3399,
                    "2": 0
                },
                "wdeletes": {
                    "1": 373,
                    "2": 0
                },
                "wcomments": {
                    "1": 0,
                    "2": 0
                },
                "wpullsOpened": {
                    "1": 1,
                    "2": 0
                },
                "wpullsMerged": {
                    "1": 1,
                    "2": 0
                },
                "wpullsHoursToMerge": {
                    "1": 53,
                    "2": 0
                },
                "rcommits": {
                    "1": 5,
                    "2": 0
                },
                "radds": {
                    "1": 3399,
                    "2": 0
                },
                "rdeletes": {
                    "1": 373,
                    "2": 0
                },
                "rcomments": {
                    "1": 0,
                    "2": 0
                },
                "rpullsOpened": {
                    "1": 1,
                    "2": 0
                },
                "rpullsMerged": {
                    "1": 1,
                    "2": 0
                },
                "rpullsHoursToMerge": {
                    "1": 53,
                    "2": 0
                },
                "ronCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "rpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "rpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "rticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                }
            },
            "group": {
                "startOfWeek": "2023-11-05",
                "wonCallFlatHours": {
                    "1": 0
                },
                "wpagesAssigned": {
                    "1": 0
                },
                "wpagesResolved": {
                    "1": 0
                },
                "wpageDurationToResolve": {
                    "1": 0
                },
                "wticketsAssigned": {},
                "wticketsClosed": {},
                "wbugsAssigned": {},
                "wbugsClosed": {},
                "wticketsTransferred": {},
                "wdurationTicketAssigned": {},
                "wdurationBugAssigned": {},
                "wcommits": {
                    "1": 2.5
                },
                "wadds": {
                    "1": 1699.5
                },
                "wdeletes": {
                    "1": 186.5
                },
                "wcomments": {
                    "1": 0
                },
                "wpullsOpened": {
                    "1": 0.5
                },
                "wpullsMerged": {
                    "1": 0.5
                },
                "wpullsHoursToMerge": {
                    "1": 106
                },
                "rcommits": {
                    "1": 2.5
                },
                "radds": {
                    "1": 1699.5
                },
                "rdeletes": {
                    "1": 186.5
                },
                "rcomments": {
                    "1": 0
                },
                "rpullsOpened": {
                    "1": 0.5
                },
                "rpullsMerged": {
                    "1": 0.5
                },
                "rpullsHoursToMerge": {
                    "1": 106
                },
                "ronCallFlatHours": {
                    "1": 0
                },
                "rpagesAssigned": {
                    "1": 0
                },
                "rpagesResolved": {
                    "1": 0
                },
                "rpageDurationToResolve": {
                    "1": 0
                },
                "rticketsAssigned": {
                    "1": 0
                },
                "rticketsClosed": {
                    "1": 0
                },
                "rbugsAssigned": {
                    "1": 0
                },
                "rbugsClosed": {
                    "1": 0
                },
                "rticketsTransferred": {
                    "1": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0
                },
                "rdurationBugAssigned": {
                    "1": 0
                }
            }
        },
        {
            "startOfWeek": "2023-11-12",
            "user": {
                "startOfWeek": "2023-11-12",
                "wonCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "wpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "wpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "wticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "wdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wcommits": {
                    "1": 4,
                    "2": 0
                },
                "wadds": {
                    "1": 745,
                    "2": 0
                },
                "wdeletes": {
                    "1": 203,
                    "2": 0
                },
                "wcomments": {
                    "1": 0,
                    "2": 0
                },
                "wpullsOpened": {
                    "1": 0,
                    "2": 0
                },
                "wpullsMerged": {
                    "1": 0,
                    "2": 0
                },
                "wpullsHoursToMerge": {
                    "1": 0,
                    "2": 0
                },
                "rcommits": {
                    "1": 9,
                    "2": 0
                },
                "radds": {
                    "1": 4144,
                    "2": 0
                },
                "rdeletes": {
                    "1": 576,
                    "2": 0
                },
                "rcomments": {
                    "1": 0,
                    "2": 0
                },
                "rpullsOpened": {
                    "1": 1,
                    "2": 0
                },
                "rpullsMerged": {
                    "1": 1,
                    "2": 0
                },
                "rpullsHoursToMerge": {
                    "1": 53,
                    "2": 0
                },
                "ronCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "rpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "rpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "rticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                }
            },
            "group": {
                "startOfWeek": "2023-11-12",
                "wonCallFlatHours": {
                    "1": 0
                },
                "wpagesAssigned": {
                    "1": 0
                },
                "wpagesResolved": {
                    "1": 0
                },
                "wpageDurationToResolve": {
                    "1": 0
                },
                "wticketsAssigned": {},
                "wticketsClosed": {},
                "wbugsAssigned": {},
                "wbugsClosed": {},
                "wticketsTransferred": {},
                "wdurationTicketAssigned": {},
                "wdurationBugAssigned": {},
                "wcommits": {
                    "1": 2
                },
                "wadds": {
                    "1": 372.5
                },
                "wdeletes": {
                    "1": 101.5
                },
                "wcomments": {
                    "1": 0
                },
                "wpullsOpened": {
                    "1": 0
                },
                "wpullsMerged": {
                    "1": 0
                },
                "wpullsHoursToMerge": {
                    "1": 0
                },
                "rcommits": {
                    "1": 4.5
                },
                "radds": {
                    "1": 2072
                },
                "rdeletes": {
                    "1": 288
                },
                "rcomments": {
                    "1": 0
                },
                "rpullsOpened": {
                    "1": 0.5
                },
                "rpullsMerged": {
                    "1": 0.5
                },
                "rpullsHoursToMerge": {
                    "1": 106
                },
                "ronCallFlatHours": {
                    "1": 0
                },
                "rpagesAssigned": {
                    "1": 0
                },
                "rpagesResolved": {
                    "1": 0
                },
                "rpageDurationToResolve": {
                    "1": 0
                },
                "rticketsAssigned": {
                    "1": 0
                },
                "rticketsClosed": {
                    "1": 0
                },
                "rbugsAssigned": {
                    "1": 0
                },
                "rbugsClosed": {
                    "1": 0
                },
                "rticketsTransferred": {
                    "1": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0
                },
                "rdurationBugAssigned": {
                    "1": 0
                }
            }
        },
        {
            "startOfWeek": "2023-11-19",
            "user": {
                "startOfWeek": "2023-11-19",
                "wonCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "wpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "wpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "wticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "wdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wcommits": {
                    "1": 0,
                    "2": 0
                },
                "wadds": {
                    "1": 0,
                    "2": 0
                },
                "wdeletes": {
                    "1": 0,
                    "2": 0
                },
                "wcomments": {
                    "1": 0,
                    "2": 0
                },
                "wpullsOpened": {
                    "1": 0,
                    "2": 0
                },
                "wpullsMerged": {
                    "1": 0,
                    "2": 0
                },
                "wpullsHoursToMerge": {
                    "1": 0,
                    "2": 0
                },
                "rcommits": {
                    "1": 9,
                    "2": 0
                },
                "radds": {
                    "1": 4144,
                    "2": 0
                },
                "rdeletes": {
                    "1": 576,
                    "2": 0
                },
                "rcomments": {
                    "1": 0,
                    "2": 0
                },
                "rpullsOpened": {
                    "1": 1,
                    "2": 0
                },
                "rpullsMerged": {
                    "1": 1,
                    "2": 0
                },
                "rpullsHoursToMerge": {
                    "1": 53,
                    "2": 0
                },
                "ronCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "rpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "rpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "rticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                }
            },
            "group": {
                "startOfWeek": "2023-11-19",
                "wonCallFlatHours": {
                    "1": 0
                },
                "wpagesAssigned": {
                    "1": 0
                },
                "wpagesResolved": {
                    "1": 0
                },
                "wpageDurationToResolve": {
                    "1": 0
                },
                "wticketsAssigned": {},
                "wticketsClosed": {},
                "wbugsAssigned": {},
                "wbugsClosed": {},
                "wticketsTransferred": {},
                "wdurationTicketAssigned": {},
                "wdurationBugAssigned": {},
                "wcommits": {
                    "1": 0
                },
                "wadds": {
                    "1": 0
                },
                "wdeletes": {
                    "1": 0
                },
                "wcomments": {
                    "1": 0
                },
                "wpullsOpened": {
                    "1": 0
                },
                "wpullsMerged": {
                    "1": 0
                },
                "wpullsHoursToMerge": {
                    "1": 0
                },
                "rcommits": {
                    "1": 4.5
                },
                "radds": {
                    "1": 2072
                },
                "rdeletes": {
                    "1": 288
                },
                "rcomments": {
                    "1": 0
                },
                "rpullsOpened": {
                    "1": 0.5
                },
                "rpullsMerged": {
                    "1": 0.5
                },
                "rpullsHoursToMerge": {
                    "1": 106
                },
                "ronCallFlatHours": {
                    "1": 0
                },
                "rpagesAssigned": {
                    "1": 0
                },
                "rpagesResolved": {
                    "1": 0
                },
                "rpageDurationToResolve": {
                    "1": 0
                },
                "rticketsAssigned": {
                    "1": 0
                },
                "rticketsClosed": {
                    "1": 0
                },
                "rbugsAssigned": {
                    "1": 0
                },
                "rbugsClosed": {
                    "1": 0
                },
                "rticketsTransferred": {
                    "1": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0
                },
                "rdurationBugAssigned": {
                    "1": 0
                }
            }
        },
        {
            "startOfWeek": "2023-11-26",
            "user": {
                "startOfWeek": "2023-11-26",
                "wonCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "wpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "wpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "wticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "wdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wcommits": {
                    "1": 11,
                    "2": 0
                },
                "wadds": {
                    "1": 3699,
                    "2": 0
                },
                "wdeletes": {
                    "1": 834,
                    "2": 0
                },
                "wcomments": {
                    "1": 0,
                    "2": 0
                },
                "wpullsOpened": {
                    "1": 2,
                    "2": 0
                },
                "wpullsMerged": {
                    "1": 2,
                    "2": 0
                },
                "wpullsHoursToMerge": {
                    "1": 0,
                    "2": 0
                },
                "rcommits": {
                    "1": 20,
                    "2": 0
                },
                "radds": {
                    "1": 7843,
                    "2": 0
                },
                "rdeletes": {
                    "1": 1410,
                    "2": 0
                },
                "rcomments": {
                    "1": 0,
                    "2": 0
                },
                "rpullsOpened": {
                    "1": 3,
                    "2": 0
                },
                "rpullsMerged": {
                    "1": 3,
                    "2": 0
                },
                "rpullsHoursToMerge": {
                    "1": 53,
                    "2": 0
                },
                "ronCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "rpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "rpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "rticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                }
            },
            "group": {
                "startOfWeek": "2023-11-26",
                "wonCallFlatHours": {
                    "1": 0
                },
                "wpagesAssigned": {
                    "1": 0
                },
                "wpagesResolved": {
                    "1": 0
                },
                "wpageDurationToResolve": {
                    "1": 0
                },
                "wticketsAssigned": {},
                "wticketsClosed": {},
                "wbugsAssigned": {},
                "wbugsClosed": {},
                "wticketsTransferred": {},
                "wdurationTicketAssigned": {},
                "wdurationBugAssigned": {},
                "wcommits": {
                    "1": 5.5
                },
                "wadds": {
                    "1": 1849.5
                },
                "wdeletes": {
                    "1": 417
                },
                "wcomments": {
                    "1": 0
                },
                "wpullsOpened": {
                    "1": 1
                },
                "wpullsMerged": {
                    "1": 1
                },
                "wpullsHoursToMerge": {
                    "1": 0
                },
                "rcommits": {
                    "1": 10
                },
                "radds": {
                    "1": 3921.5
                },
                "rdeletes": {
                    "1": 705
                },
                "rcomments": {
                    "1": 0
                },
                "rpullsOpened": {
                    "1": 1.5
                },
                "rpullsMerged": {
                    "1": 1.5
                },
                "rpullsHoursToMerge": {
                    "1": 106
                },
                "ronCallFlatHours": {
                    "1": 0
                },
                "rpagesAssigned": {
                    "1": 0
                },
                "rpagesResolved": {
                    "1": 0
                },
                "rpageDurationToResolve": {
                    "1": 0
                },
                "rticketsAssigned": {
                    "1": 0
                },
                "rticketsClosed": {
                    "1": 0
                },
                "rbugsAssigned": {
                    "1": 0
                },
                "rbugsClosed": {
                    "1": 0
                },
                "rticketsTransferred": {
                    "1": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0
                },
                "rdurationBugAssigned": {
                    "1": 0
                }
            }
        },
        {
            "startOfWeek": "2023-12-03",
            "user": {
                "startOfWeek": "2023-12-03",
                "wonCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "wpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "wpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "wticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "wdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wcommits": {
                    "1": 2,
                    "2": 0
                },
                "wadds": {
                    "1": 812,
                    "2": 0
                },
                "wdeletes": {
                    "1": 169,
                    "2": 0
                },
                "wcomments": {
                    "1": 0,
                    "2": 0
                },
                "wpullsOpened": {
                    "1": 0,
                    "2": 0
                },
                "wpullsMerged": {
                    "1": 0,
                    "2": 0
                },
                "wpullsHoursToMerge": {
                    "1": 0,
                    "2": 0
                },
                "rcommits": {
                    "1": 22,
                    "2": 0
                },
                "radds": {
                    "1": 8655,
                    "2": 0
                },
                "rdeletes": {
                    "1": 1579,
                    "2": 0
                },
                "rcomments": {
                    "1": 0,
                    "2": 0
                },
                "rpullsOpened": {
                    "1": 3,
                    "2": 0
                },
                "rpullsMerged": {
                    "1": 3,
                    "2": 0
                },
                "rpullsHoursToMerge": {
                    "1": 53,
                    "2": 0
                },
                "ronCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "rpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "rpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "rticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                }
            },
            "group": {
                "startOfWeek": "2023-12-03",
                "wonCallFlatHours": {
                    "1": 0
                },
                "wpagesAssigned": {
                    "1": 0
                },
                "wpagesResolved": {
                    "1": 0
                },
                "wpageDurationToResolve": {
                    "1": 0
                },
                "wticketsAssigned": {},
                "wticketsClosed": {},
                "wbugsAssigned": {},
                "wbugsClosed": {},
                "wticketsTransferred": {},
                "wdurationTicketAssigned": {},
                "wdurationBugAssigned": {},
                "wcommits": {
                    "1": 1
                },
                "wadds": {
                    "1": 406
                },
                "wdeletes": {
                    "1": 84.5
                },
                "wcomments": {
                    "1": 0
                },
                "wpullsOpened": {
                    "1": 0
                },
                "wpullsMerged": {
                    "1": 0
                },
                "wpullsHoursToMerge": {
                    "1": 0
                },
                "rcommits": {
                    "1": 11
                },
                "radds": {
                    "1": 4327.5
                },
                "rdeletes": {
                    "1": 789.5
                },
                "rcomments": {
                    "1": 0
                },
                "rpullsOpened": {
                    "1": 1.5
                },
                "rpullsMerged": {
                    "1": 1.5
                },
                "rpullsHoursToMerge": {
                    "1": 106
                },
                "ronCallFlatHours": {
                    "1": 0
                },
                "rpagesAssigned": {
                    "1": 0
                },
                "rpagesResolved": {
                    "1": 0
                },
                "rpageDurationToResolve": {
                    "1": 0
                },
                "rticketsAssigned": {
                    "1": 0
                },
                "rticketsClosed": {
                    "1": 0
                },
                "rbugsAssigned": {
                    "1": 0
                },
                "rbugsClosed": {
                    "1": 0
                },
                "rticketsTransferred": {
                    "1": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0
                },
                "rdurationBugAssigned": {
                    "1": 0
                }
            }
        },
        {
            "startOfWeek": "2023-12-10",
            "user": {
                "startOfWeek": "2023-12-10",
                "wonCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "wpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "wpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "wticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "wdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wcommits": {
                    "1": 3,
                    "2": 0
                },
                "wadds": {
                    "1": 2061,
                    "2": 0
                },
                "wdeletes": {
                    "1": 712,
                    "2": 0
                },
                "wcomments": {
                    "1": 0,
                    "2": 0
                },
                "wpullsOpened": {
                    "1": 0,
                    "2": 0
                },
                "wpullsMerged": {
                    "1": 0,
                    "2": 0
                },
                "wpullsHoursToMerge": {
                    "1": 0,
                    "2": 0
                },
                "rcommits": {
                    "1": 25,
                    "2": 0
                },
                "radds": {
                    "1": 10716,
                    "2": 0
                },
                "rdeletes": {
                    "1": 2291,
                    "2": 0
                },
                "rcomments": {
                    "1": 0,
                    "2": 0
                },
                "rpullsOpened": {
                    "1": 3,
                    "2": 0
                },
                "rpullsMerged": {
                    "1": 3,
                    "2": 0
                },
                "rpullsHoursToMerge": {
                    "1": 53,
                    "2": 0
                },
                "ronCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "rpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "rpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "rticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                }
            },
            "group": {
                "startOfWeek": "2023-12-10",
                "wonCallFlatHours": {
                    "1": 0
                },
                "wpagesAssigned": {
                    "1": 0
                },
                "wpagesResolved": {
                    "1": 0
                },
                "wpageDurationToResolve": {
                    "1": 0
                },
                "wticketsAssigned": {},
                "wticketsClosed": {},
                "wbugsAssigned": {},
                "wbugsClosed": {},
                "wticketsTransferred": {},
                "wdurationTicketAssigned": {},
                "wdurationBugAssigned": {},
                "wcommits": {
                    "1": 1.5
                },
                "wadds": {
                    "1": 1030.5
                },
                "wdeletes": {
                    "1": 356
                },
                "wcomments": {
                    "1": 0
                },
                "wpullsOpened": {
                    "1": 0
                },
                "wpullsMerged": {
                    "1": 0
                },
                "wpullsHoursToMerge": {
                    "1": 0
                },
                "rcommits": {
                    "1": 12.5
                },
                "radds": {
                    "1": 5358
                },
                "rdeletes": {
                    "1": 1145.5
                },
                "rcomments": {
                    "1": 0
                },
                "rpullsOpened": {
                    "1": 1.5
                },
                "rpullsMerged": {
                    "1": 1.5
                },
                "rpullsHoursToMerge": {
                    "1": 106
                },
                "ronCallFlatHours": {
                    "1": 0
                },
                "rpagesAssigned": {
                    "1": 0
                },
                "rpagesResolved": {
                    "1": 0
                },
                "rpageDurationToResolve": {
                    "1": 0
                },
                "rticketsAssigned": {
                    "1": 0
                },
                "rticketsClosed": {
                    "1": 0
                },
                "rbugsAssigned": {
                    "1": 0
                },
                "rbugsClosed": {
                    "1": 0
                },
                "rticketsTransferred": {
                    "1": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0
                },
                "rdurationBugAssigned": {
                    "1": 0
                }
            }
        },
        {
            "startOfWeek": "2023-12-17",
            "user": {
                "startOfWeek": "2023-12-17",
                "wonCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "wpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "wpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "wticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "wdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wcommits": {
                    "1": 1,
                    "2": 0
                },
                "wadds": {
                    "1": 997,
                    "2": 0
                },
                "wdeletes": {
                    "1": 1147,
                    "2": 0
                },
                "wcomments": {
                    "1": 0,
                    "2": 0
                },
                "wpullsOpened": {
                    "1": 0,
                    "2": 0
                },
                "wpullsMerged": {
                    "1": 0,
                    "2": 0
                },
                "wpullsHoursToMerge": {
                    "1": 0,
                    "2": 0
                },
                "rcommits": {
                    "1": 26,
                    "2": 0
                },
                "radds": {
                    "1": 11713,
                    "2": 0
                },
                "rdeletes": {
                    "1": 3438,
                    "2": 0
                },
                "rcomments": {
                    "1": 0,
                    "2": 0
                },
                "rpullsOpened": {
                    "1": 3,
                    "2": 0
                },
                "rpullsMerged": {
                    "1": 3,
                    "2": 0
                },
                "rpullsHoursToMerge": {
                    "1": 53,
                    "2": 0
                },
                "ronCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "rpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "rpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "rticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                }
            },
            "group": {
                "startOfWeek": "2023-12-17",
                "wonCallFlatHours": {
                    "1": 0
                },
                "wpagesAssigned": {
                    "1": 0
                },
                "wpagesResolved": {
                    "1": 0
                },
                "wpageDurationToResolve": {
                    "1": 0
                },
                "wticketsAssigned": {},
                "wticketsClosed": {},
                "wbugsAssigned": {},
                "wbugsClosed": {},
                "wticketsTransferred": {},
                "wdurationTicketAssigned": {},
                "wdurationBugAssigned": {},
                "wcommits": {
                    "1": 0.5
                },
                "wadds": {
                    "1": 498.5
                },
                "wdeletes": {
                    "1": 573.5
                },
                "wcomments": {
                    "1": 0
                },
                "wpullsOpened": {
                    "1": 0
                },
                "wpullsMerged": {
                    "1": 0
                },
                "wpullsHoursToMerge": {
                    "1": 0
                },
                "rcommits": {
                    "1": 13
                },
                "radds": {
                    "1": 5856.5
                },
                "rdeletes": {
                    "1": 1719
                },
                "rcomments": {
                    "1": 0
                },
                "rpullsOpened": {
                    "1": 1.5
                },
                "rpullsMerged": {
                    "1": 1.5
                },
                "rpullsHoursToMerge": {
                    "1": 106
                },
                "ronCallFlatHours": {
                    "1": 0
                },
                "rpagesAssigned": {
                    "1": 0
                },
                "rpagesResolved": {
                    "1": 0
                },
                "rpageDurationToResolve": {
                    "1": 0
                },
                "rticketsAssigned": {
                    "1": 0
                },
                "rticketsClosed": {
                    "1": 0
                },
                "rbugsAssigned": {
                    "1": 0
                },
                "rbugsClosed": {
                    "1": 0
                },
                "rticketsTransferred": {
                    "1": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0
                },
                "rdurationBugAssigned": {
                    "1": 0
                }
            }
        },
        {
            "startOfWeek": "2023-12-24",
            "user": {
                "startOfWeek": "2023-12-24",
                "wonCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "wpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "wpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "wticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "wdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wcommits": {
                    "1": 2,
                    "2": 0
                },
                "wadds": {
                    "1": 614,
                    "2": 0
                },
                "wdeletes": {
                    "1": 278,
                    "2": 0
                },
                "wcomments": {
                    "1": 0,
                    "2": 0
                },
                "wpullsOpened": {
                    "1": 0,
                    "2": 0
                },
                "wpullsMerged": {
                    "1": 0,
                    "2": 0
                },
                "wpullsHoursToMerge": {
                    "1": 0,
                    "2": 0
                },
                "rcommits": {
                    "1": 28,
                    "2": 0
                },
                "radds": {
                    "1": 12327,
                    "2": 0
                },
                "rdeletes": {
                    "1": 3716,
                    "2": 0
                },
                "rcomments": {
                    "1": 0,
                    "2": 0
                },
                "rpullsOpened": {
                    "1": 3,
                    "2": 0
                },
                "rpullsMerged": {
                    "1": 3,
                    "2": 0
                },
                "rpullsHoursToMerge": {
                    "1": 53,
                    "2": 0
                },
                "ronCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "rpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "rpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "rticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                }
            },
            "group": {
                "startOfWeek": "2023-12-24",
                "wonCallFlatHours": {
                    "1": 0
                },
                "wpagesAssigned": {
                    "1": 0
                },
                "wpagesResolved": {
                    "1": 0
                },
                "wpageDurationToResolve": {
                    "1": 0
                },
                "wticketsAssigned": {},
                "wticketsClosed": {},
                "wbugsAssigned": {},
                "wbugsClosed": {},
                "wticketsTransferred": {},
                "wdurationTicketAssigned": {},
                "wdurationBugAssigned": {},
                "wcommits": {
                    "1": 1
                },
                "wadds": {
                    "1": 307
                },
                "wdeletes": {
                    "1": 139
                },
                "wcomments": {
                    "1": 0
                },
                "wpullsOpened": {
                    "1": 0
                },
                "wpullsMerged": {
                    "1": 0
                },
                "wpullsHoursToMerge": {
                    "1": 0
                },
                "rcommits": {
                    "1": 14
                },
                "radds": {
                    "1": 6163.5
                },
                "rdeletes": {
                    "1": 1858
                },
                "rcomments": {
                    "1": 0
                },
                "rpullsOpened": {
                    "1": 1.5
                },
                "rpullsMerged": {
                    "1": 1.5
                },
                "rpullsHoursToMerge": {
                    "1": 106
                },
                "ronCallFlatHours": {
                    "1": 0
                },
                "rpagesAssigned": {
                    "1": 0
                },
                "rpagesResolved": {
                    "1": 0
                },
                "rpageDurationToResolve": {
                    "1": 0
                },
                "rticketsAssigned": {
                    "1": 0
                },
                "rticketsClosed": {
                    "1": 0
                },
                "rbugsAssigned": {
                    "1": 0
                },
                "rbugsClosed": {
                    "1": 0
                },
                "rticketsTransferred": {
                    "1": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0
                },
                "rdurationBugAssigned": {
                    "1": 0
                }
            }
        },
        {
            "startOfWeek": "2023-12-31",
            "user": {
                "startOfWeek": "2023-12-31",
                "wonCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "wpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "wpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "wticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "wdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wcommits": {
                    "1": 0,
                    "2": 0
                },
                "wadds": {
                    "1": 0,
                    "2": 0
                },
                "wdeletes": {
                    "1": 0,
                    "2": 0
                },
                "wcomments": {
                    "1": 0,
                    "2": 0
                },
                "wpullsOpened": {
                    "1": 0,
                    "2": 0
                },
                "wpullsMerged": {
                    "1": 0,
                    "2": 0
                },
                "wpullsHoursToMerge": {
                    "1": 0,
                    "2": 0
                },
                "rcommits": {
                    "1": 28,
                    "2": 0
                },
                "radds": {
                    "1": 12327,
                    "2": 0
                },
                "rdeletes": {
                    "1": 3716,
                    "2": 0
                },
                "rcomments": {
                    "1": 0,
                    "2": 0
                },
                "rpullsOpened": {
                    "1": 3,
                    "2": 0
                },
                "rpullsMerged": {
                    "1": 3,
                    "2": 0
                },
                "rpullsHoursToMerge": {
                    "1": 53,
                    "2": 0
                },
                "ronCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "rpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "rpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "rticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                }
            },
            "group": {
                "startOfWeek": "2023-12-31",
                "wonCallFlatHours": {
                    "1": 0
                },
                "wpagesAssigned": {
                    "1": 0
                },
                "wpagesResolved": {
                    "1": 0
                },
                "wpageDurationToResolve": {
                    "1": 0
                },
                "wticketsAssigned": {},
                "wticketsClosed": {},
                "wbugsAssigned": {},
                "wbugsClosed": {},
                "wticketsTransferred": {},
                "wdurationTicketAssigned": {},
                "wdurationBugAssigned": {},
                "wcommits": {
                    "1": 0
                },
                "wadds": {
                    "1": 0
                },
                "wdeletes": {
                    "1": 0
                },
                "wcomments": {
                    "1": 0
                },
                "wpullsOpened": {
                    "1": 0
                },
                "wpullsMerged": {
                    "1": 0
                },
                "wpullsHoursToMerge": {
                    "1": 0
                },
                "rcommits": {
                    "1": 14
                },
                "radds": {
                    "1": 6163.5
                },
                "rdeletes": {
                    "1": 1858
                },
                "rcomments": {
                    "1": 0
                },
                "rpullsOpened": {
                    "1": 1.5
                },
                "rpullsMerged": {
                    "1": 1.5
                },
                "rpullsHoursToMerge": {
                    "1": 106
                },
                "ronCallFlatHours": {
                    "1": 0
                },
                "rpagesAssigned": {
                    "1": 0
                },
                "rpagesResolved": {
                    "1": 0
                },
                "rpageDurationToResolve": {
                    "1": 0
                },
                "rticketsAssigned": {
                    "1": 0
                },
                "rticketsClosed": {
                    "1": 0
                },
                "rbugsAssigned": {
                    "1": 0
                },
                "rbugsClosed": {
                    "1": 0
                },
                "rticketsTransferred": {
                    "1": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0
                },
                "rdurationBugAssigned": {
                    "1": 0
                }
            }
        },
        {
            "startOfWeek": "2024-01-07",
            "user": {
                "startOfWeek": "2024-01-07",
                "wonCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "wpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "wpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "wticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "wdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wcommits": {
                    "1": 0,
                    "2": 0
                },
                "wadds": {
                    "1": 0,
                    "2": 0
                },
                "wdeletes": {
                    "1": 0,
                    "2": 0
                },
                "wcomments": {
                    "1": 0,
                    "2": 0
                },
                "wpullsOpened": {
                    "1": 0,
                    "2": 0
                },
                "wpullsMerged": {
                    "1": 0,
                    "2": 0
                },
                "wpullsHoursToMerge": {
                    "1": 0,
                    "2": 0
                },
                "rcommits": {
                    "1": 28,
                    "2": 0
                },
                "radds": {
                    "1": 12327,
                    "2": 0
                },
                "rdeletes": {
                    "1": 3716,
                    "2": 0
                },
                "rcomments": {
                    "1": 0,
                    "2": 0
                },
                "rpullsOpened": {
                    "1": 3,
                    "2": 0
                },
                "rpullsMerged": {
                    "1": 3,
                    "2": 0
                },
                "rpullsHoursToMerge": {
                    "1": 53,
                    "2": 0
                },
                "ronCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "rpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "rpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "rticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                }
            },
            "group": {
                "startOfWeek": "2024-01-07",
                "wonCallFlatHours": {
                    "1": 0
                },
                "wpagesAssigned": {
                    "1": 0
                },
                "wpagesResolved": {
                    "1": 0
                },
                "wpageDurationToResolve": {
                    "1": 0
                },
                "wticketsAssigned": {},
                "wticketsClosed": {},
                "wbugsAssigned": {},
                "wbugsClosed": {},
                "wticketsTransferred": {},
                "wdurationTicketAssigned": {},
                "wdurationBugAssigned": {},
                "wcommits": {
                    "1": 0
                },
                "wadds": {
                    "1": 0
                },
                "wdeletes": {
                    "1": 0
                },
                "wcomments": {
                    "1": 0
                },
                "wpullsOpened": {
                    "1": 0
                },
                "wpullsMerged": {
                    "1": 0
                },
                "wpullsHoursToMerge": {
                    "1": 0
                },
                "rcommits": {
                    "1": 14
                },
                "radds": {
                    "1": 6163.5
                },
                "rdeletes": {
                    "1": 1858
                },
                "rcomments": {
                    "1": 0
                },
                "rpullsOpened": {
                    "1": 1.5
                },
                "rpullsMerged": {
                    "1": 1.5
                },
                "rpullsHoursToMerge": {
                    "1": 106
                },
                "ronCallFlatHours": {
                    "1": 0
                },
                "rpagesAssigned": {
                    "1": 0
                },
                "rpagesResolved": {
                    "1": 0
                },
                "rpageDurationToResolve": {
                    "1": 0
                },
                "rticketsAssigned": {
                    "1": 0
                },
                "rticketsClosed": {
                    "1": 0
                },
                "rbugsAssigned": {
                    "1": 0
                },
                "rbugsClosed": {
                    "1": 0
                },
                "rticketsTransferred": {
                    "1": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0
                },
                "rdurationBugAssigned": {
                    "1": 0
                }
            }
        },
        {
            "startOfWeek": "2024-01-14",
            "user": {
                "startOfWeek": "2024-01-14",
                "wonCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "wpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "wpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "wticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "wdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wcommits": {
                    "1": 0,
                    "2": 0
                },
                "wadds": {
                    "1": 0,
                    "2": 0
                },
                "wdeletes": {
                    "1": 0,
                    "2": 0
                },
                "wcomments": {
                    "1": 0,
                    "2": 0
                },
                "wpullsOpened": {
                    "1": 0,
                    "2": 0
                },
                "wpullsMerged": {
                    "1": 0,
                    "2": 0
                },
                "wpullsHoursToMerge": {
                    "1": 0,
                    "2": 0
                },
                "rcommits": {
                    "1": 28,
                    "2": 0
                },
                "radds": {
                    "1": 12327,
                    "2": 0
                },
                "rdeletes": {
                    "1": 3716,
                    "2": 0
                },
                "rcomments": {
                    "1": 0,
                    "2": 0
                },
                "rpullsOpened": {
                    "1": 3,
                    "2": 0
                },
                "rpullsMerged": {
                    "1": 3,
                    "2": 0
                },
                "rpullsHoursToMerge": {
                    "1": 53,
                    "2": 0
                },
                "ronCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "rpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "rpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "rticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                }
            },
            "group": {
                "startOfWeek": "2024-01-14",
                "wonCallFlatHours": {
                    "1": 0
                },
                "wpagesAssigned": {
                    "1": 0
                },
                "wpagesResolved": {
                    "1": 0
                },
                "wpageDurationToResolve": {
                    "1": 0
                },
                "wticketsAssigned": {},
                "wticketsClosed": {},
                "wbugsAssigned": {},
                "wbugsClosed": {},
                "wticketsTransferred": {},
                "wdurationTicketAssigned": {},
                "wdurationBugAssigned": {},
                "wcommits": {
                    "1": 0
                },
                "wadds": {
                    "1": 0
                },
                "wdeletes": {
                    "1": 0
                },
                "wcomments": {
                    "1": 0
                },
                "wpullsOpened": {
                    "1": 0
                },
                "wpullsMerged": {
                    "1": 0
                },
                "wpullsHoursToMerge": {
                    "1": 0
                },
                "rcommits": {
                    "1": 14
                },
                "radds": {
                    "1": 6163.5
                },
                "rdeletes": {
                    "1": 1858
                },
                "rcomments": {
                    "1": 0
                },
                "rpullsOpened": {
                    "1": 1.5
                },
                "rpullsMerged": {
                    "1": 1.5
                },
                "rpullsHoursToMerge": {
                    "1": 106
                },
                "ronCallFlatHours": {
                    "1": 0
                },
                "rpagesAssigned": {
                    "1": 0
                },
                "rpagesResolved": {
                    "1": 0
                },
                "rpageDurationToResolve": {
                    "1": 0
                },
                "rticketsAssigned": {
                    "1": 0
                },
                "rticketsClosed": {
                    "1": 0
                },
                "rbugsAssigned": {
                    "1": 0
                },
                "rbugsClosed": {
                    "1": 0
                },
                "rticketsTransferred": {
                    "1": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0
                },
                "rdurationBugAssigned": {
                    "1": 0
                }
            }
        },
        {
            "startOfWeek": "2024-01-21",
            "user": {
                "startOfWeek": "2024-01-21",
                "wonCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "wpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "wpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "wticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "wdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wcommits": {
                    "1": 2,
                    "2": 0
                },
                "wadds": {
                    "1": 2415,
                    "2": 0
                },
                "wdeletes": {
                    "1": 679,
                    "2": 0
                },
                "wcomments": {
                    "1": 0,
                    "2": 0
                },
                "wpullsOpened": {
                    "1": 0,
                    "2": 0
                },
                "wpullsMerged": {
                    "1": 0,
                    "2": 0
                },
                "wpullsHoursToMerge": {
                    "1": 0,
                    "2": 0
                },
                "rcommits": {
                    "1": 30,
                    "2": 0
                },
                "radds": {
                    "1": 14742,
                    "2": 0
                },
                "rdeletes": {
                    "1": 4395,
                    "2": 0
                },
                "rcomments": {
                    "1": 0,
                    "2": 0
                },
                "rpullsOpened": {
                    "1": 3,
                    "2": 0
                },
                "rpullsMerged": {
                    "1": 3,
                    "2": 0
                },
                "rpullsHoursToMerge": {
                    "1": 53,
                    "2": 0
                },
                "ronCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "rpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "rpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "rticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                }
            },
            "group": {
                "startOfWeek": "2024-01-21",
                "wonCallFlatHours": {
                    "1": 0
                },
                "wpagesAssigned": {
                    "1": 0
                },
                "wpagesResolved": {
                    "1": 0
                },
                "wpageDurationToResolve": {
                    "1": 0
                },
                "wticketsAssigned": {},
                "wticketsClosed": {},
                "wbugsAssigned": {},
                "wbugsClosed": {},
                "wticketsTransferred": {},
                "wdurationTicketAssigned": {},
                "wdurationBugAssigned": {},
                "wcommits": {
                    "1": 1
                },
                "wadds": {
                    "1": 1207.5
                },
                "wdeletes": {
                    "1": 339.5
                },
                "wcomments": {
                    "1": 0
                },
                "wpullsOpened": {
                    "1": 0
                },
                "wpullsMerged": {
                    "1": 0
                },
                "wpullsHoursToMerge": {
                    "1": 0
                },
                "rcommits": {
                    "1": 15
                },
                "radds": {
                    "1": 7371
                },
                "rdeletes": {
                    "1": 2197.5
                },
                "rcomments": {
                    "1": 0
                },
                "rpullsOpened": {
                    "1": 1.5
                },
                "rpullsMerged": {
                    "1": 1.5
                },
                "rpullsHoursToMerge": {
                    "1": 106
                },
                "ronCallFlatHours": {
                    "1": 0
                },
                "rpagesAssigned": {
                    "1": 0
                },
                "rpagesResolved": {
                    "1": 0
                },
                "rpageDurationToResolve": {
                    "1": 0
                },
                "rticketsAssigned": {
                    "1": 0
                },
                "rticketsClosed": {
                    "1": 0
                },
                "rbugsAssigned": {
                    "1": 0
                },
                "rbugsClosed": {
                    "1": 0
                },
                "rticketsTransferred": {
                    "1": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0
                },
                "rdurationBugAssigned": {
                    "1": 0
                }
            }
        },
        {
            "startOfWeek": "2024-01-28",
            "user": {
                "startOfWeek": "2024-01-28",
                "wonCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "wpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "wpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "wticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "wdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wcommits": {
                    "1": 4,
                    "2": 0
                },
                "wadds": {
                    "1": 1759,
                    "2": 0
                },
                "wdeletes": {
                    "1": 750,
                    "2": 0
                },
                "wcomments": {
                    "1": 0,
                    "2": 0
                },
                "wpullsOpened": {
                    "1": 0,
                    "2": 0
                },
                "wpullsMerged": {
                    "1": 0,
                    "2": 0
                },
                "wpullsHoursToMerge": {
                    "1": 0,
                    "2": 0
                },
                "rcommits": {
                    "1": 34,
                    "2": 0
                },
                "radds": {
                    "1": 16501,
                    "2": 0
                },
                "rdeletes": {
                    "1": 5145,
                    "2": 0
                },
                "rcomments": {
                    "1": 0,
                    "2": 0
                },
                "rpullsOpened": {
                    "1": 3,
                    "2": 0
                },
                "rpullsMerged": {
                    "1": 3,
                    "2": 0
                },
                "rpullsHoursToMerge": {
                    "1": 53,
                    "2": 0
                },
                "ronCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "rpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "rpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "rticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                }
            },
            "group": {
                "startOfWeek": "2024-01-28",
                "wonCallFlatHours": {
                    "1": 0
                },
                "wpagesAssigned": {
                    "1": 0
                },
                "wpagesResolved": {
                    "1": 0
                },
                "wpageDurationToResolve": {
                    "1": 0
                },
                "wticketsAssigned": {},
                "wticketsClosed": {},
                "wbugsAssigned": {},
                "wbugsClosed": {},
                "wticketsTransferred": {},
                "wdurationTicketAssigned": {},
                "wdurationBugAssigned": {},
                "wcommits": {
                    "1": 2
                },
                "wadds": {
                    "1": 879.5
                },
                "wdeletes": {
                    "1": 375
                },
                "wcomments": {
                    "1": 0
                },
                "wpullsOpened": {
                    "1": 0
                },
                "wpullsMerged": {
                    "1": 0
                },
                "wpullsHoursToMerge": {
                    "1": 0
                },
                "rcommits": {
                    "1": 17
                },
                "radds": {
                    "1": 8250.5
                },
                "rdeletes": {
                    "1": 2572.5
                },
                "rcomments": {
                    "1": 0
                },
                "rpullsOpened": {
                    "1": 1.5
                },
                "rpullsMerged": {
                    "1": 1.5
                },
                "rpullsHoursToMerge": {
                    "1": 106
                },
                "ronCallFlatHours": {
                    "1": 0
                },
                "rpagesAssigned": {
                    "1": 0
                },
                "rpagesResolved": {
                    "1": 0
                },
                "rpageDurationToResolve": {
                    "1": 0
                },
                "rticketsAssigned": {
                    "1": 0
                },
                "rticketsClosed": {
                    "1": 0
                },
                "rbugsAssigned": {
                    "1": 0
                },
                "rbugsClosed": {
                    "1": 0
                },
                "rticketsTransferred": {
                    "1": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0
                },
                "rdurationBugAssigned": {
                    "1": 0
                }
            }
        },
        {
            "startOfWeek": "2024-02-04",
            "user": {
                "startOfWeek": "2024-02-04",
                "wonCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "wpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "wpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "wticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "wdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wcommits": {
                    "1": 3,
                    "2": 0
                },
                "wadds": {
                    "1": 1540,
                    "2": 0
                },
                "wdeletes": {
                    "1": 703,
                    "2": 0
                },
                "wcomments": {
                    "1": 0,
                    "2": 0
                },
                "wpullsOpened": {
                    "1": 0,
                    "2": 0
                },
                "wpullsMerged": {
                    "1": 0,
                    "2": 0
                },
                "wpullsHoursToMerge": {
                    "1": 0,
                    "2": 0
                },
                "rcommits": {
                    "1": 37,
                    "2": 0
                },
                "radds": {
                    "1": 18041,
                    "2": 0
                },
                "rdeletes": {
                    "1": 5848,
                    "2": 0
                },
                "rcomments": {
                    "1": 0,
                    "2": 0
                },
                "rpullsOpened": {
                    "1": 3,
                    "2": 0
                },
                "rpullsMerged": {
                    "1": 3,
                    "2": 0
                },
                "rpullsHoursToMerge": {
                    "1": 53,
                    "2": 0
                },
                "ronCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "rpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "rpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "rticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                }
            },
            "group": {
                "startOfWeek": "2024-02-04",
                "wonCallFlatHours": {
                    "1": 0
                },
                "wpagesAssigned": {
                    "1": 0
                },
                "wpagesResolved": {
                    "1": 0
                },
                "wpageDurationToResolve": {
                    "1": 0
                },
                "wticketsAssigned": {},
                "wticketsClosed": {},
                "wbugsAssigned": {},
                "wbugsClosed": {},
                "wticketsTransferred": {},
                "wdurationTicketAssigned": {},
                "wdurationBugAssigned": {},
                "wcommits": {
                    "1": 1.5
                },
                "wadds": {
                    "1": 770
                },
                "wdeletes": {
                    "1": 351.5
                },
                "wcomments": {
                    "1": 0
                },
                "wpullsOpened": {
                    "1": 0
                },
                "wpullsMerged": {
                    "1": 0
                },
                "wpullsHoursToMerge": {
                    "1": 0
                },
                "rcommits": {
                    "1": 18.5
                },
                "radds": {
                    "1": 9020.5
                },
                "rdeletes": {
                    "1": 2924
                },
                "rcomments": {
                    "1": 0
                },
                "rpullsOpened": {
                    "1": 1.5
                },
                "rpullsMerged": {
                    "1": 1.5
                },
                "rpullsHoursToMerge": {
                    "1": 106
                },
                "ronCallFlatHours": {
                    "1": 0
                },
                "rpagesAssigned": {
                    "1": 0
                },
                "rpagesResolved": {
                    "1": 0
                },
                "rpageDurationToResolve": {
                    "1": 0
                },
                "rticketsAssigned": {
                    "1": 0
                },
                "rticketsClosed": {
                    "1": 0
                },
                "rbugsAssigned": {
                    "1": 0
                },
                "rbugsClosed": {
                    "1": 0
                },
                "rticketsTransferred": {
                    "1": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0
                },
                "rdurationBugAssigned": {
                    "1": 0
                }
            }
        },
        {
            "startOfWeek": "2024-02-11",
            "user": {
                "startOfWeek": "2024-02-11",
                "wonCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "wpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "wpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "wticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "wdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wcommits": {
                    "1": 3,
                    "2": 0
                },
                "wadds": {
                    "1": 1632,
                    "2": 0
                },
                "wdeletes": {
                    "1": 740,
                    "2": 0
                },
                "wcomments": {
                    "1": 0,
                    "2": 0
                },
                "wpullsOpened": {
                    "1": 2,
                    "2": 0
                },
                "wpullsMerged": {
                    "1": 2,
                    "2": 0
                },
                "wpullsHoursToMerge": {
                    "1": 0,
                    "2": 0
                },
                "rcommits": {
                    "1": 40,
                    "2": 0
                },
                "radds": {
                    "1": 19673,
                    "2": 0
                },
                "rdeletes": {
                    "1": 6588,
                    "2": 0
                },
                "rcomments": {
                    "1": 0,
                    "2": 0
                },
                "rpullsOpened": {
                    "1": 5,
                    "2": 0
                },
                "rpullsMerged": {
                    "1": 5,
                    "2": 0
                },
                "rpullsHoursToMerge": {
                    "1": 53,
                    "2": 0
                },
                "ronCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "rpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "rpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "rticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                }
            },
            "group": {
                "startOfWeek": "2024-02-11",
                "wonCallFlatHours": {
                    "1": 0
                },
                "wpagesAssigned": {
                    "1": 0
                },
                "wpagesResolved": {
                    "1": 0
                },
                "wpageDurationToResolve": {
                    "1": 0
                },
                "wticketsAssigned": {},
                "wticketsClosed": {},
                "wbugsAssigned": {},
                "wbugsClosed": {},
                "wticketsTransferred": {},
                "wdurationTicketAssigned": {},
                "wdurationBugAssigned": {},
                "wcommits": {
                    "1": 1.5
                },
                "wadds": {
                    "1": 816
                },
                "wdeletes": {
                    "1": 370
                },
                "wcomments": {
                    "1": 0
                },
                "wpullsOpened": {
                    "1": 1
                },
                "wpullsMerged": {
                    "1": 1
                },
                "wpullsHoursToMerge": {
                    "1": 0
                },
                "rcommits": {
                    "1": 20
                },
                "radds": {
                    "1": 9836.5
                },
                "rdeletes": {
                    "1": 3294
                },
                "rcomments": {
                    "1": 0
                },
                "rpullsOpened": {
                    "1": 2.5
                },
                "rpullsMerged": {
                    "1": 2.5
                },
                "rpullsHoursToMerge": {
                    "1": 106
                },
                "ronCallFlatHours": {
                    "1": 0
                },
                "rpagesAssigned": {
                    "1": 0
                },
                "rpagesResolved": {
                    "1": 0
                },
                "rpageDurationToResolve": {
                    "1": 0
                },
                "rticketsAssigned": {
                    "1": 0
                },
                "rticketsClosed": {
                    "1": 0
                },
                "rbugsAssigned": {
                    "1": 0
                },
                "rbugsClosed": {
                    "1": 0
                },
                "rticketsTransferred": {
                    "1": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0
                },
                "rdurationBugAssigned": {
                    "1": 0
                }
            }
        },
        {
            "startOfWeek": "2024-02-18",
            "user": {
                "startOfWeek": "2024-02-18",
                "wonCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "wpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "wpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "wticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "wdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wcommits": {
                    "1": 36,
                    "2": 4
                },
                "wadds": {
                    "1": 26466,
                    "2": 1401
                },
                "wdeletes": {
                    "1": 3487,
                    "2": 384
                },
                "wcomments": {
                    "1": 9,
                    "2": 2
                },
                "wpullsOpened": {
                    "1": 14,
                    "2": 2
                },
                "wpullsMerged": {
                    "1": 14,
                    "2": 2
                },
                "wpullsHoursToMerge": {
                    "1": 59,
                    "2": 22
                },
                "rcommits": {
                    "1": 76,
                    "2": 4
                },
                "radds": {
                    "1": 46139,
                    "2": 1401
                },
                "rdeletes": {
                    "1": 10075,
                    "2": 384
                },
                "rcomments": {
                    "1": 9,
                    "2": 2
                },
                "rpullsOpened": {
                    "1": 19,
                    "2": 2
                },
                "rpullsMerged": {
                    "1": 19,
                    "2": 2
                },
                "rpullsHoursToMerge": {
                    "1": 112,
                    "2": 22
                },
                "ronCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "rpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "rpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "rticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                }
            },
            "group": {
                "startOfWeek": "2024-02-18",
                "wonCallFlatHours": {
                    "1": 0
                },
                "wpagesAssigned": {
                    "1": 0
                },
                "wpagesResolved": {
                    "1": 0
                },
                "wpageDurationToResolve": {
                    "1": 0
                },
                "wticketsAssigned": {},
                "wticketsClosed": {},
                "wbugsAssigned": {},
                "wbugsClosed": {},
                "wticketsTransferred": {},
                "wdurationTicketAssigned": {},
                "wdurationBugAssigned": {},
                "wcommits": {
                    "1": 20
                },
                "wadds": {
                    "1": 13933.5
                },
                "wdeletes": {
                    "1": 1935.5
                },
                "wcomments": {
                    "1": 5.5
                },
                "wpullsOpened": {
                    "1": 8
                },
                "wpullsMerged": {
                    "1": 8
                },
                "wpullsHoursToMerge": {
                    "1": 10.13
                },
                "rcommits": {
                    "1": 40
                },
                "radds": {
                    "1": 23770
                },
                "rdeletes": {
                    "1": 5229.5
                },
                "rcomments": {
                    "1": 5.5
                },
                "rpullsOpened": {
                    "1": 10.5
                },
                "rpullsMerged": {
                    "1": 10.5
                },
                "rpullsHoursToMerge": {
                    "1": 116.13
                },
                "ronCallFlatHours": {
                    "1": 0
                },
                "rpagesAssigned": {
                    "1": 0
                },
                "rpagesResolved": {
                    "1": 0
                },
                "rpageDurationToResolve": {
                    "1": 0
                },
                "rticketsAssigned": {
                    "1": 0
                },
                "rticketsClosed": {
                    "1": 0
                },
                "rbugsAssigned": {
                    "1": 0
                },
                "rbugsClosed": {
                    "1": 0
                },
                "rticketsTransferred": {
                    "1": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0
                },
                "rdurationBugAssigned": {
                    "1": 0
                }
            }
        },
        {
            "startOfWeek": "2024-02-25",
            "user": {
                "startOfWeek": "2024-02-25",
                "wonCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "wpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "wpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "wticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "wdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wcommits": {
                    "1": 19,
                    "2": 0
                },
                "wadds": {
                    "1": 5416,
                    "2": 0
                },
                "wdeletes": {
                    "1": 42259,
                    "2": 0
                },
                "wcomments": {
                    "1": 47,
                    "2": 0
                },
                "wpullsOpened": {
                    "1": 7,
                    "2": 1
                },
                "wpullsMerged": {
                    "1": 7,
                    "2": 1
                },
                "wpullsHoursToMerge": {
                    "1": 16,
                    "2": 26
                },
                "rcommits": {
                    "1": 95,
                    "2": 4
                },
                "radds": {
                    "1": 51555,
                    "2": 1401
                },
                "rdeletes": {
                    "1": 52334,
                    "2": 384
                },
                "rcomments": {
                    "1": 56,
                    "2": 2
                },
                "rpullsOpened": {
                    "1": 26,
                    "2": 3
                },
                "rpullsMerged": {
                    "1": 26,
                    "2": 3
                },
                "rpullsHoursToMerge": {
                    "1": 128,
                    "2": 48
                },
                "ronCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "rpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "rpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "rticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                }
            },
            "group": {
                "startOfWeek": "2024-02-25",
                "wonCallFlatHours": {
                    "1": 0
                },
                "wpagesAssigned": {
                    "1": 0
                },
                "wpagesResolved": {
                    "1": 0
                },
                "wpageDurationToResolve": {
                    "1": 0
                },
                "wticketsAssigned": {},
                "wticketsClosed": {},
                "wbugsAssigned": {},
                "wbugsClosed": {},
                "wticketsTransferred": {},
                "wdurationTicketAssigned": {},
                "wdurationBugAssigned": {},
                "wcommits": {
                    "1": 9.5
                },
                "wadds": {
                    "1": 2708
                },
                "wdeletes": {
                    "1": 21129.5
                },
                "wcomments": {
                    "1": 23.5
                },
                "wpullsOpened": {
                    "1": 4
                },
                "wpullsMerged": {
                    "1": 4
                },
                "wpullsHoursToMerge": {
                    "1": 10.5
                },
                "rcommits": {
                    "1": 49.5
                },
                "radds": {
                    "1": 26478
                },
                "rdeletes": {
                    "1": 26359
                },
                "rcomments": {
                    "1": 29
                },
                "rpullsOpened": {
                    "1": 14.5
                },
                "rpullsMerged": {
                    "1": 14.5
                },
                "rpullsHoursToMerge": {
                    "1": 126.63
                },
                "ronCallFlatHours": {
                    "1": 0
                },
                "rpagesAssigned": {
                    "1": 0
                },
                "rpagesResolved": {
                    "1": 0
                },
                "rpageDurationToResolve": {
                    "1": 0
                },
                "rticketsAssigned": {
                    "1": 0
                },
                "rticketsClosed": {
                    "1": 0
                },
                "rbugsAssigned": {
                    "1": 0
                },
                "rbugsClosed": {
                    "1": 0
                },
                "rticketsTransferred": {
                    "1": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0
                },
                "rdurationBugAssigned": {
                    "1": 0
                }
            }
        },
        {
            "startOfWeek": "2024-03-03",
            "user": {
                "startOfWeek": "2024-03-03",
                "wonCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "wpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "wpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "wticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "wdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wcommits": {
                    "1": 31,
                    "2": 12
                },
                "wadds": {
                    "1": 2917,
                    "2": 1429
                },
                "wdeletes": {
                    "1": 1398,
                    "2": 404
                },
                "wcomments": {
                    "1": 18,
                    "2": 13
                },
                "wpullsOpened": {
                    "1": 12,
                    "2": 4
                },
                "wpullsMerged": {
                    "1": 12,
                    "2": 4
                },
                "wpullsHoursToMerge": {
                    "1": 26,
                    "2": 48
                },
                "rcommits": {
                    "1": 126,
                    "2": 16
                },
                "radds": {
                    "1": 54472,
                    "2": 2830
                },
                "rdeletes": {
                    "1": 53732,
                    "2": 788
                },
                "rcomments": {
                    "1": 74,
                    "2": 15
                },
                "rpullsOpened": {
                    "1": 38,
                    "2": 7
                },
                "rpullsMerged": {
                    "1": 38,
                    "2": 7
                },
                "rpullsHoursToMerge": {
                    "1": 154,
                    "2": 96
                },
                "ronCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "rpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "rpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "rticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                }
            },
            "group": {
                "startOfWeek": "2024-03-03",
                "wonCallFlatHours": {
                    "1": 0
                },
                "wpagesAssigned": {
                    "1": 0
                },
                "wpagesResolved": {
                    "1": 0
                },
                "wpageDurationToResolve": {
                    "1": 0
                },
                "wticketsAssigned": {},
                "wticketsClosed": {},
                "wbugsAssigned": {},
                "wbugsClosed": {},
                "wticketsTransferred": {},
                "wdurationTicketAssigned": {},
                "wdurationBugAssigned": {},
                "wcommits": {
                    "1": 21.5
                },
                "wadds": {
                    "1": 2173
                },
                "wdeletes": {
                    "1": 901
                },
                "wcomments": {
                    "1": 15.5
                },
                "wpullsOpened": {
                    "1": 8
                },
                "wpullsMerged": {
                    "1": 8
                },
                "wpullsHoursToMerge": {
                    "1": 9.25
                },
                "rcommits": {
                    "1": 71
                },
                "radds": {
                    "1": 28651
                },
                "rdeletes": {
                    "1": 27260
                },
                "rcomments": {
                    "1": 44.5
                },
                "rpullsOpened": {
                    "1": 22.5
                },
                "rpullsMerged": {
                    "1": 22.5
                },
                "rpullsHoursToMerge": {
                    "1": 135.88
                },
                "ronCallFlatHours": {
                    "1": 0
                },
                "rpagesAssigned": {
                    "1": 0
                },
                "rpagesResolved": {
                    "1": 0
                },
                "rpageDurationToResolve": {
                    "1": 0
                },
                "rticketsAssigned": {
                    "1": 0
                },
                "rticketsClosed": {
                    "1": 0
                },
                "rbugsAssigned": {
                    "1": 0
                },
                "rbugsClosed": {
                    "1": 0
                },
                "rticketsTransferred": {
                    "1": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0
                },
                "rdurationBugAssigned": {
                    "1": 0
                }
            }
        },
        {
            "startOfWeek": "2024-03-10",
            "user": {
                "startOfWeek": "2024-03-10",
                "wonCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "wpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "wpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "wticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "wdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wcommits": {
                    "1": 17,
                    "2": 0
                },
                "wadds": {
                    "1": 1652,
                    "2": 0
                },
                "wdeletes": {
                    "1": 1486,
                    "2": 0
                },
                "wcomments": {
                    "1": 8,
                    "2": 3
                },
                "wpullsOpened": {
                    "1": 7,
                    "2": 0
                },
                "wpullsMerged": {
                    "1": 7,
                    "2": 0
                },
                "wpullsHoursToMerge": {
                    "1": 20,
                    "2": 0
                },
                "rcommits": {
                    "1": 143,
                    "2": 16
                },
                "radds": {
                    "1": 56124,
                    "2": 2830
                },
                "rdeletes": {
                    "1": 55218,
                    "2": 788
                },
                "rcomments": {
                    "1": 82,
                    "2": 18
                },
                "rpullsOpened": {
                    "1": 45,
                    "2": 7
                },
                "rpullsMerged": {
                    "1": 45,
                    "2": 7
                },
                "rpullsHoursToMerge": {
                    "1": 174,
                    "2": 96
                },
                "ronCallFlatHours": {
                    "1": 0,
                    "2": 0
                },
                "rpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "rpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "rticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                }
            },
            "group": {
                "startOfWeek": "2024-03-10",
                "wonCallFlatHours": {
                    "1": 0
                },
                "wpagesAssigned": {
                    "1": 0
                },
                "wpagesResolved": {
                    "1": 0
                },
                "wpageDurationToResolve": {
                    "1": 0
                },
                "wticketsAssigned": {},
                "wticketsClosed": {},
                "wbugsAssigned": {},
                "wbugsClosed": {},
                "wticketsTransferred": {},
                "wdurationTicketAssigned": {},
                "wdurationBugAssigned": {},
                "wcommits": {
                    "1": 8.5
                },
                "wadds": {
                    "1": 826
                },
                "wdeletes": {
                    "1": 743
                },
                "wcomments": {
                    "1": 5.5
                },
                "wpullsOpened": {
                    "1": 3.5
                },
                "wpullsMerged": {
                    "1": 3.5
                },
                "wpullsHoursToMerge": {
                    "1": 5.71
                },
                "rcommits": {
                    "1": 79.5
                },
                "radds": {
                    "1": 29477
                },
                "rdeletes": {
                    "1": 28003
                },
                "rcomments": {
                    "1": 50
                },
                "rpullsOpened": {
                    "1": 26
                },
                "rpullsMerged": {
                    "1": 26
                },
                "rpullsHoursToMerge": {
                    "1": 141.59
                },
                "ronCallFlatHours": {
                    "1": 0
                },
                "rpagesAssigned": {
                    "1": 0
                },
                "rpagesResolved": {
                    "1": 0
                },
                "rpageDurationToResolve": {
                    "1": 0
                },
                "rticketsAssigned": {
                    "1": 0
                },
                "rticketsClosed": {
                    "1": 0
                },
                "rbugsAssigned": {
                    "1": 0
                },
                "rbugsClosed": {
                    "1": 0
                },
                "rticketsTransferred": {
                    "1": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0
                },
                "rdurationBugAssigned": {
                    "1": 0
                }
            }
        },
        {
            "startOfWeek": "2024-03-17",
            "user": {
                "startOfWeek": "2024-03-17",
                "wonCallFlatHours": {
                    "1": 32.19,
                    "2": 0.03
                },
                "wpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "wpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "wticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "wdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wcommits": {
                    "1": 13,
                    "2": 0
                },
                "wadds": {
                    "1": 2178,
                    "2": 0
                },
                "wdeletes": {
                    "1": 652,
                    "2": 0
                },
                "wcomments": {
                    "1": 10,
                    "2": 2
                },
                "wpullsOpened": {
                    "1": 5,
                    "2": 0
                },
                "wpullsMerged": {
                    "1": 5,
                    "2": 0
                },
                "wpullsHoursToMerge": {
                    "1": 0,
                    "2": 0
                },
                "rcommits": {
                    "1": 156,
                    "2": 16
                },
                "radds": {
                    "1": 58302,
                    "2": 2830
                },
                "rdeletes": {
                    "1": 55870,
                    "2": 788
                },
                "rcomments": {
                    "1": 92,
                    "2": 20
                },
                "rpullsOpened": {
                    "1": 50,
                    "2": 7
                },
                "rpullsMerged": {
                    "1": 50,
                    "2": 7
                },
                "rpullsHoursToMerge": {
                    "1": 174,
                    "2": 96
                },
                "ronCallFlatHours": {
                    "1": 32.19,
                    "2": 0.03
                },
                "rpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "rpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "rticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                }
            },
            "group": {
                "startOfWeek": "2024-03-17",
                "wonCallFlatHours": {
                    "1": 16
                },
                "wpagesAssigned": {
                    "1": 0
                },
                "wpagesResolved": {
                    "1": 0
                },
                "wpageDurationToResolve": {
                    "1": 0
                },
                "wticketsAssigned": {},
                "wticketsClosed": {},
                "wbugsAssigned": {},
                "wbugsClosed": {},
                "wticketsTransferred": {},
                "wdurationTicketAssigned": {},
                "wdurationBugAssigned": {},
                "wcommits": {
                    "1": 6.5
                },
                "wadds": {
                    "1": 1089
                },
                "wdeletes": {
                    "1": 326
                },
                "wcomments": {
                    "1": 6
                },
                "wpullsOpened": {
                    "1": 2.5
                },
                "wpullsMerged": {
                    "1": 2.5
                },
                "wpullsHoursToMerge": {
                    "1": 0
                },
                "rcommits": {
                    "1": 86
                },
                "radds": {
                    "1": 30566
                },
                "rdeletes": {
                    "1": 28329
                },
                "rcomments": {
                    "1": 56
                },
                "rpullsOpened": {
                    "1": 28.5
                },
                "rpullsMerged": {
                    "1": 28.5
                },
                "rpullsHoursToMerge": {
                    "1": 141.59
                },
                "ronCallFlatHours": {
                    "1": 16
                },
                "rpagesAssigned": {
                    "1": 0
                },
                "rpagesResolved": {
                    "1": 0
                },
                "rpageDurationToResolve": {
                    "1": 0
                },
                "rticketsAssigned": {
                    "1": 0
                },
                "rticketsClosed": {
                    "1": 0
                },
                "rbugsAssigned": {
                    "1": 0
                },
                "rbugsClosed": {
                    "1": 0
                },
                "rticketsTransferred": {
                    "1": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0
                },
                "rdurationBugAssigned": {
                    "1": 0
                }
            }
        },
        {
            "startOfWeek": "2024-03-24",
            "user": {
                "startOfWeek": "2024-03-24",
                "wonCallFlatHours": {
                    "1": 168,
                    "2": 24
                },
                "wpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "wpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "wticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "wdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wcommits": {
                    "1": 0,
                    "2": 3
                },
                "wadds": {
                    "1": 0,
                    "2": 1091
                },
                "wdeletes": {
                    "1": 0,
                    "2": 753
                },
                "wcomments": {
                    "1": 0,
                    "2": 7
                },
                "wpullsOpened": {
                    "1": 2,
                    "2": 2
                },
                "wpullsMerged": {
                    "1": 0,
                    "2": 2
                },
                "wpullsHoursToMerge": {
                    "1": 0,
                    "2": 117
                },
                "rcommits": {
                    "1": 156,
                    "2": 19
                },
                "radds": {
                    "1": 58302,
                    "2": 3921
                },
                "rdeletes": {
                    "1": 55870,
                    "2": 1541
                },
                "rcomments": {
                    "1": 92,
                    "2": 27
                },
                "rpullsOpened": {
                    "1": 52,
                    "2": 9
                },
                "rpullsMerged": {
                    "1": 50,
                    "2": 9
                },
                "rpullsHoursToMerge": {
                    "1": 174,
                    "2": 213
                },
                "ronCallFlatHours": {
                    "1": 200.19,
                    "2": 24.03
                },
                "rpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "rpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "rticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                }
            },
            "group": {
                "startOfWeek": "2024-03-24",
                "wonCallFlatHours": {
                    "1": 96
                },
                "wpagesAssigned": {
                    "1": 0
                },
                "wpagesResolved": {
                    "1": 0
                },
                "wpageDurationToResolve": {
                    "1": 0
                },
                "wticketsAssigned": {},
                "wticketsClosed": {},
                "wbugsAssigned": {},
                "wbugsClosed": {},
                "wticketsTransferred": {},
                "wdurationTicketAssigned": {},
                "wdurationBugAssigned": {},
                "wcommits": {
                    "1": 1.5
                },
                "wadds": {
                    "1": 545.5
                },
                "wdeletes": {
                    "1": 376.5
                },
                "wcomments": {
                    "1": 3.5
                },
                "wpullsOpened": {
                    "1": 2
                },
                "wpullsMerged": {
                    "1": 1
                },
                "wpullsHoursToMerge": {
                    "1": 117
                },
                "rcommits": {
                    "1": 87.5
                },
                "radds": {
                    "1": 31111.5
                },
                "rdeletes": {
                    "1": 28705.5
                },
                "rcomments": {
                    "1": 59.5
                },
                "rpullsOpened": {
                    "1": 30.5
                },
                "rpullsMerged": {
                    "1": 29.5
                },
                "rpullsHoursToMerge": {
                    "1": 258.59
                },
                "ronCallFlatHours": {
                    "1": 112
                },
                "rpagesAssigned": {
                    "1": 0
                },
                "rpagesResolved": {
                    "1": 0
                },
                "rpageDurationToResolve": {
                    "1": 0
                },
                "rticketsAssigned": {
                    "1": 0
                },
                "rticketsClosed": {
                    "1": 0
                },
                "rbugsAssigned": {
                    "1": 0
                },
                "rbugsClosed": {
                    "1": 0
                },
                "rticketsTransferred": {
                    "1": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0
                },
                "rdurationBugAssigned": {
                    "1": 0
                }
            }
        },
        {
            "startOfWeek": "2024-03-31",
            "user": {
                "startOfWeek": "2024-03-31",
                "wonCallFlatHours": {
                    "1": 168,
                    "2": 0
                },
                "wpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "wpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "wticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "wdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wcommits": {
                    "1": 14,
                    "2": 1
                },
                "wadds": {
                    "1": 228,
                    "2": 35
                },
                "wdeletes": {
                    "1": 172,
                    "2": 15
                },
                "wcomments": {
                    "1": 11,
                    "2": 9
                },
                "wpullsOpened": {
                    "1": 14,
                    "2": 2
                },
                "wpullsMerged": {
                    "1": 14,
                    "2": 2
                },
                "wpullsHoursToMerge": {
                    "1": 0,
                    "2": 18
                },
                "rcommits": {
                    "1": 170,
                    "2": 20
                },
                "radds": {
                    "1": 58530,
                    "2": 3956
                },
                "rdeletes": {
                    "1": 56042,
                    "2": 1556
                },
                "rcomments": {
                    "1": 103,
                    "2": 36
                },
                "rpullsOpened": {
                    "1": 66,
                    "2": 11
                },
                "rpullsMerged": {
                    "1": 64,
                    "2": 11
                },
                "rpullsHoursToMerge": {
                    "1": 174,
                    "2": 231
                },
                "ronCallFlatHours": {
                    "1": 368.19,
                    "2": 24.03
                },
                "rpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "rpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "rticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                }
            },
            "group": {
                "startOfWeek": "2024-03-31",
                "wonCallFlatHours": {
                    "1": 84
                },
                "wpagesAssigned": {
                    "1": 0
                },
                "wpagesResolved": {
                    "1": 0
                },
                "wpageDurationToResolve": {
                    "1": 0
                },
                "wticketsAssigned": {},
                "wticketsClosed": {},
                "wbugsAssigned": {},
                "wbugsClosed": {},
                "wticketsTransferred": {},
                "wdurationTicketAssigned": {},
                "wdurationBugAssigned": {},
                "wcommits": {
                    "1": 7.5
                },
                "wadds": {
                    "1": 131.5
                },
                "wdeletes": {
                    "1": 93.5
                },
                "wcomments": {
                    "1": 10
                },
                "wpullsOpened": {
                    "1": 8
                },
                "wpullsMerged": {
                    "1": 8
                },
                "wpullsHoursToMerge": {
                    "1": 2.25
                },
                "rcommits": {
                    "1": 95
                },
                "radds": {
                    "1": 31243
                },
                "rdeletes": {
                    "1": 28799
                },
                "rcomments": {
                    "1": 69.5
                },
                "rpullsOpened": {
                    "1": 38.5
                },
                "rpullsMerged": {
                    "1": 37.5
                },
                "rpullsHoursToMerge": {
                    "1": 260.84
                },
                "ronCallFlatHours": {
                    "1": 196
                },
                "rpagesAssigned": {
                    "1": 0
                },
                "rpagesResolved": {
                    "1": 0
                },
                "rpageDurationToResolve": {
                    "1": 0
                },
                "rticketsAssigned": {
                    "1": 0
                },
                "rticketsClosed": {
                    "1": 0
                },
                "rbugsAssigned": {
                    "1": 0
                },
                "rbugsClosed": {
                    "1": 0
                },
                "rticketsTransferred": {
                    "1": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0
                },
                "rdurationBugAssigned": {
                    "1": 0
                }
            }
        },
        {
            "startOfWeek": "2024-04-07",
            "user": {
                "startOfWeek": "2024-04-07",
                "wonCallFlatHours": {
                    "1": 168,
                    "2": 0
                },
                "wpagesAssigned": {
                    "1": 1,
                    "2": 0
                },
                "wpagesResolved": {
                    "1": 1,
                    "2": 0
                },
                "wpageDurationToResolve": {
                    "1": 59.78,
                    "2": 0
                },
                "wticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "wdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wcommits": {
                    "1": 30,
                    "2": 16
                },
                "wadds": {
                    "1": 3118,
                    "2": 2149
                },
                "wdeletes": {
                    "1": 2482,
                    "2": 1095
                },
                "wcomments": {
                    "1": 21,
                    "2": 16
                },
                "wpullsOpened": {
                    "1": 10,
                    "2": 8
                },
                "wpullsMerged": {
                    "1": 10,
                    "2": 8
                },
                "wpullsHoursToMerge": {
                    "1": 115,
                    "2": 46
                },
                "rcommits": {
                    "1": 200,
                    "2": 36
                },
                "radds": {
                    "1": 61648,
                    "2": 6105
                },
                "rdeletes": {
                    "1": 58524,
                    "2": 2651
                },
                "rcomments": {
                    "1": 124,
                    "2": 52
                },
                "rpullsOpened": {
                    "1": 76,
                    "2": 19
                },
                "rpullsMerged": {
                    "1": 74,
                    "2": 19
                },
                "rpullsHoursToMerge": {
                    "1": 289,
                    "2": 277
                },
                "ronCallFlatHours": {
                    "1": 536.19,
                    "2": 24.03
                },
                "rpagesAssigned": {
                    "1": 1,
                    "2": 0
                },
                "rpagesResolved": {
                    "1": 1,
                    "2": 0
                },
                "rpageDurationToResolve": {
                    "1": 1,
                    "2": 0
                },
                "rticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                }
            },
            "group": {
                "startOfWeek": "2024-04-07",
                "wonCallFlatHours": {
                    "1": 84
                },
                "wpagesAssigned": {
                    "1": 0
                },
                "wpagesResolved": {
                    "1": 0
                },
                "wpageDurationToResolve": {
                    "1": 0
                },
                "wticketsAssigned": {},
                "wticketsClosed": {},
                "wbugsAssigned": {},
                "wbugsClosed": {},
                "wticketsTransferred": {},
                "wdurationTicketAssigned": {},
                "wdurationBugAssigned": {},
                "wcommits": {
                    "1": 23
                },
                "wadds": {
                    "1": 2633.5
                },
                "wdeletes": {
                    "1": 1788.5
                },
                "wcomments": {
                    "1": 18.5
                },
                "wpullsOpened": {
                    "1": 9
                },
                "wpullsMerged": {
                    "1": 9
                },
                "wpullsHoursToMerge": {
                    "1": 17.89
                },
                "rcommits": {
                    "1": 118
                },
                "radds": {
                    "1": 33876.5
                },
                "rdeletes": {
                    "1": 30587.5
                },
                "rcomments": {
                    "1": 88
                },
                "rpullsOpened": {
                    "1": 47.5
                },
                "rpullsMerged": {
                    "1": 46.5
                },
                "rpullsHoursToMerge": {
                    "1": 278.73
                },
                "ronCallFlatHours": {
                    "1": 280
                },
                "rpagesAssigned": {
                    "1": 0
                },
                "rpagesResolved": {
                    "1": 0
                },
                "rpageDurationToResolve": {
                    "1": 0
                },
                "rticketsAssigned": {
                    "1": 0
                },
                "rticketsClosed": {
                    "1": 0
                },
                "rbugsAssigned": {
                    "1": 0
                },
                "rbugsClosed": {
                    "1": 0
                },
                "rticketsTransferred": {
                    "1": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0
                },
                "rdurationBugAssigned": {
                    "1": 0
                }
            }
        },
        {
            "startOfWeek": "2024-04-14",
            "user": {
                "startOfWeek": "2024-04-14",
                "wonCallFlatHours": {
                    "1": 168,
                    "2": 146
                },
                "wpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "wpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "wticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "wdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wcommits": {
                    "1": 9,
                    "2": 0
                },
                "wadds": {
                    "1": 12391,
                    "2": 0
                },
                "wdeletes": {
                    "1": 4083,
                    "2": 0
                },
                "wcomments": {
                    "1": 0,
                    "2": 0
                },
                "wpullsOpened": {
                    "1": 5,
                    "2": 0
                },
                "wpullsMerged": {
                    "1": 5,
                    "2": 0
                },
                "wpullsHoursToMerge": {
                    "1": 0,
                    "2": 0
                },
                "rcommits": {
                    "1": 209,
                    "2": 36
                },
                "radds": {
                    "1": 74039,
                    "2": 6105
                },
                "rdeletes": {
                    "1": 62607,
                    "2": 2651
                },
                "rcomments": {
                    "1": 124,
                    "2": 52
                },
                "rpullsOpened": {
                    "1": 81,
                    "2": 19
                },
                "rpullsMerged": {
                    "1": 79,
                    "2": 19
                },
                "rpullsHoursToMerge": {
                    "1": 289,
                    "2": 277
                },
                "ronCallFlatHours": {
                    "1": 704.19,
                    "2": 170.03
                },
                "rpagesAssigned": {
                    "1": 1,
                    "2": 0
                },
                "rpagesResolved": {
                    "1": 1,
                    "2": 0
                },
                "rpageDurationToResolve": {
                    "1": 1,
                    "2": 0
                },
                "rticketsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rbugsAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rbugsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rticketsTransferred": {
                    "1": 0,
                    "2": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rdurationBugAssigned": {
                    "1": 0,
                    "2": 0
                }
            },
            "group": {
                "startOfWeek": "2024-04-14",
                "wonCallFlatHours": {
                    "1": 157
                },
                "wpagesAssigned": {
                    "1": 0
                },
                "wpagesResolved": {
                    "1": 0
                },
                "wpageDurationToResolve": {
                    "1": 0
                },
                "wticketsAssigned": {},
                "wticketsClosed": {},
                "wbugsAssigned": {},
                "wbugsClosed": {},
                "wticketsTransferred": {},
                "wdurationTicketAssigned": {},
                "wdurationBugAssigned": {},
                "wcommits": {
                    "1": 4.5
                },
                "wadds": {
                    "1": 6195.5
                },
                "wdeletes": {
                    "1": 2041.5
                },
                "wcomments": {
                    "1": 0
                },
                "wpullsOpened": {
                    "1": 2.5
                },
                "wpullsMerged": {
                    "1": 2.5
                },
                "wpullsHoursToMerge": {
                    "1": 0
                },
                "rcommits": {
                    "1": 122.5
                },
                "radds": {
                    "1": 40072
                },
                "rdeletes": {
                    "1": 32629
                },
                "rcomments": {
                    "1": 88
                },
                "rpullsOpened": {
                    "1": 50
                },
                "rpullsMerged": {
                    "1": 49
                },
                "rpullsHoursToMerge": {
                    "1": 278.73
                },
                "ronCallFlatHours": {
                    "1": 437
                },
                "rpagesAssigned": {
                    "1": 0
                },
                "rpagesResolved": {
                    "1": 0
                },
                "rpageDurationToResolve": {
                    "1": 0
                },
                "rticketsAssigned": {
                    "1": 0
                },
                "rticketsClosed": {
                    "1": 0
                },
                "rbugsAssigned": {
                    "1": 0
                },
                "rbugsClosed": {
                    "1": 0
                },
                "rticketsTransferred": {
                    "1": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0
                },
                "rdurationBugAssigned": {
                    "1": 0
                }
            }
        },
        {
            "startOfWeek": "2024-04-21",
            "user": {
                "startOfWeek": "2024-04-21",
                "wonCallFlatHours": {
                    "1": 168,
                    "2": 22
                },
                "wpagesAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wpagesResolved": {
                    "1": 0,
                    "2": 0
                },
                "wpageDurationToResolve": {
                    "1": 0,
                    "2": 0
                },
                "wticketsAssigned": {
                    "1": 1,
                    "2": 0
                },
                "wticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "wbugsAssigned": {
                    "1": 2,
                    "2": 0
                },
                "wbugsClosed": {
                    "1": 2,
                    "2": 0
                },
                "wticketsTransferred": {
                    "1": 1,
                    "2": 0
                },
                "wdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "wdurationBugAssigned": {
                    "1": 0.13,
                    "2": 0
                },
                "wcommits": {
                    "1": 0,
                    "2": 0
                },
                "wadds": {
                    "1": 0,
                    "2": 0
                },
                "wdeletes": {
                    "1": 0,
                    "2": 0
                },
                "wcomments": {
                    "1": 0,
                    "2": 0
                },
                "wpullsOpened": {
                    "1": 1,
                    "2": 0
                },
                "wpullsMerged": {
                    "1": 0,
                    "2": 0
                },
                "wpullsHoursToMerge": {
                    "1": 0,
                    "2": 0
                },
                "rcommits": {
                    "1": 209,
                    "2": 36
                },
                "radds": {
                    "1": 74039,
                    "2": 6105
                },
                "rdeletes": {
                    "1": 62607,
                    "2": 2651
                },
                "rcomments": {
                    "1": 124,
                    "2": 52
                },
                "rpullsOpened": {
                    "1": 82,
                    "2": 19
                },
                "rpullsMerged": {
                    "1": 79,
                    "2": 19
                },
                "rpullsHoursToMerge": {
                    "1": 289,
                    "2": 277
                },
                "ronCallFlatHours": {
                    "1": 872.19,
                    "2": 192.03
                },
                "rpagesAssigned": {
                    "1": 1,
                    "2": 0
                },
                "rpagesResolved": {
                    "1": 1,
                    "2": 0
                },
                "rpageDurationToResolve": {
                    "1": 1,
                    "2": 0
                },
                "rticketsAssigned": {
                    "1": 1,
                    "2": 0
                },
                "rticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rbugsAssigned": {
                    "1": 2,
                    "2": 0
                },
                "rbugsClosed": {
                    "1": 2,
                    "2": 0
                },
                "rticketsTransferred": {
                    "1": 1,
                    "2": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rdurationBugAssigned": {
                    "1": 0.13,
                    "2": 0
                }
            },
            "group": {
                "startOfWeek": "2024-04-21",
                "wonCallFlatHours": {
                    "1": 95
                },
                "wpagesAssigned": {
                    "1": 0
                },
                "wpagesResolved": {
                    "1": 0
                },
                "wpageDurationToResolve": {
                    "1": 0
                },
                "wticketsAssigned": {},
                "wticketsClosed": {},
                "wbugsAssigned": {},
                "wbugsClosed": {},
                "wticketsTransferred": {},
                "wdurationTicketAssigned": {},
                "wdurationBugAssigned": {},
                "wcommits": {
                    "1": 0
                },
                "wadds": {
                    "1": 0
                },
                "wdeletes": {
                    "1": 0
                },
                "wcomments": {
                    "1": 0
                },
                "wpullsOpened": {
                    "1": 0.5
                },
                "wpullsMerged": {
                    "1": 0
                },
                "wpullsHoursToMerge": {
                    "1": 0
                },
                "rcommits": {
                    "1": 122.5
                },
                "radds": {
                    "1": 40072
                },
                "rdeletes": {
                    "1": 32629
                },
                "rcomments": {
                    "1": 88
                },
                "rpullsOpened": {
                    "1": 50.5
                },
                "rpullsMerged": {
                    "1": 49
                },
                "rpullsHoursToMerge": {
                    "1": 278.73
                },
                "ronCallFlatHours": {
                    "1": 532
                },
                "rpagesAssigned": {
                    "1": 0
                },
                "rpagesResolved": {
                    "1": 0
                },
                "rpageDurationToResolve": {
                    "1": 0
                },
                "rticketsAssigned": {
                    "1": 0
                },
                "rticketsClosed": {
                    "1": 0
                },
                "rbugsAssigned": {
                    "1": 0
                },
                "rbugsClosed": {
                    "1": 0
                },
                "rticketsTransferred": {
                    "1": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0
                },
                "rdurationBugAssigned": {
                    "1": 0
                }
            }
        },
        {
            "startOfWeek": "2024-04-28",
            "user": {
                "startOfWeek": "2024-04-28",
                "wonCallFlatHours": {},
                "wpagesAssigned": {},
                "wpagesResolved": {},
                "wpageDurationToResolve": {},
                "wticketsAssigned": {},
                "wticketsClosed": {},
                "wbugsAssigned": {},
                "wbugsClosed": {},
                "wticketsTransferred": {},
                "wdurationTicketAssigned": {},
                "wdurationBugAssigned": {},
                "wcommits": {
                    "1": 8,
                    "2": 0
                },
                "wadds": {
                    "1": 705,
                    "2": 0
                },
                "wdeletes": {
                    "1": 201,
                    "2": 0
                },
                "wcomments": {
                    "1": 1,
                    "2": 0
                },
                "wpullsOpened": {
                    "1": 8,
                    "2": 0
                },
                "wpullsMerged": {
                    "1": 8,
                    "2": 0
                },
                "wpullsHoursToMerge": {
                    "1": 0,
                    "2": 0
                },
                "rcommits": {
                    "1": 217,
                    "2": 36
                },
                "radds": {
                    "1": 74744,
                    "2": 6105
                },
                "rdeletes": {
                    "1": 62808,
                    "2": 2651
                },
                "rcomments": {
                    "1": 125,
                    "2": 52
                },
                "rpullsOpened": {
                    "1": 90,
                    "2": 19
                },
                "rpullsMerged": {
                    "1": 87,
                    "2": 19
                },
                "rpullsHoursToMerge": {
                    "1": 289,
                    "2": 277
                },
                "ronCallFlatHours": {
                    "1": 872.19,
                    "2": 192.03
                },
                "rpagesAssigned": {
                    "1": 1,
                    "2": 0
                },
                "rpagesResolved": {
                    "1": 1,
                    "2": 0
                },
                "rpageDurationToResolve": {
                    "1": 1,
                    "2": 0
                },
                "rticketsAssigned": {
                    "1": 1,
                    "2": 0
                },
                "rticketsClosed": {
                    "1": 0,
                    "2": 0
                },
                "rbugsAssigned": {
                    "1": 2,
                    "2": 0
                },
                "rbugsClosed": {
                    "1": 2,
                    "2": 0
                },
                "rticketsTransferred": {
                    "1": 1,
                    "2": 0
                },
                "rdurationTicketAssigned": {
                    "1": 0,
                    "2": 0
                },
                "rdurationBugAssigned": {
                    "1": 0.13,
                    "2": 0
                }
            }
        }
    ]
}