import React, { useState } from "react";
import { useApi } from "../ApiProvider";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import styled from "@emotion/styled";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const InviteUserModal = ({ open, handleClose }) => {
  const [email, setEmail] = useState("");
  const [file, setFile] = useState(null);
  const [nameOverride, setNameOverride] = useState("");
  const [shouldAccessDevLanding, setShouldAccessDevLanding] = useState(false);
  const { inviteUser, uploadUserCsv } = useApi();

  const queryClient = useQueryClient();
  const inviteUserMutation = useMutation({
    mutationFn: () => inviteUser(email, nameOverride, shouldAccessDevLanding),
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ["scopes"] });
      handleClose();
    },
  });

  const uploadUserCsvMutation = useMutation({
    mutationFn: () => uploadUserCsv(file, shouldAccessDevLanding),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["scopes"] });
      setFile(null);
      handleClose();
    },
  });

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Add User</DialogTitle>
      <DialogContent>
        <FormGroup>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <TextField
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                label="Email"
                autoFocus
                required
                margin="dense"
                type="email"
                fullWidth
                disabled={file !== null}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                value={nameOverride}
                onChange={(event) => setNameOverride(event.target.value)}
                label="Name"
                autoFocus
                margin="dense"
                placeholder={email}
                fullWidth
                disabled={file !== null}
              />
            </Grid>
          </Grid>
          {file !== null ? (
            <Grid container alignItems="center">
              <Grid item xs={2}>
                <span>1 file</span>
              </Grid>
              <Grid item xs={4}>
                <Button onClick={() => setFile(null)}>Remove</Button>
              </Grid>
            </Grid>
          ) : (
            <Tooltip title="Must be a file of type .csv Must have column named email Can have column name for user names">
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
              >
                Upload CSV
                <VisuallyHiddenInput
                  type="file"
                  onChange={(e) => {
                    setFile(e.target.files[0]);
                  }}
                  accept=".csv"
                />
              </Button>
            </Tooltip>
          )}
          <Tooltip title="Will send an email to users to create an account">
            <FormControlLabel
              control={
                <Switch
                  value={shouldAccessDevLanding}
                  onChange={(e) => setShouldAccessDevLanding(e.target.checked)}
                />
              }
              label="Can access DevLanding?"
            />
          </Tooltip>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <LoadingButton
          disabled={
            /^[\w+-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email) === false &&
            file === null
          }
          onClick={() => {
            if (file !== null) {
              uploadUserCsvMutation.mutate();
            } else {
              inviteUserMutation.mutate();
            }
          }}
          color="secondary"
          loading={
            inviteUserMutation.isPending || uploadUserCsvMutation.isPending
          }
        >
          <span>{file !== null ? "Upload" : "Save"}</span>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
