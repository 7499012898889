import React, { useState } from "react";
import { GroupsController } from "./GroupsController";
import { useApi } from "../ApiProvider";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "../AuthProvider";
import { ReportsContainer } from "../dashboard/ReportsContainer";
import { CircularProgress, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

export const ProfileController = ({ userId }) => {
  const queryClient = useQueryClient();
  const { user, updateStoredUser } = useAuth();
  const userIdToQuery = userId || user.id;

  const [isEditing, setIsEditing] = useState(false);
  const [newUserName, setNewUserName] = useState("");

  const { getUser, updateUser } = useApi();
  const userDetailsQuery = useQuery({
    queryKey: ["userDetails", userIdToQuery],
    queryFn: ({ queryKey }) => getUser(queryKey[1]),
  });

  const updateUserMutation = useMutation({
    mutationFn: () => updateUser(userIdToQuery, newUserName),
    onSuccess: (response) => {
      queryClient.setQueryData(["userDetails", userIdToQuery], response);
      updateStoredUser(response);
      setIsEditing(false);
    },
  });

  const toggleEditing = () => {
    if (isEditing) {
      updateUserMutation.mutate();
    } else {
      setNewUserName(userDetailsQuery.data.name);
    }
    setIsEditing(!isEditing);
  };

  return (
    <div className="body-component-container">
      <div className="header-with-actions">
        <h1>Profile</h1>
        {userIdToQuery !== userId && userDetailsQuery.isSuccess && (
          <LoadingButton
            variant="contained"
            color="secondary"
            onClick={toggleEditing}
            loading={updateUserMutation.isPending}
          >
            {isEditing ? "Save" : "Edit"}
          </LoadingButton>
        )}
      </div>
      {userDetailsQuery.isSuccess ? (
        <>
          <div>
            <div>
              {isEditing ? (
                <TextField
                  variant="outlined"
                  label="Your name"
                  value={newUserName}
                  onChange={(e) => setNewUserName(e.target.value)}
                />
              ) : (
                <span>{userDetailsQuery.data.name}</span>
              )}
            </div>
            <div>{userDetailsQuery.data.email}</div>
          </div>
          {userIdToQuery !== userId && (
            <div>
              <GroupsController level="INDIVIDUAL" />
            </div>
          )}
          <div>
            <h2>Individual Metrics (last 6 months)</h2>
            <ReportsContainer
              userIds={[userIdToQuery]}
              groupIds={[]}
              timeWindow={{ apiValue: 6 }}
              showSummaryTable={false}
            />
          </div>
        </>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
};
