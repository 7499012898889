import React, { useState } from "react";
import { useApi } from "./ApiProvider";
import { useQuery } from "@tanstack/react-query";
import {
  CircularProgress,
  FormControlLabel,
  LinearProgress,
  Switch,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

export const JobStatusController = () => {
  const { getJobProgress } = useApi();
  const [includeCompleted, setIncludeCompleted] = useState(false);

  const jobProgressQuery = useQuery({
    queryKey: ["jobProgress", includeCompleted],
    queryFn: () => getJobProgress(includeCompleted),
  });

  const columns = [
    { field: "id", headerName: "Batch ID" },
    { field: "batchType", headerName: "Type" },
    {
      field: "entity",
      headerName: "Entity ID",
      valueGetter: ({ row }) => {
        if (row.batchType === "USER") {
          return row.userId;
        } else if (row.batchType === "GROUP") {
          return row.groupId;
        } else if (row.batchType === "REPO") {
          return row.repoId;
        } else if (row.batchType === "ACCOUNT") {
          return row.accountId;
        }
        return "";
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.25,
    },
    {
      field: "progress",
      headerName: "Progress",
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => {
        let value = ((row.totalJobs - row.remainingJobs) / row.totalJobs) * 100;
        if (isNaN(value)) {
          value = 0;
        }
        return (
          <LinearProgress
            variant="determinate"
            value={value}
            color="secondary"
            style={{ width: "100%" }}
          />
        );
      },
    },
    {
      field: "totalJobs",
      headerName: "Total",
    },
    {
      field: "failedJobs",
      headerName: "Failures",
    },
    {
      field: "inserted",
      headerName: "Started",
      flex: 0.25,
      valueGetter: (params) => {
        if (!params.value) {
          return params.value;
        }
        // Convert the decimal value to a percentage
        return new Date(params.value).toString();
      },
    },
  ];

  return (
    <>
      <h1>Jobs</h1>
      <FormControlLabel
        label="Include Completed"
        control={
          <Switch
            checked={includeCompleted}
            onChange={(_, checked) => {
              setIncludeCompleted(checked);
            }}
          />
        }
      />
      {jobProgressQuery.isLoading ? (
        <CircularProgress />
      ) : (
        <DataGrid rows={jobProgressQuery.data} columns={columns} />
      )}
    </>
  );
};
