import React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Checkbox, Link } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useAuth } from "../AuthProvider";

export const RepoTable = ({ repos, doUpdate, doSave, doFilter, isDemoMode=false }) => {
  const theme = useTheme();
  const { userHasPermission } = useAuth();

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 25,
  });
  const handleUpdate = (repoId, field, value) => {
    if (isDemoMode) {
      return;
    }
    doUpdate(repoId, field, value);
  };

  // Access theme colors
  const primaryColor = theme.palette.primary.main;
  const secondaryColor = theme.palette.secondary.main;

  // Define row styles based on theme colors
  const primaryRowStyle = {
    backgroundColor: primaryColor,
  };

  const secondaryRowStyle = {
    backgroundColor: secondaryColor,
  };

  const getRowStyle = (params) => {
    return params.rowIndex % 2 === 0 ? primaryRowStyle : secondaryRowStyle;
  };

  let columns = [
    { field: "id", headerName: "ID", type: "number" },
    { field: "integrationId", headerName: "Integration", type: "number" },
    {
      field: "fullUrl",
      headerName: "URL",
      minWidth: 300,
      flex: 1,
      renderCell: ({ row }) => <Link href={row.fullUrl}>{row.fullUrl}</Link>,
    },
  ];

  if (userHasPermission("manage_account") || isDemoMode === true) {
    columns = columns.concat([
      {
        field: "indexing",
        headerName: "Indexing",
        renderCell: ({ row }) => (
          <Checkbox
            checked={row.indexing}
            onChange={(e) => handleUpdate(row.id, "indexing", e.target.checked)}
          />
        ),
      },
      {
        field: "archived",
        headerName: "Archived",
        renderCell: ({ row }) => (
          <Checkbox
            checked={row.archived}
            onChange={(e) => handleUpdate(row.id, "archived", e.target.checked)}
          />
        ),
      },
    ]);
  }

  return (
    <DataGrid
      rows={repos}
      getRowClassName={getRowStyle}
      columns={columns}
      slots={{ toolbar: GridToolbar }}
      disableRowSelectionOnClick
      pagination
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      getRowHeight={() => "auto"}
    />
  );
};
