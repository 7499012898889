import React from "react";
import "./App.css";
import { Link, Outlet } from "react-router-dom";
import { UserMenu } from "./UserMenu";
import {
  Alert,
  AppBar,
  Button,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import { useAuth } from "./AuthProvider";

export const App = () => {
  const { isImpersonating, stopImpersonation, email } = useAuth();

  return (
    <div className="topLevelContainer" autoComplete="off">
      <AppBar
        position="sticky"
        color={isImpersonating() ? "secondary" : "primary"}
      >
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            DevLanding
          </Typography>
          <Button component={Link} to="/graphs" color="inherit">
            <div>Reports</div>
          </Button>
          <Button component={Link} to="/repos" color="inherit">
            <div>Repos</div>
          </Button>
          <UserMenu />
        </Toolbar>
      </AppBar>
      {isImpersonating() && (
        <Alert
          severity="error"
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => stopImpersonation()}
            >
              Stop
            </Button>
          }
        >
          Impersonating {email}
        </Alert>
      )}
      <div id="mainContent" className="mainBody">
        <Outlet />
      </div>
      <Paper id="footer" className="footer" elevation={0}>
        <div>Explore Measure Monitor Act. All in one place</div>
      </Paper>
    </div>
  );
};
