import {
    AppBar,
    Box,
    Button, Grid, Link, List, ListItem, ListItemText, Paper,
    Toolbar,
    Typography,
} from "@mui/material";
import React from "react";
import {useTheme} from "@mui/material/styles";

const AboutUsComponent = () => {
    const theme = useTheme();

    const handleEmailBasedLogin = () => {
        window.location.href = "/login";
    };

    const handleContactUs = () => {
        window.location.href = "/contact";
    }
    const handleHomeClick = () => {
        window.location.href = "/";
    };

    const subPages = [
        {
            title: 'About us',
            link: '/aboutUs'
        },
        {
            title: 'The Science of Dev Efficiency',
            link: '/devEfficiency'
        },
        {
            title: 'Investors',
            link: '/investors'
        },
    ];
    const handleFooterLinkClick = (index) => {
        window.location.href = subPages[index].link;
    }

    const styles = {
        primarySectionBox: {
            width: "100%",
            display: "flex", // Use flexbox layout
            justifyContent: "center", // Horizontally center items
            textAlign: "justify" /* Evenly distribute text across lines */,
            height: '100vh', // Set the height to fill the whole viewport
            padding: '3em',
            flex: 1,
            overflow: 'auto',
        },
        secondarySectionBox: {
            width: "100%",
            height: "10em",
            display: "flex", // Use flexbox layout
            flexDirection: "column"
        },
        primaryBackground: {
            fontWeight: "bold",
            textAlign: "center",
        },
        sectionBox: {
            paddingTop: '2em'
        },
        firstHeaderBox: {
            color: theme.palette.text.primary,
        },
        headerBox: {
            color: theme.palette.text.primary,
            paddingTop: '2em'
        },
        secondaryBox: {
            color: theme.palette.secondary.main,
            paddingTop: '2em'
        },
        outerBox: {
            color: theme.palette.text.content,
            display: 'flex', flexDirection: 'column', height: '100vh'}
    };

    return (
        <>
            <Box sx={styles.outerBox}>
                <AppBar position="sticky">
                    <Toolbar>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{cursor: 'pointer'}} onClick={handleHomeClick}>
                            DevLanding
                        </Typography>

                        <Button style={{"margin-right": "1em", cursor: 'pointer'}} color="secondary" variant="contained" onClick={handleContactUs}>
                            Contact Us
                        </Button>

                        <Button variant="contained"
                                color="secondary" style={{cursor: 'pointer'}} onClick={handleEmailBasedLogin}>
                            Log in
                        </Button>

                    </Toolbar>
                </AppBar>
                <Box sx={styles.primarySectionBox}>
                    <Box sx={styles.secondarySectionBox}>
                        <Typography variant="h2" sx={styles.firstHeaderBox}>
                            What is Developer Efficiency?
                        </Typography>
                        <Typography sx={styles.sectionBox} gutterBottom>
                            It takes culture to deliver high quality software as a collective unit - and that's where dev efficiency comes into play. Building software is more than writing and shipping code. It starts with empathy for the day to day life of a developer.
                            There are multiple good articles out there, but starting with this Article from McKinsey sums it up nicely
                            <List>
                                <ListItem>
                                    <Link
                                        sx={{color: theme.palette.text.secondary}}
                                        href="https://www.mckinsey.com/industries/technology-media-and-telecommunications/our-insights/yes-you-can-measure-software-developer-productivity" target="_blank" rel="noopener">
                                        The McKinsey Dev Efficiency Article
                                    </Link>
                                </ListItem>
                            </List>
                        </Typography>
                        <Typography sx={styles.sectionBox} gutterBottom>
                            In short, building software is more than fancy typing. It's a collective effort with multiple actors pushing and pulling an organizations culture in different directions. With access to richer productivity data and insights, leaders can begin addressing questions about the culture they are building.
                            <List>
                                <ListItem>
                                    <ListItemText primary="What are the impediments to the engineers working at their best level?" />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="How much does culture and organization affect their ability to produce their best work?" />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="How do we know if we’re using their time on activities that truly drive value?" />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="How can we know if we have all the software engineering talent we need?" />
                                </ListItem>
                            </List>
                        </Typography>
                        <Typography sx={styles.secondaryBox} variant="h6" gutterBottom>
                            The challenge then becomes, how do we gain access to such data?
                        </Typography>
                        <Typography sx={styles.headerBox} variant="h4">
                            Traditional software development has been thought of in 2 veins of measurement:
                            <List sx={{color: theme.palette.text.secondary}}>
                                <ListItem>
                                    <ListItemText primary="1: How easy is it for me to build code? We used metrics like code commits" />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="2: How easy is it for me to ship code? All the DORA metrics apply here." />
                                </ListItem>
                            </List>
                        </Typography>
                        <Typography sx={styles.headerBox} variant="h4">
                            We believe the new age software development lifecycle requires much more than this to build a healthy engineering culture.
                            <List sx={{color: theme.palette.text.secondary}}>
                                <ListItem>
                                    <ListItemText primary="1: Software is Collaborative: How often are people meeting? Too much? Not enough?" />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="2: Delivery requires Focus: How much time do people spend focusing on what matters?" />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="3: Outcomes require culture: How is our culture impacting our ability to deliver with quality?" />
                                </ListItem>
                            </List>
                        </Typography>
                        <Typography sx={styles.headerBox} variant="h4">
                            Most companies today fail to have the data they really need to understand their software development culture
                            <List sx={{color: theme.palette.text.secondary}}>
                                <ListItem>
                                    <ListItemText primary="Jira: Reports how many tickets are being closed across a given program."/>
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="PagerDuty: Reports on how often a set of services are healthy" />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="DataDog: Reports the fine grain performance of your services" />
                                </ListItem>
                            </List>
                        </Typography>
                        <Typography sx={styles.secondaryBox} variant="h6" >
                            In reality, every engineer uses these on day to day basis as well as many other services.                             Dev Efficiency is about optimizing the day in the life of a software engineer across multiple facets.
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={styles.secondaryBox}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} style={{display: 'flex', flexDirection: 'column'}}>
                        <Paper sx={styles.secondaryBox} style={{
                            padding: '16px',
                            textAlign: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            height: '100%',
                        }}>
                            <Box
                                style={{
                                    padding: '20px',
                                    cursor: 'pointer',
                                    'text-align': 'left',
                                    marginBottom: '10px',
                                }}
                            >
                                {subPages.map((option, index) => (
                                    <div onClick={() => handleFooterLinkClick(index)}>
                                        <Typography variant='h6' gutterBottom style={{}}>
                                            {option.title}
                                        </Typography>
                                    </div>
                                ))}
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default AboutUsComponent;
