import React, { useState } from "react";
import { GroupTableComponent } from "./GroupTableComponent";
import GroupCreateModal from "./GroupCreateModal";
import { useApi } from "../ApiProvider";
import { useQuery } from "@tanstack/react-query";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";

export const GroupsController = ({ level }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { fetchGroups } = useApi();

  const groupsQuery = useQuery({
    queryKey: ["groups", level],
    queryFn: ({ queryKey }) => fetchGroups(queryKey[1]),
  });

  return (
    <div>
      <div className="header-with-actions">
        <h2>Groups</h2>
        <Button
          className="saveButton"
          variant="contained"
          color="secondary"
          onClick={() => {
            setModalIsOpen(true);
          }}
        >
          Add
        </Button>
      </div>
      {groupsQuery.isPending ? (
        <CircularProgress />
      ) : (
        <GroupTableComponent groupConfigs={groupsQuery.data} level={level} />
      )}
      <GroupCreateModal
        isOpen={modalIsOpen}
        closeModal={() => {
          setModalIsOpen(false);
        }}
        level={level}
      />
    </div>
  );
};
