import React, { useEffect, useState } from "react";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useApi } from "../ApiProvider";
import { useQuery } from "@tanstack/react-query";
import {useTheme} from "../theme";
import {Chip} from "@mui/material";

export const UserSelector = ({ handleUserSelection, multiple = true }) => {
  const { fetchAccountUsers } = useApi();
  const theme  = useTheme();
  const [displayedUsers, setDisplayedUsers] = useState(() => {
    // Retrieve array from localStorage or use default value

    if (multiple) {
      const displayedUsers = localStorage.getItem("displayedUsers");
      return displayedUsers ? JSON.parse(displayedUsers) : [];
    } else {
      return null;
    }
  });
  useEffect(() => {
    if (multiple) {
      localStorage.setItem("displayedUsers", JSON.stringify(displayedUsers));
    }
  }, [displayedUsers, multiple]);

  const accountUsersQuery = useQuery({
    queryKey: ["accountUsers"],
    queryFn: fetchAccountUsers,
  });

  const handleSelectUsers = (e, newValues) => {
    const uniqueItems = [];
    const seenIds = new Set();

    if (multiple) {
      newValues.forEach((item) => {
        if (!seenIds.has(item.id)) {
          uniqueItems.push(item);
          seenIds.add(item.id);
        }
      });
      setDisplayedUsers(uniqueItems);
      localStorage.setItem("displayedUsers", JSON.stringify(uniqueItems));
      handleUserSelection(newValues);
    } else {
      setDisplayedUsers(newValues);
      handleUserSelection(newValues);
    }
  };

  const handleRenderTags = (value, getTagProps) =>
      value.map((option) => (
          <Chip
              key={option.id}
              label={`${option.name}`}
              color="secondary" // Set chip color to primary (can be customized)
              {...getTagProps({ index: value.indexOf(option) })}
          />
      ));

  return (
    <Autocomplete
      multiple={multiple}
      options={accountUsersQuery.isPending ? [] : accountUsersQuery.data}
      getOptionLabel={(option) => option.name}
      onChange={handleSelectUsers}
      value={displayedUsers}
      size="small"
      renderInput={(params) => (
        <TextField {...params} variant="standard" label="Users" color="secondary" />
      )}
      renderTags={handleRenderTags} // Customize chip appearance

      sx={{
        '& .MuiAutocomplete-option': {
          color: theme.palette.secondary.main, // Apply custom text color
        },
      }}
    />
  );
};
