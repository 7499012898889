import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProfileController } from "./ProfileController";

function ProfileControllerWrapper() {
  const navigate = useNavigate();
  const params = useParams();
  return <ProfileController navigate={navigate} userId={params.userId} />;
}

export default ProfileControllerWrapper;
