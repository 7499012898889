import React from "react";

const allMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const MonthTick = ({ x, y, payload }) => {
  const date = new Date(payload.value);
  if (date.getUTCDate() < 8) {
    const monthIndex = date.getUTCMonth();
    const month = allMonths[monthIndex];

    return (
      <text x={x} y={y} dy={16} textAnchor="middle" fill="#666">
        {month}
      </text>
    );
  } else {
    return <></>;
  }
};
