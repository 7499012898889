import React from "react";
import "../styling.css";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

export const ReportTable = ({ groupSummaries, userSummaries, allowLinks=false }) => {
  const navigate = useNavigate();

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 300,
    },
    {
      field: "ticketsAssigned",
      headerName: "Assigned Tickets",
      type: "number",
    },
    {
      field: "ticketsClosed",
      headerName: "Closed Tickets",
      type: "number",
    },
    {
      field: "ticketsTransferred",
      headerName: "Transferred Tickets",
      type: "number",
    },
    {
      field: "durationTicketAssigned",
      headerName: "Ticket Duration",
      type: "number",
    },
    {
      field: "bugsAssigned",
      headerName: "Assigned Bugs",
      type: "number",
    },
    {
      field: "bugsClosed",
      headerName: "Closed  Bugs",
      type: "number",
    },
    {
      field: "durationBugAssigned",
      headerName: "Bug Duration",
      type: "number",
    },
    {
      field: "numPagesAssigned",
      headerName: "Paged",
      type: "number",
    },
    {
      field: "numPagesResolved",
      headerName: "Pages Resolved",
      type: "number",
      minWidth: 125,
    },
    {
      field: "durationPagesToResolve",
      headerName: "Page Duration",
      type: "number",
      minWidth: 125,
    },
    {
      field: "numOncallHoursFlat",
      headerName: "On Call Hours",
      type: "number",
    },
    {
      field: "numPrsOpened",
      headerName: "PRs Opened",
      type: "number",
    },
    {
      field: "numPrsMerged",
      headerName: "PRs merged",
      type: "number",
    },
    {
      field: "avgPrHoursToMerge",
      headerName: "Hours to Merge",
      type: "number",
      minWidth: 125,
    },
    {
      field: "numComments",
      headerName: "Comments",
      type: "number",
    },
    {
      field: "numCommits",
      headerName: "Commits",
      type: "number",
    },
    {
      field: "numAdds",
      headerName: "+ Additions",
      headerAlign: "right",
      valueFormatter: (value) => {
        if (value === null || value === 0) {
          return 0;
        } else {
          return `+${value}`;
        }
      },
      headerClassName: "commit-addition",
      align: "right",
      cellClassName: "commit-addition",
    },
    {
      field: "numDeletes",
      headerName: "- Deletions",
      headerAlign: "right",
      valueFormatter: (value) => {
        if (value === null || value === 0) {
          return 0;
        } else {
          return `-${value}`;
        }
      },
      headerClassName: "commit-removal",
      align: "right",
      cellClassName: "commit-removal",
    },
  ];


  return (
    <DataGrid
      rows={groupSummaries.concat(userSummaries)}
      columns={columns}
      getRowId={(row) => row.userId || "g" + row.groupId}
      disableRowSelectionOnClick
      onRowClick={({ row }) => {
        if (allowLinks && row.userId) {
          navigate(`/users/${row.userId}`);
        }
      }}
    />
  );
};
