import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ApiError } from "./api/ApiError";
import { jwtDecode } from "jwt-decode";
import { useQueryClient } from "@tanstack/react-query";

export const AuthContext = createContext(null);
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const queryClient = useQueryClient();
  const [authToken, setAuthToken] = useState(localStorage.getItem("authToken"));
  const [email, setEmail] = useState(localStorage.getItem("email"));

  const [userId, setUserId] = useState(localStorage.getItem("userId"));
  const [accountId, setAccountId] = useState(localStorage.getItem("accountId"));
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [decodedJwt, setDecodedJwt] = useState(null);

  const [impersonationToken, setImpersonationToken] = useState(null);

  const navigate = useNavigate();
  const loginBaseUrl = process.env.REACT_APP_LOGIN;

  const authWithGoogle = async (tokenFromGoogle) => {
    const body = {
      token: tokenFromGoogle,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };

    const response = await fetch(loginBaseUrl + "/google", requestOptions);

    if (!response.ok) {
      if (response.status === 401) {
        handleUnauthorized(); // Use handleLogout from AuthContext
      } else {
        toast.error("Something went wrong. Please try again later.");
        throw new ApiError("Unauthorized");
      }
    }
    return await response.json();
  };

  const login = (code) => {
    console.log("doing login");

    authWithGoogle(code)
      .then((response) => {
        let token = response.token;
        setAuthToken(token);
        setEmail(response.user.email);
        setUserId(response.user.id);
        setUser(response.user);
        setAccountId(response.accountId);

        localStorage.setItem("authToken", token); // Store the token securely
        localStorage.setItem("email", response.user.email); // Store the token securely
        localStorage.setItem("userId", response.user.id); // Store the token securely
        localStorage.setItem("user", JSON.stringify(response.user));
        window.location.href = "/"; // URL to initiate OAuth flow
      })
      .catch(() => {
        navigate("/unauthorized");
      });
  };

  const emailBasedLogin = async (email, password) => {
    const body = {
      email: email,
      password: password,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };

    fetch(loginBaseUrl + "/emailBased", requestOptions)
      .then((response) => {
        if (!response.ok) {
          localStorage.removeItem("authToken"); // Remove the token from storage
          setAuthToken("");
          throw new ApiError(
            "Invalid Login, Please try again later",
            response.statusCode
          );
        } else {
          const responseBody = response.json();
          return responseBody;
        }
      })
      .then((response) => {
        let token = response.token;
        setAuthToken(token);
        setEmail(response.user.email);
        setUserId(response.user.id);
        setUser(response.user);
        setAccountId(response.accountId);
        setDecodedJwt(jwtDecode(token));

        localStorage.setItem("authToken", token); // Store the token securely
        localStorage.setItem("email", response.user.email); // Store the token securely
        localStorage.setItem("userId", response.user.id); // Store the token securely
        localStorage.setItem("user", JSON.stringify(response.user));
        localStorage.setItem("accountId", response.accountId);
        window.location.href = "/home"; // URL to initiate OAuth flow
      })
      .catch((error) => {
        toast.error("Invalid login, please try again", {
          //https://stackoverflow.com/questions/62578112/react-toastify-showing-multiple-toast
          toastId: "invalidLogin",
        });
      });
  };

  const logout = () => {
    setAuthToken("");
    setEmail("");
    setUserId("");
    setUser(null);
    setAccountId(null);
    setDecodedJwt(null);
    localStorage.clear();
    navigate("/");
  };

  const isAuthenticated = () => {
    return !!authToken;
  };

  const handleUnauthorized = () => {
    localStorage.removeItem("authToken"); // Remove the token from storage
    setAuthToken("");
    navigate("/");
  };

  const userHasPermission = (permissionName) => {
    if (!isAuthenticated()) {
      return false;
    }
    let jwt = decodedJwt;
    if (jwt === null) {
      jwt = jwtDecode(authToken);
      setDecodedJwt(jwt);
    }
    const authorities = jwt.authorities;
    let result = authorities.includes(permissionName.toUpperCase());

    return result;
  };

  const startImpersonation = (userId) => {
    if (userHasPermission("internal_admin")) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };
      fetch(
        process.env.REACT_APP_INTERNAL_API_URL +
          "/impersonate?userToImpersonate=" +
          userId,
        requestOptions
      )
        .then((response) => {
          if (!response.ok) {
            localStorage.removeItem("impersonation"); // Remove the token from storage
            setImpersonationToken("");
            throw new ApiError(
              "Invalid Login, Please try again later",
              response.statusCode
            );
          } else {
            const responseBody = response.json();
            return responseBody;
          }
        })
        .then((response) => {
          let token = response.token;
          setImpersonationToken(token);
          setEmail(response.user.email);
          setUserId(response.user.id);
          setUser(response.user);
          setAccountId(response.accountId);
          setDecodedJwt(jwtDecode(token));
          queryClient.invalidateQueries({ refetchType: "all" }); // figure out how to refetch the user groups properly here too

          localStorage.setItem("impersonation", token); // Store the token securely
          navigate("/home");
        });
    }
  };

  const stopImpersonation = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    setImpersonationToken(null);
    setEmail(user.email);
    setUserId(user.id);
    setUser(user);
    setAccountId(1);
    setDecodedJwt(jwtDecode(authToken));
    localStorage.removeItem("impersonation"); // Remove the token from storage
    queryClient.invalidateQueries();
    navigate("/admin");
  };

  const isImpersonating = () => {
    return impersonationToken !== null;
  };

  const updateStoredUser = (newUserData) => {
    setUser(newUserData);
    localStorage.setItem("user", JSON.stringify(newUserData));
  };

  return (
    <AuthContext.Provider
      value={{
        userId,
        accountId,
        authToken,
        isAuthenticated,
        login,
        emailBasedLogin,
        logout,
        handleUnauthorized,
        email,
        user,
        userHasPermission,
        startImpersonation,
        isImpersonating,
        stopImpersonation,
        impersonationToken,
        updateStoredUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
