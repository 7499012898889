import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "../ApiProvider";
import { Button, Chip, CircularProgress, Grid, TextField } from "@mui/material";
import { UserSelector } from "../dashboard/UserSelector";
import { DataGrid } from "@mui/x-data-grid";
import { IntegrationIcon } from "../integration/IntegrationIcon";
export const AliasTable = ({ userId = null }) => {
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });

  const {
    getAccountAliases,
    getUserAliases,
    unassignAlias,
    assignAlias,
    inviteUser,
  } = useApi();
  const [showUserSelector, setShowUserSelector] = useState(null);
  const [showUserCreator, setshowUserCreator] = useState(null);
  const [newEmail, setNewEmail] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);

  const { data, error, isLoading } = useQuery({
    queryKey: ["aliases", userId ? "user" : "account", userId],
    queryFn: () => {
      if (userId) {
        return getUserAliases(userId);
      } else {
        return getAccountAliases();
      }
    },
  });

  const inviteUserMutation = useMutation({
    mutationFn: () => inviteUser(newEmail, false),
    onSuccess: (response) => {
      setSelectedUser(response);
      assignAliasMutation.mutate(showUserCreator);
      setNewEmail("");
      setshowUserCreator(null);
      queryClient.invalidateQueries({ queryKey: ["aliases"] });
      queryClient.invalidateQueries({ queryKey: ["scopes"] });
    },
  });

  const queryClient = useQueryClient();
  const unassignAliasMutation = useMutation({
    mutationFn: (aliasId) => unassignAlias(aliasId),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["aliases", "account"] });
      queryClient.invalidateQueries({ queryKey: ["aliases", "user", userId] });
    },
  });

  const assignAliasMutation = useMutation({
    mutationFn: (aliasId) => assignAlias(aliasId, selectedUser.id),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["aliases", "account"] });
      queryClient.invalidateQueries({
        queryKey: ["aliases", "user", selectedUser.id],
      });
    },
  });
  const columnsSortable = userId === null;

  const columns = [
    {
      field: "valueString",
      headerName: "Value",
      sortable: columnsSortable,
      minWidth: 350,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <p>{row.valueString}</p>
            </Grid>
            {row.relatedAliasId !== 0 &&
              data.find((el) => el.id === row.relatedAliasId) && (
                <Grid item>
                  <Chip
                    label={
                      "related: " +
                      data.find((el) => el.id === row.relatedAliasId)
                        .valueString
                    }
                  />
                </Grid>
              )}
          </Grid>
        );
      },
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 120,
      sortable: columnsSortable,
      valueGetter: (value) => {
        return value
          .toLowerCase()
          .split("_")
          .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
          .join(" ");
      },
      cellClassName: "integration-column",
    },
    {
      field: "provider",
      headerName: "Integration",
      sortable: columnsSortable,
      renderCell: ({ row }) => {
        return (
          <IntegrationIcon
            integrationName={row.provider || ""}
            additionalText={row.description}
          />
        );
      },
      cellClassName: "integration-column",
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 300,
      sortable: false,
      disableColumnMenu: true,
      cellClassName: "integration-column",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Grid container alignItems="center" spacing={2}>
            {userId === null &&
              (showUserSelector === row.id || showUserCreator === row.id ? (
                <>
                  {showUserSelector === row.id && (
                    <>
                      <Grid item xs={8}>
                        <UserSelector
                          multiple={false}
                          handleUserSelection={(user) => setSelectedUser(user)}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          variant="contained"
                          onClick={() => assignAliasMutation.mutate(row.id)}
                          color="secondary"
                        >
                          Save
                        </Button>
                      </Grid>
                    </>
                  )}
                  {showUserCreator === row.id && (
                    <>
                      <TextField
                        value={newEmail}
                        onChange={(e) => setNewEmail(e.target.value)}
                        label="Email address"
                        variant="standard"
                      />
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => inviteUserMutation.mutate()}
                      >
                        Save
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Grid item>
                    <Button
                        className="saveButton"
                        variant="contained"
                        color="secondary"
                      onClick={() => setShowUserSelector(row.id)}
                    >
                      Assign
                    </Button>
                  </Grid>
                  {/* <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setshowUserCreator(row.id)}
                  >
                    Create New User
                  </Button> */}
                </>
              ))}
            {userId !== null && (
              <Grid item>
                <Button
                  className="saveButton"
                  variant="contained"
                  color="secondary"
                  onClick={() => unassignAliasMutation.mutate(row.id)}
                >
                  Unassign
                </Button>
              </Grid>
            )}
          </Grid>
        );
      },
    },
  ];

  return (
    <div>
      {isLoading ? (
        <CircularProgress />
      ) : !data || error ? (
        <p>Error fetching account information</p>
      ) : (
        <>
          <DataGrid
            pageSizeOptions={[5, 10, 25, 50]}
            columns={columns}
            rows={data}
            loading={isLoading}
            pagination
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            disableRowSelectionOnClick
            getRowHeight={() => "auto"}
          ></DataGrid>
        </>
      )}
    </div>
  );
};
